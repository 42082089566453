<template>
  <v-app>
       <h1 class="d-sr-only">PRCA Rodeo News and Information</h1>
    <v-app-bar app color="primary" dark :height="$vuetify.breakpoint.lgAndUp ? '72' : '60' " class="main-header d-print-none">
        <v-container no-gutter class="px-0">
            <v-row class="px-0 px-lg-8 ma-0 d-flex flex-nowrap align-stretch">
                <div class="main-header__logo">
                    <router-link :to="{name: 'Home'}" aria-label="Go to PRCA homepage">
                    <v-img class="main-header__logo-img" src="@/assets/logos/logo_primary.svg" alt="PRCA logo" :width="logoWidth"/>
                    </router-link>
                </div>
                <the-navigation-menu :value="navActive" @input="toggleNav" @nav-hover="toggleNavHovered">
                    <v-btn
                        v-if="false"
                        @click="toggleSearch"
                        dark
                        tile
                        small
                        color="primary"
                        :min-width="$vuetify.breakpoint.lgAndUp ? 40 : 59"
                        class="main-header__search-button px-1"
                        :height="$vuetify.breakpoint.lgAndUp ? '72' : '60' ">
                        <v-icon size="24" v-if="searchActive">$close</v-icon>
                        <v-icon size="18" v-else>$search</v-icon>
                    </v-btn>
                    <transition name="fade">
                        <div v-if="searchActive" class="main-header__search white">
                            <v-container>
                                <v-row>
                                <v-col xs-12>
                                    <v-text-field @keydown="handleSearchInput" light hide-details v-model="searchInput" :label="$vuetify.breakpoint.mdAndUp ? 'What are you looking for?': 'Search for...'">
                                        <template #append-outer>
                                            <v-btn :disabled="!searchInput" @click="executeSearch" small text color="secondary"><v-icon size="16">$arrowRight</v-icon></v-btn>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                </v-row>
                            </v-container>
                        </div>
                    </transition>
                </the-navigation-menu>
                <div class="d-flex align-center main-header__account">
                    <block-account-modal />
                    <div v-if="user">
                        <block-account-menu :value="accountMenuActive" @input="toggleAccountMenu" />
                    </div>
                </div>
            </v-row>
        </v-container>
    </v-app-bar>
    <v-main class="main-content__wrapper">
         <router-view></router-view>
    </v-main>
      <block-alert-i-e-modal v-model="isIE" />
    <the-footer/>
  </v-app>
</template>

<script>
import {mapState} from 'vuex';
import TheNavigationMenu from "./components/TheNavigationMenu";
import TheFooter from "./components/TheFooter";
import BlockAccountModal from "@/components/blocks/account/BlockAccountModal";
import BlockAlertIEModal from "@/components/blocks/alerts/BlockAlertIEModal";

export default {
    name: "App",

    components: {
        TheNavigationMenu,
        TheFooter,
        BlockAccountModal,
        BlockAccountMenu: () => import("@/components/blocks/account/BlockAccountMenu"),
        BlockAlertIEModal
    },

    data: () => ({
        navActive:false,
        searchActive:false,
        searchInput:'',
        isNavHovered:false,
        accountMenuActive:false,
        isIE: false
    }),
    computed:{
        logoWidth(){
            if(this.searchActive || this.isNavHovered){
                return this.$vuetify.breakpoint.lgAndUp ? 40 : 31;
            }else if(this.navActive){
                return this.$vuetify.breakpoint.xlOnly ? 40 : this.$vuetify.breakpoint.lgAndUp ? 62 : this.$vuetify.breakpoint.smAndDown ? 31 : 50;
            }
            return this.$vuetify.breakpoint.lgAndUp ? 62 : this.$vuetify.breakpoint.smAndDown ? 31 : 50;
        },
          ...mapState({
            user: state => state.user
        }),
    },
    watch: {
      isNavHovered(val) {
        if (val) {
          this.navActive = false;
        }
      }
    },
    methods:{
        toggleNavHovered(hover) {
            this.isNavHovered = hover;
        },
        toggleNav(){
            this.navActive = !this.navActive;
            this.searchActive = false;
            this.accountMenuActive = false;
        },
        toggleSearch(){
            this.searchActive = !this.searchActive;
            this.navActive = false;
            this.accountMenuActive = false;
        },
        toggleAccountMenu(){
            this.accountMenuActive = !this.accountMenuActive;
            this.navActive = false;
            this.searchActive = false;
        },
        handleSearchInput(e){
           if(e.keyCode == 13){
               this.executeSearch();
           }
        },
        executeSearch(){
            alert('search:' + this.searchInput);
        }
    },
    created(){
        if(!(window.ActiveXObject) && "ActiveXObject" in window){
           if(!document.cookie.match('(^|;) ?sidearm-rodeo-ie-11-banner_consent=([^;]*)(;|$)')){
            this.isIE = true;
           }
        }

        this.$store.dispatch('look_for_user');

    }
};
</script>

<style lang="scss">
    @import "@/styles/app.scss";
    .v-application--wrap{
        background:$color-background;
    }
    .main-header{
        .v-toolbar__content{
            padding:0;
        }
    }
    .main-header__search{
        .v-label{
            text-transform: uppercase;
            font-weight: 700;
        }
    }

    @media print {
        main.v-main{
            padding-top:0 !important;
        }
    }

    #payoutSwitch > div > div > div.v-input__slot {
        background: none !important;
        background-color: transparent !important;
    }

</style>

<style lang="scss" scoped>
    .main-header{
        &__logo{
            position:relative;
            z-index: 101;
            width:59px;
        }
        &__logo-img{
            position:absolute;
            top:8px;
            left:16px;
            transition:0.2s;
        }
        &__search-button{
            position:relative;
            &:hover{
                color:$color-accent;
            }
            &::before{
                display: none;
            }
            &::after{
                position: absolute;
                top: 0;
                left:0;
                width:1px;
                height:100%;
                content:'';
                background:rgba(0, 0, 0, 0.3);
            }
        }
        &__search{
            position:fixed;
            top:100%;
            left:0;
            width: 100%;
        }
        &__account{
             position:relative;
            &::after{
                position: absolute;
                top: 0;
                left:0;
                width:1px;
                height:100%;
                content:'';
                background:rgba(0, 0, 0, 0.3);
            }

        }
    }
    .main-content__wrapper{
        min-height: auto;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    @media #{map-get($display-breakpoints, 'md-and-up')}{
        .main-header__logo{
            width:78px;
        }
    }
    @media #{map-get($display-breakpoints, 'lg-and-up')}{
        .main-header{
            &__logo{
                width:94px;
            }
            &__logo-img{
                left:0;
            }
            &__search-button, &__account{
                &::after{
                    display: none;
                }
            }
        }
    }

</style>
