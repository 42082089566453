import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VParallax } from 'vuetify/lib/components/VParallax';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.slideshow)?_c(VParallax,{staticClass:"sa-block-parallax",attrs:{"dark":"","height":_vm.$vuetify.breakpoint.mdAndDown ? 500 : 648,"src":_vm.$root.imageBasePath + _vm.slideshow.secondary_image.images[0].image}},[_c(VContainer,[_c(VRow,{staticClass:"sa-block-parallax__row flex-column align-center justify-center"},[_c(VImg,{staticClass:"sa-block-parallax__logo",attrs:{"src":_vm.$root.imageBasePath + _vm.slideshow.primary_image.images[0].image,"alt":_vm.slideshow.primary_image.images[0].alt,"width":_vm.$vuetify.breakpoint.mdAndDown ? 255 : 360}}),_c('div',{staticClass:"sa-block-parallax__text text-uppercase text-center mb-8"},[_c('span',{staticClass:"sa-block-parallax__text-upper d-block"},[_vm._v(_vm._s(_vm.slideshow.title))]),_vm._v(" "),_c('span',{staticClass:"d-block sa-block-parallax__text-lower"},[_vm._v(_vm._s(_vm.slideshow.subtitle))])]),_vm._l((_vm.slideshow.links),function(link){return _c(VBtn,{key:'slideshowlink' + link.link_id,staticClass:"mb-2",attrs:{"color":"secondary","href":link.link_url}},[_vm._v(" "+_vm._s(link.link_text)+" "),_c(VIcon,{attrs:{"right":""}},[_vm._v("$arrowRight")])],1)})],2)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }