<template>
    <div>
        <v-parallax v-if="slideshow"
            dark
            :height="$vuetify.breakpoint.mdAndDown ? 500 : 648"
            class="sa-block-parallax"
            :src="$root.imageBasePath + slideshow.secondary_image.images[0].image"
        >
            <v-container>
                <v-row class="sa-block-parallax__row flex-column align-center justify-center">
                    <v-img class="sa-block-parallax__logo" :src="$root.imageBasePath + slideshow.primary_image.images[0].image" :alt="slideshow.primary_image.images[0].alt" :width="$vuetify.breakpoint.mdAndDown ? 255 : 360"  />
                    <div class="sa-block-parallax__text text-uppercase text-center mb-8">
                        <span class="sa-block-parallax__text-upper d-block">{{ slideshow.title }}</span> <span class="d-block sa-block-parallax__text-lower">{{ slideshow.subtitle}}</span>
                    </div>
                    <v-btn color="secondary" class="mb-2" v-for="link in slideshow.links" :key="'slideshowlink' + link.link_id" :href="link.link_url">
                        {{link.link_text}}
                        <v-icon right>$arrowRight</v-icon>
                    </v-btn>
                </v-row>
            </v-container>
        </v-parallax>
    </div>
</template>

<script>
import { get } from "@/helpers";

export default {
    name:"BlockSlideshowHomepage",
    data: () => ({
        slideshow:null
    }),
    methods: {
        getSlideshow() {
        const request_options = {
            type: "slideshow",
            sport_id: 0,
            extra:JSON.stringify({slideshow_id:1}),
            count:1
        };

        get("/services/adaptive_components.ashx", request_options).then(slideshow => {
            if(slideshow && slideshow.length){
                this.slideshow = slideshow[0];
            }
        });
        }
    },
    created() {
        this.getSlideshow();
    }
};
</script>

<style lang="scss" scoped>
.sa-block-parallax{
    background:$color-primary;
    &__row{
        position:relative;
    }
    &__logo{
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
    }
    &__text{
        position:relative;
        z-index:2;
        &-upper{
            font-size:2.25rem;
            line-height:1;
            font-weight:900;
            color:$color-white;
            @supports (-webkit-text-stroke: 1px black) {
                -webkit-text-fill-color: rgba(0,0,0,0); 
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: $color-white;
            }
        }
        &-lower{
            font-size:2.625rem;
            line-height:1;
            font-weight:900;
        }
    }
}

@media #{map-get($display-breakpoints, 'md-and-up')}{
    .sa-block-parallax{
        &__text{
            &-upper{
                font-size:3.75rem;
            }
            &-lower{
                font-size:4.375rem;
            }
        }
    }
}

@media #{map-get($display-breakpoints, 'lg-and-up')}{
    .sa-block-parallax{
        &__text{
            &-upper{
                font-size:4.25rem;
            }
            &-lower{
                font-size:5rem;
            }
        }
    }
}
</style>
