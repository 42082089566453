<template>
    <div>
        <div class="d-flex flex-column align-stretch sa-element-athlete-featured">
            <div class="flex-grow-1 flex-shrink-0">
            <common-avatar-sidearm max-width="400" :type="athlete.Type()"  :src="athlete.PhotoUrl"   :alt="athlete.FullName()" tile />
            <router-link :to="athlete.BioLink()" class="type-heading--sm text-uppercase pt-4 d-block text-link">{{ athlete.FullName() }}</router-link>
            <div v-if="contestant" class="type-subtext sa-safe-text--text">
                <span v-for="eventType in eventTypes" :key="eventType.EventName">{{ eventType.EventName }}</span>
            </div>
            </div>
            <div class="flex-grow-0 flex-shrink-0">
                <v-btn color="secondary" height="33" class="mt-4" :to="athlete.BioLink()">Profile</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import CommonAvatarSidearm from "@/components/common/CommonAvatarSidearm";
import filter from "lodash/filter";
import includes from 'lodash/includes';

export default {
    name: "ElementAthletesFeaturedItem",
    props:{
        athlete:{
            type: Object,
            required: true
        }
    },
    components:{
        CommonAvatarSidearm,
    },
    data:() => ({
    }),
    computed:{
        eventTypes(){
            let _events = [];
            if(this.athlete.EventTypes && this.athlete.EventTypes.length){
                _events = filter(this.$root.eventTypes, (eventType)=>{
                    return includes(this.athlete.EventTypes,eventType.EventAbbrev);
                });
            }
            return _events;
        }
    }
};
</script>


<style lang="scss" scoped>
.sa-element-athlete-featured{
    height:100%;
}
</style>

