<template>
  <div class="sa-butler ">

  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
import postscribe from "postscribe";

export default {
    name:'ElementAdButlerVertical',
    props: {
        location: {
            type: String,
            default: ""
        }
    },
    data: () => ({}),
    mounted: function() {
        postscribe(this.$el, '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script><script type=\"text\/javascript\">var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];var abkw = window.abkw || \'\';var plc704814 = window.plc704814 || 0;document.write(\'<\'+\'div id=\"placement_704814_\'+plc704814+\'\"><\/\'+\'div>\');AdButler.ads.push({handler: function(opt){ AdButler.register(175684, 704814, [240,427], \'placement_704814_\'+opt.place, opt); }, opt: { place: plc704814++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});<\/script>');
    }
};
</script>
