<template>
  <div class="sa-butler d-flex align-center">

  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
import postscribe from "postscribe";

export default {
    name:'ElementAdButlerLeaderboardMobile',
    props: {
        location: {
            type: String,
            default: ""
        }
    },
    data: () => ({}),
    watch: {
        showXtremeBullsAd() {
            // Switch out the ad.
            this.$el.innerHTML = "";
            postscribe(this.$el, this.adScript);
        },
        showPlayoffSeriesAd() {
            // Switch out the ad.
            this.$el.innerHTML = "";
            postscribe(this.$el, this.adScript);
        }
    },
    computed: {
        showXtremeBullsAd() {
            // Xtreme Bulls https://sidearm.atlassian.net/browse/PRODEO-158
            return this.location == "standings" && this.$route.query.id == 4;
        },
        showPlayoffSeriesAd() {
            // Playoff Series https://sidearm.atlassian.net/browse/PRODEO-163
            return this.location == "standings" && this.$route.query.id == 17;
        },
        adScript() {
            let adScript = "";
            switch(this.location){
                case "standings":
                    if(this.showXtremeBullsAd) adScript = '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script><script type=\"text\/javascript\">var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];var abkw = window.abkw || \'\';var plc596358 = window.plc596358 || 0;document.write(\'<\'+\'div id=\"placement_596358_\'+plc596358+\'\"><\/\'+\'div>\');AdButler.ads.push({handler: function(opt){ AdButler.register(175684, 596358, [320,50], \'placement_596358_\'+opt.place, opt); }, opt: { place: plc596358++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});<\/script>'
                    else if(this.showPlayoffSeriesAd) adScript = '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script><script type=\"text\/javascript\">var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];var abkw = window.abkw || \'\';var plc606914 = window.plc606914 || 0;document.write(\'<\'+\'div id=\"placement_606914_\'+plc606914+\'\"><\/\'+\'div>\');AdButler.ads.push({handler: function(opt){ AdButler.register(175684, 606914, [320,50], \'placement_606914_\'+opt.place, opt); }, opt: { place: plc606914++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});<\/script>';
                    else adScript = '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script>\r\n<script type=\"text\/javascript\">\r\nvar AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];\r\nvar abkw = window.abkw || \'\';\r\nvar plc465415 = window.plc465415 || 0;\r\ndocument.write(\'<\'+\'div id=\"placement_465415_\'+plc465415+\'\"><\/\'+\'div>\');\r\nAdButler.ads.push({handler: function(opt){ AdButler.register(175684, 465415, [320,50], \'placement_465415_\'+opt.place, opt); }, opt: { place: plc465415++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});\r\n<\/script>';                    
                    break;
                case "barrel-racing-standings":
                    adScript = '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script>\r\n<script type=\"text\/javascript\">\r\n\r\nvar AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];\r\n\r\nvar abkw = window.abkw || \'\';\r\n\r\nvar plc493421 = window.plc493421 || 0;\r\n\r\ndocument.write(\'<\'+\'div id=\"placement_493421_\'+plc493421+\'\"><\/\'+\'div>\');\r\n\r\nAdButler.ads.push({handler: function(opt){ AdButler.register(175684, 493421, [320,50], \'placement_493421_\'+opt.place, opt); }, opt: { place: plc493421++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});\r\n\r\n<\/script>';
                    break;
                case "breakaway-roping-standings":
                    adScript = '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script>\r\n<script type=\"text\/javascript\">\r\n\r\nvar AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];\r\n\r\nvar abkw = window.abkw || \'\';\r\n\r\nvar plc501266 = window.plc501266 || 0;\r\n\r\ndocument.write(\'<\'+\'div id=\"placement_501266_\'+plc501266+\'\"><\/\'+\'div>\');\r\n\r\nAdButler.ads.push({handler: function(opt){ AdButler.register(175684, 501266, [320,50], \'placement_501266_\'+opt.place, opt); }, opt: { place: plc501266++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});\r\n\r\n<\/script>';
                    break;
                default:
                    adScript = '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script>\r\n<script type=\"text\/javascript\">\r\n\r\nvar AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];\r\n\r\nvar abkw = window.abkw || \'\';\r\n\r\nvar plc451813 = window.plc451813 || 0;\r\n\r\ndocument.write(\'<\'+\'div id=\"placement_451813_\'+plc451813+\'\"><\/\'+\'div>\');\r\n\r\nAdButler.ads.push({handler: function(opt){ AdButler.register(175684, 451813, [320,50], \'placement_451813_\'+opt.place, opt); }, opt: { place: plc451813++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});\r\n\r\n<\/script>';
            }
            return adScript;
        },
    },
    mounted: function() {
        postscribe(this.$el, this.adScript);
    }
};
</script>
