<template>
    <div class="sa-payout d-flex  align-center justify-center flex-lg-column justify-lg-start align-lg-start">
        <span class="primary--text font-weight-bold text-uppercase type-subtext d-flex flex-column flex-md-row align-center text-center text-md-left"><v-icon small color="primary" class="mr-1">$award</v-icon><span>{{ year }} Contestant Payout</span></span>
        <span  class="sa-payout__amount secondary--text type-lg-heading--xl type-hero--lg font-weight-bold d-block mt-lg-2 pl-8 pl-lg-0">
            <span class="sa-payout__amount-inner d-inline-block">
                <span class="sa-payout__amount-hidden" aria-hidden="true">
                    {{ amount | currency }}
                </span>
                <span :class="payout ? 'has-payout' : ''" class="sa-payout__amount-visible">
                    {{ payout | currency }}
                </span>
            </span>
        </span>
    </div>
</template>

<script>

export default {
    name: "BlockRodeosPayout",
    props: {
        totalPayout: String
    },
    data:() => ({
        amount:null,
        payout:null,
    }),
    computed: {
        year() {
            return this.$root.currentYear;
        }
    },
    methods:{
        animatePayout(start, end, duration) {
            let startTimestamp = null;
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / duration, 1);
                this.payout = Math.floor(progress * (end - start) + start);
                if (progress < 1) {
                window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        }

    },
    mounted(){
        this.amount = Number(this.totalPayout?.replaceAll(',',''));
        this.animatePayout(0,this.amount,1200);
    }
};
</script>

<style lang="scss" scoped>
.sa-payout{
    &__amount{
        line-height:1;
        
        &-inner{
            position: relative;
        }
        &-hidden{
            opacity: 0;
        }
        &-visible{
            position: absolute;
            top:0;
            left:0;
            opacity: 0;
            transition:opacity 0.6s;
            &.has-payout{
                opacity:1;
            }
        }
    }
}
</style>

