<template>
    <aside class="sa-block-slideshow sa-block-slideshow--sports-news" v-if="slideshowItem">
        <common-title class="mr-auto mb-8" top="ProRodeo" bottom="Sports News" type="h2"/>
        <div class="sa-block-slideshow__container">
            <picture>
                <source
                v-for="(size, size_index) in images"
                :key="size_index"
                :srcset="$root.imageBasePath + size.image"
                :media="`(min-width: ${size.breakpoint}px)`"
                />

                <img :src="$root.imageBasePath + images[0].image" :alt="images[0].alt" />
            </picture>
            <ul v-if="slideshowItem.links && slideshowItem.links.length">
                <li v-for="link in slideshowItem.links" :key="link.link_text + link.link_id">
                    <a :href="link.link_url">{{ link.link_text}}</a>
                </li>
            </ul>            
        </div>
	</aside>
</template>

<script>
import { get } from "@/helpers";
import CommonTitle from "@/components/common/CommonTitle";

export default {
    name:"BlockSlideshowSportsNews",
    components:{
        CommonTitle
    },
    data: () => ({
        slideshowItem:null
    }),
    computed:{
        images() {
            if (
                this.slideshowItem &&
                this.slideshowItem.primary_image.images &&
                this.slideshowItem.primary_image.images.length
            ) {
                return this.slideshowItem.primary_image.images;
            }

        return [];
        },

        alt_text() {
        if (this.images.length) {
            return this.images[0].alt;
        }

        return this.slideshowItem.title;
        }
    },
    methods: {
        getSlideshow() {
        const request_options = {
            type: "slideshow",
            sport_id: 0,
            extra:JSON.stringify({slideshow_id:3}),
            count:1
        };

        get("/services/adaptive_components.ashx", request_options).then(slideshow => {
            if(slideshow && slideshow.length){
                this.slideshowItem = slideshow[0];
            }
        });
        }
    },
    created() {
        this.getSlideshow();
    }
};
</script>

<style lang="scss" scoped>
.sa-block-slideshow--sports-news{
    .sa-block-slideshow{
        &__container{
            display: block;
            img{
                max-width:100%;
            }
        }
    }
    ul{
        @include reset-list;
        margin-top:$spacer*8;
        li{
            padding:0 0 $spacer*2 $spacer*6;
            position: relative;
            &:last-child{
                padding-bottom:0;
            }
            &::before{
                position:absolute;
                top:5px;
                left:0;
                width:$spacer*4;
                content:'';
                height:$spacer*4;
                background-image:url("~@/assets/images/image_arrow_right.svg");
                color: $color-border;
            }
        }
        a{
            color:$color-primary;
            text-decoration:none;
            text-transform:uppercase;
            font-weight:700;
            font-size:0.75rem;
            transition:color 0.2s;
            &:hover{
                color:$color-secondary;
            }
        }
    }
}

@media #{map-get($display-breakpoints, 'md-and-up')}{
    .sa-block-slideshow--sports-news{
        .sa-block-slideshow{
            &__container{
                display:flex;
                align-items:flex-start;
            }
        }
        ul{
            margin:0 $spacer*8 0;
        }
    }
}

@media #{map-get($display-breakpoints, 'lg-and-up')}{
    .sa-block-slideshow--sports-news{
        .sa-block-slideshow{
            &__container{
                display: block;
            }
        }
        ul{
            margin:$spacer*8 0 0;
        }

    }
}
</style>
