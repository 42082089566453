<template>
  <div class="sa-view-account">
      <common-title-page title="Account"/>
       <v-container  class="px-0 px-lg-8 py-0">
            <div>
                <div class="pa-3">
                    <h2>Hello, {{email}}</h2>
                </div>
                <v-form ref="passwordForm">
                    <div class="d-flex align-stretch justify-center">
                        <v-text-field
                            label="New Password"
                            type="password"
                            single-line
                            outlined
                            v-model="newPassword"
                            prepend-inner-icon="$password"
                            :rounded="false"
                            required
                            clearable
                            class="input-group--focused {valid:passwordValidation.valid}" 
                            ></v-text-field>
                    </div>
                    <ul v-show="newPassword && newPassword.length && passwordValidation.errors" class="caption mb-3">
                        <li class="red--text" v-for="(error, index) in passwordValidation.errors" :key="index">{{error}}</li>
                    </ul>
                    <div>
                        <v-text-field
                            label="Verify Password"
                            type="password"
                            single-line
                            outlined
                            v-model="confirmPassword"
                            :rules="[matchRules]"
                            prepend-inner-icon="$password"
                            :rounded="false"
                            required
                            clearable
                            ></v-text-field>
                    </div>
                </v-form>
                <div class="align-center justify-space-between d-block d-md-flex">
                    <v-spacer></v-spacer>
                    <v-btn class="mb-2 " dark color="#3B5999" @click="updatePassword()">Update Password</v-btn>
                </div>
            </div>
       </v-container>
  </div>
</template>

<script>
import CommonTitlePage from "@/components/common/CommonTitlePage";
import { check_guid, update_password }from '@/auth';
export default {
    name: "ForgotPassword",
    props:{
        guid:{
            type:[String],
            default: null,
            required: true
        }
    },
    components:{
        CommonTitlePage,
    },
    data: () => ({
        newPassword: '',
        confirmPassword: '',
        email: '',
        passwordRules: [
                        { message: 'One lowercase letter required.', regex: /[a-z]+/ },
                        { message: "One uppercase letter required.", regex: /[A-Z]+/ },
                        { message: "8 characters minimum.", regex: /.{8,}/ },
                        { message: "One number required.", regex: /[0-9]+/ },
                        { message: "One special character required.", regex: /[!@#$%^&*(),.?":{}|<>]/ },
                        { message: "Username not allowed in password", setting_shortname: 'allow_user_name_in_password', setting_value: false }
                    ],
    }),
    computed:{
        matchRules(){
            return () => (this.newPassword === this.confirmPassword) || 'Password must match'
        },
         passwordValidation() {
             return this.validatePassword();
         }

    },
    methods:{
        checkGuidValid(){
            check_guid(this.guid)
                .then(data => {
                    if(!data.isValid){
                        this.$root._router.push('/')
                    } else{
                        this.email = data.personInfo;
                    }
                })
        },
        updatePassword(){
            if(this.$refs.passwordForm.validate()){

                 if (this.passwordValidation.errors.length != 0) {
                    return;
                }

                update_password(this.email, this.newPassword)
                    .then(data => {
                        if(data){
                            this.$root._router.push('/')
                        }
                    })
            }
        },
         validatePassword() {
            let errors = []
            
            for (let condition of this.passwordRules) {
                if (condition.regex && !(new RegExp(condition.regex).test(this.newPassword))) {
                    errors.push(condition.message)
                } 
            }
            
            if (errors.length === 0) {
                this.valid = true;
                return { valid: true, errors }
            } else {
                this.valid = false
                return { valid: false, errors }
            }
        },
    },
    watch:{

    },
    created(){
        if(this.guid){
            this.checkGuidValid()
        }
    }
}
</script>

<style lang="scss">
.sa-view-account{
    .embedded{
        width:100%;
        height:calc(1100px);
        border:solid 1px $color-border-light;
    }
}

</style>