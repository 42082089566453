<template>
     <v-chip v-bind="properties" class="sa-common-chip-progress text-uppercase font-weight-bold">
      {{ properties.title }}
    </v-chip>

</template>

<script>


export default {
  name: "CommonChipProgressSidearm",
  props:{
      status:{
        type:String,
        required:true,
        default:'in-progress'
      },
      canceled:{
          type:Boolean,
          default:false
      }
  },
  data: () => ({}),
  computed:{
      properties: function(){
          let _properties = {};
          switch(this.status){
                case 'in-progress':
                    _properties['color'] = 'secondary';
                    _properties['text-color'] = '#ffffff';
                    _properties['title'] = 'In Progress';
                    break;
                case 'completed':
                    _properties['color'] = 'primary';
                    _properties['text-color'] = '#ffffff';
                    _properties['title'] = 'Completed';
                    break;
                case 'upcoming':
                    _properties['color'] = this.$vuetify.theme.themes.light['sa-background'];
                    _properties['text-color'] = '#666666';
                    _properties['title'] = 'Upcoming';
                    break;
          }

          if(this.canceled){
                _properties['color'] = this.$vuetify.theme.themes.light['sa-background'];
                _properties['text-color'] = '#000000';
                _properties['title'] = 'Canceled';
          }

          return _properties;
      }
  }

};
</script>

<style lang="scss" scoped>
.sa-common-chip-progress{
    font-size:0.625rem;
    height: 1.25rem;
}
</style>
