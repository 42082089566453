<template>
    <div class="sa-block-ads sa-block-ads--social-icons d-flex justify-center align-center">
        <div class="ads-container">
            <div class="ad icons-ad" v-for="ad in ads" :key="ad.title" :class="ad.css_class">
                <a :href="ad.url" target="_blank" :aria-label="ad.title" rel="noopener">
                    <v-icon
                    v-if="ad.css_class === 'feather'"
                      color="white"
                      size="18"
                    >
                      {{ ad.icon }}
                    </v-icon>
                    <img
                      v-else-if="ad.icon == 'sf-twitter'"
                      src="@/assets/icons/x-logo-white.svg"
                      style="width: 16px; height: 16px"
                    />
                    <span v-else :class="ad.icon"></span>
                </a>
            </div>
        </div>
	</div>
</template>

<script>

export default {
    name: "BlockAdSocialIcons",
    data: () => ({
        ads:[
            {
                title:'Facebook',
                icon:'sf-facebook',
                css_class:'',
                url:'https://www.facebook.com/prcaprorodeo'
            },{
                title:'Twitter',
                icon:'sf-twitter',
                css_class:'',
                url:'https://twitter.com/prca_prorodeo'
            },
            {
                title:'Instagram',
                icon:'sf-instagram',
                url:'https://www.instagram.com/prca_prorodeo/'
            },
            {
                title:'YouTube',
                icon:'$youtube',
                css_class:'feather',
                url:'https://www.youtube.com/prcaprorodeo'
            }
        ]
    })
};
</script>

<style lang="scss">
.sa-block-ads--social-icons{
    .ads-container{
        display: flex;
        align-items: center;
    }
    .ad{
        display:inline-block;
        margin: 0 $spacer*2;
        &:first-child{
            margin-left:0;
        }
        &:last-child{
            margin-right:0;
        }
    }
    .ad a{
        display:flex;
        align-items: center;
        justify-content:center;        
        width:40px;
        height:40px;
        background:$color-secondary;
        color:$color-white;
        text-decoration:none;
        text-align:center;
        line-height:40px;
        font-size:20px;
        border-radius:50%;
        transition:0.2s;
        &:hover, &:focus{
            background:$color-primary
        }
    }
    .ad.feather a{
       font-size: 18px;;
    }
}
</style>

