<template>
    <div v-if="rodeo">

        <!-- Rodeo Details -->
        <div
            class="sa-rodeos-list-item"
            :class="condensed ? '' : expanded ?  'white py-4 px-8' : 'white pa-8'"
        >
            <div v-if="!condensed" class="d-block d-md-flex align-center sa-rodeos-list-item__body">
                <div class="sa-rodeos-list-item__main d-flex align-center flex-grow-1 flex-shrink-1">
                    <div class="sa-rodeos-list-item__content flex-grow-1 flex-shrink-1">
                        <span class="d-block type-subtext  sa-safe-text--text">
                            <span class="secondary--text font-weight-bold text-uppercase">{{ date }}</span>
                            <span class="d-inline-block mx-2">&bull;</span>
                            <span class="text-no-wrap">{{ rodeo.City }}, {{ rodeo.StateAbbrv }}</span>
                        </span>
                        <router-link :to="status === 'completed' || status === 'in-progress' ? rodeo.ResultLink() : rodeo.ProfileLink()" class="text-link"><span class="type-paragraph font-weight-bold">{{ rodeo.Name}}</span></router-link>
                        <div v-if="expanded || (status != 'upcoming')" class="type-subtext"><span class="sa-safe-text--text">Added Money: </span>{{ rodeo.Payout | currency}}</div>
                        <br /><common-chip-progress-sidearm v-if="!expanded" :status="status" :canceled="!rodeo.IsActive" />
                    </div>
                </div>
                <div v-if="!expanded || $vuetify.breakpoint.mdAndUp" class="sa-rodeos-list-item__links flex-grow-0 flex-shrink-0">
                    <v-btn
                        @click="toggleStats"
                        v-if="showStats"
                        :height="60"
                        :color="statsVisible ? 'secondary' : 'primary'"
                    >
                        Results
                    </v-btn>
                    <ul
                        v-else-if="links && links.length"
                        class="reset-list link-items d-flex flex-wrap d-md-block"
                    >
                        <li class="link-item" v-for="link in links" :key="link.text">
                            <router-link v-if="link.route" class="link-item__url text-link d-flex align-center" :to="link.route" >
                                <v-icon class="link-item__icon" small>$arrowRight</v-icon><span class="link-item__text">{{link.text}}<span class="d-sr-only"> for {{rodeo.Name}}</span></span>
                            </router-link> 
                            <a v-else class="link-item__url text-link d-flex align-center" :href="link.url" target="_blank" rel="noopener">
                                <v-icon class="link-item__icon" small>$arrowRight</v-icon><span class="link-item__text">{{link.text}}<span class="d-sr-only"> for {{rodeo.Name}}</span></span>
                            </a>
                        </li>
                    </ul>
                    
                </div>
            </div>

            <div v-if="expanded" class="sa-rodeos-list-item__footer d-flex flex-column-reverse flex-md-row justify-space-between align-stretch align-md-center bt-md-dashed--light pt-md-4 mt-md-4">
                <div class="d-flex justify-start align-start bt-dashed--light bt-md-none pt-4 pt-md-0 mt-4 mt-md-0">
                    <ul v-if="links && links.length && $vuetify.breakpoint.smAndDown" class="reset-list link-items d-block mr-auto">
                        <li class="link-item" v-for="link in links" :key="link.text">
                            <router-link v-if="link.route" class="link-item__url text-link d-flex align-center" :to="link.route" >
                                <v-icon class="link-item__icon" small>$arrowRight</v-icon><span class="link-item__text">{{link.text}}<span class="d-sr-only"> for {{rodeo.Name}}</span></span>
                            </router-link> 
                            <a v-else class="link-item__url text-link d-flex align-center" :href="link.url" rel="noopener" target="_blank">
                                <v-icon class="link-item__icon" small>$arrowRight</v-icon><span class="link-item__text">{{link.text}}<span class="d-sr-only"> for {{rodeo.Name}}</span></span>
                            </a>
                        </li>
                    </ul>
                    <common-chip-progress-sidearm :status="status" :canceled="!rodeo.IsActive" />
                </div>
                <ul class="bt-dashed--light bt-md-none reset-list tags d-flex flex-wrap pt-4 pt-md-0 mt-4 mt-md-0" v-if="rodeo.TourIds.length || rodeo.CircuitIds.length">
                    <template  v-for="tour in rodeo.TourIds">
                        <li  v-if="isValidTour(tour)" class="tag" :key="tour">
                            <span class="tag__url d-flex align-center" ><v-icon size="12" class="mr-1">$mdiTag</v-icon> {{tourNameById(tour)}}</span>
                        </li>
                    </template>
                    <template  v-for="circuit in rodeo.CircuitIds">
                    <li class="tag"  v-if="isValidCircuit(circuit)" :key="circuit">
                        <span  class="tag__url d-flex align-center" ><v-icon size="12" class="mr-1">$mdiTag</v-icon> {{circuitNameById(circuit)}}</span>
                    </li>   
                    </template>             
                </ul>
            </div>

            <div v-if="condensed" class="d-flex align-start">
                <div class="sa-rodeos-list-item__content flex-grow-1 flex-shrink-1">
                    <div class="type-subtext  sa-safe-text--text">
                        <span class="secondary--text font-weight-bold text-uppercase">{{ date }}</span>
                        <span class="d-inline-block mx-2">&bull;</span>
                        <span class="text-no-wrap">{{ rodeo.City }}, {{ rodeo.StateAbbrv }}</span>
                    </div>
                    <router-link :to="status === 'upcoming' ? rodeo.ProfileLink() : rodeo.ResultLink()" class="text-link"><span class="type-paragraph font-weight-bold">{{ rodeo.Name}}</span></router-link>
                    <br />
                    <common-chip-progress-sidearm :status="status" :canceled="!rodeo.IsActive" />
                </div>
            </div>
        </div>

        <!-- Rodeo Stats -->
        <div
            v-if="statsVisible"
            style="background-color: rgb(239 239 239)"
            class="px-8"
        >
            <element-result-grid-view
                :rodeo-id="rodeo.RodeoId"
            />
        </div>
    </div>
</template>

<script>
import CommonChipProgressSidearm from "@/components/common/CommonChipProgressSidearm";
import ElementResultGridView from "@/components/elements/results/ElementResultGridView"


export default {
    name: "ElementRodeosListItem",

    props:{
        rodeo:{
            type: Object,
            required: true,
            default:null
        },
        expanded:{
            type: Boolean,
            default:false
        },
        condensed:{
            type:Boolean,
            default:false
        },
        showStats: {
            type: Boolean,
            required: false,
            default: false,
        }
    },

    components:{
        CommonChipProgressSidearm,
        ElementResultGridView,
    },

    data: () => ({
        statsVisible: false,
    }),

    computed:{
        date(){
            if(!this.rodeo){
                return null;
            }

            let _format = 'MMM D';

            if(new Date(this.rodeo.StartDate).getFullYear() != this.$root.currentYear){
                _format = 'MMM D, YYYY';
            }

            let _date = this.$options.filters.moment(this.rodeo.StartDate,_format);
        
            if(this.rodeo.EndDate && this.rodeo.EndDate != this.rodeo.StartDate){
                _date += ' - ' + this.$options.filters.moment(this.rodeo.EndDate,_format);
            }

            return _date;
        },
        status(){
            if(!this.rodeo){
                return null;
            }
            else if(this.rodeo.InProgress || this.$moment(new Date()).isBetween(this.rodeo.StartDate,this.rodeo.EndDate)){
                return "in-progress";
            }
            // if the rodeo starts today or after
            else if(this.$moment(this.rodeo.StartDate).isSameOrAfter(this.$moment(), 'day')){
                return "upcoming";
            }
            // if the rodeo ends today or previously
            else if(this.$moment(this.rodeo.EndDate).isSameOrBefore(this.$moment(), 'day')){
                return "completed";
            } 
            else {
                return null;
            }
        },
        links(){
            let _links = []
            if(this.status != "upcoming"){
                if(this.rodeo.IsActive){
                    

                    _links.push({ text:"See Results", route: this.rodeo.ResultLink() });
                    
                    if (this.rodeo.HasDaysheets) {
                        _links.push({ text:"Daysheet", route: this.rodeo.DaysheetResultLink() });
                    }
                }
            }else{
                _links.push({ text:"Rodeo Profile", route: this.rodeo.ProfileLink() });
                
                if (this.rodeo.HasDaysheets) {
                    _links.push({ text:"Daysheet", route: this.rodeo.DaysheetProfileUrl() });
                }
                
                if(this.rodeo.WebsiteUrl){
                    _links.push({ text:'Website', url: this.rodeo.WebsiteUrl});
                }
            }
            return _links;
        }
    },
    methods:{
        toggleStats() {
            this.statsVisible = !this.statsVisible
        },

        isValidTour(id){
            let _tours = this.$root.tours;
            return _tours && _tours.length && _tours.findIndex((t) => t.TourId == id) > -1;
        },
        isValidCircuit(id){
            let _circuits = this.$root.circuits;
            return _circuits && _circuits.length && _circuits.findIndex((c) => c.CircuitId == id) > -1;
        },
        tourNameById(id){
            let _tours = this.$root.tours;
            if(this.isValidTour(id)){
                return _tours[_tours.findIndex((t) => t.TourId == id)].Name;
            }
            return '';
        },
        circuitNameById(id){
            let _circuits = this.$root.circuits;
            if(this.isValidCircuit(id)){
                return _circuits[_circuits.findIndex((c) => c.CircuitId == id)].Name;
            }
            return '';
        }
    }
};
</script>

<style lang="scss" scoped>
    .sa-rodeos-list-item__links{
        padding-top:$spacer*4;
        margin-top:$spacer*4;
        position: relative;
        &::before{
            position: absolute;
            top:0;
            left:0;
            width:32px;
            content:'';
            border-top:dashed 1px $color-border-light;
        }
    }
    .link-items{
        .link-item{
            font-weight:700;
            text-transform: uppercase;
            line-height: 1.5;
            font-size:0.75rem;
            margin-right: $spacer*4;
           &__icon{
               margin-right:$spacer*2;
           }
        }
    }

    .tags{
        .tag{
            margin-right:$spacer * 4;
            &:last-child{
                margin-right:0;
            }
            &__url{
                color:$color-gray-dark;
                font-size:0.75rem;
                line-height:1.5;
                text-decoration:none;
                transition:0.2s;
                &:hover{
                    //color:$color-secondary;
                }
            }
        }
    }

    @media #{map-get($display-breakpoints, 'md-and-up')}{
        .sa-rodeos-list-item__links{
            padding-top:0;
            margin-top:0;
            &::before{
                display: none;
            }
        }
        .link-items{
            margin:0;
            padding:0;
            .link-item{
                margin-right:0;
                margin-bottom:$spacer * 4;
                &:last-child{
                    margin-bottom:0;
                }
            }
        }

    }

</style>

