import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import ForgotPassword from "../views/account/ForgotPassword.vue"
import Stock from "../views/athletes/Stock.vue"
import {get, map_athletes} from "@/helpers";

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: '/account/forgotpassword/:guid',
    name: 'ForgotPassword',
    component: ForgotPassword,
    props: true,
  },
  {
    path: "/news",
    name: "News",
    component: () =>
      import(/* webpackChunkName: "News" */ "../views/news/News.vue"),
  },
  {
    path:"/news-notes-archive",
    redirect:"/news"
  },{
    path: "/news*/:year/:month/:day/:filename",
    props:true,
    component: () =>
      import(/* webpackChunkName: "News" */ "../views/news/NewsStory.vue"),
  },{
    path: "/standings",
    name: "Standings",
    component: () =>
      import(/* webpackChunkName: "Standings" */ "../views/standings/Standings.vue"),
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: () =>
      import(/* webpackChunkName: "Schedule" */ "../views/schedule/Schedule.vue"),
  },{
    path: "/schedule/:year/:name/:id/:daysheet?",
    name: "Rodeo",
    props:true,
    component: () =>
      import(/* webpackChunkName: "Schedule" */ "../views/schedule/ScheduleRodeo.vue"),
  },{
    path: "/results",
    name: "Results",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Results" */ "../views/results/Results.vue"),
  },
  {
    // path: "/result/:year/:name/:id/:daysheet?", daysheet now included with query param like the other tabs.
    path: "/result/:year/:name/:id",
    name:"Result",
    props:true,
    component: () =>
      import(/* webpackChunkName: "Results" */ "../views/results/ResultsRodeo.vue"),
  },
  {
    path: "/athletes",
    name: "Athletes",
    component: () =>
      import(/* webpackChunkName: "Athletes" */ "../views/athletes/Athletes.vue"),
  },
  {
    path: "/stock",
    name: "Stock",
    component: Stock
  },
  {
    path: "/bio/:type/:name/:id",
    name: "Bio",
    props:true,
    component: () =>
      import(/* webpackChunkName: "Athletes" */ "../views/athletes/AthletesBio.vue"),
  },{
    path: "/documents/:year/:month/:day/:filepath",
    name: "Documents",
    props:true,
    component: () =>
      import(/* webpackChunkName: "Document" */ "../views/Document.vue"),
  },{
    path: "/tours",
    name: "Tours",
    component: () =>
      import(/* webpackChunkName: "Tours" */ "../views/tours/Tours.vue"),
  },{
    path: "/nfr",
    component: () =>
      import(/* webpackChunkName: "Nfr" */ "../views/nfr/Nfr.vue"),
        children:[
            {
            path: '',
            component: () =>
                import(/* webpackChunkName: "Nfr" */ "../views/nfr/NfrHome.vue"),
            },
            {
                path: 'contestants',
                component: () =>
                    import(/* webpackChunkName: "Nfr" */ "../views/nfr/NfrContestants.vue"),
            },
            {
                path: 'results',
                component: () =>
                    import(/* webpackChunkName: "Nfr" */ "../views/nfr/NfrResults.vue"),
            },
            {
                path: 'rankings',
                component: () =>
                    import(/* webpackChunkName: "Nfr" */ "../views/nfr/NfrRankings.vue"),
            },
            {
                path: 'projected',
                component: () =>
                    import(/* webpackChunkName: "Nfr" */ "../views/nfr/NfrProjectedStandings.vue"),
            },
            {
                path: 'topgun',
                component: () =>
                    import(/* webpackChunkName: "Nfr" */ "../views/nfr/NfrTopGun.vue"),
            },
            {
                path: 'watch',
                component: () =>
                    import(/* webpackChunkName: "Nfr" */ "../views/nfr/NfrWatch.vue"),
            }
        ]
  },{
    path: "*/cowboys/cowboy-biographies",
    beforeEnter: (to,from,next) => {
        let request_options = {
            cowboyid:to.query.id
        };
        get("/services/pro_rodeo.ashx/getcontestantid", request_options).then(response => {
            if(response && response.data === 0){
                next({name:'Missing'});
            }else{
                let request_options = {
                    ids:response
                };
                get("/services/pro_rodeo.ashx/athletes", request_options).then(response => {
                    let _redirect = map_athletes(response[0]).BioLink();
                    _redirect.replace = true;
                    next(_redirect);
                });
            }
        }).catch((e) =>{
            if(e){ console.log(e); }
            next({name:'Missing'});
        });
    }
  },
  {
    path:"/prorodeo/rodeo/rodeo-results/*",
    redirect:"/results"
  },
  {
    path:"/prorodeo/rodeo/rodeo-schedule*",
    redirect:"/schedule"
  },
  {
    path:"/prorodeo/standings*",
    redirect:"/standings"
  },
  {
    path:"/prca-ram-world-standings*",
    redirect:"/standings"
  },
  {
    path:"/prca-circuit-standings*",
    redirect:"/standings"
  },{
    path:"/prorodeo/find-a-rodeo*",
    redirect:"/schedule"
  },{
    path:"/prorodeo/prca-stock-stats/*",
    redirect:"/athletes?type=stock"
  },{
    path:"/Resources/html/livestream-sept2020.html",
    redirect:"/prorodeo/tv/online-streaming-schedule"
  },{
    path:"/Resources/html/streaming-6-25-2020.html",
    redirect:"/prorodeo/tv/online-streaming-schedule"
  },
  {
    path:"/prorodeo/national-finals-rodeo",
    redirect:"/-national-finals-rodeo"
  },{
    path:"/prorodeo/media/pro-rodeo-sports-news",
    redirect:"/prorodeo/media/pro-rodeo-sports-news/pro-rodeo-sports-news"
  },{
    path:"/prorodeo/national-finals-rodeo/national-finals-rodeo-news/wrangler-nfr-payoff",
    redirect:"/prca-payout"
  },{
    path:"/prorodeo/national-finals-rodeo/wnfr-associated-events/all-events",
    redirect:"/more-nfr-events"
  },
  {
    path: "/missing",
    name: "Missing",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/NotFound.vue"),
  },
  {
    path: "*",
    name: "Sportfile",
    props:getSlugFromRoute,
    component: () =>
      import(/* webpackChunkName: "Sportfile" */ "../views/Sportfile.vue"),
  }
];

function getSlugFromRoute(route){
    if(route.fullPath.startsWith('/sports/')){
        let _params = route.fullPath.split('/');
        return {
            year:_params[2],
            month:_params[3],
            day:_params[4],
            filename:_params[5]
        }
    }
    return {slug: route.fullPath}
}

const router = new VueRouter({
    mode: "history",
    scrollBehavior(to, from, savedPosition) { // eslint-disable-line no-unused-vars

        // PRODEO-226
        if (to.name == "Results") {
          return savedPosition; // Scroll to stored position, which is only available if this is a popstate navigation (triggered by the browser's back/forward buttons)
        }

        if(to.name == from.name)
            return // No scrolling if just changing query params.
        return { x: 0, y: 0 };
    },
    base: '/',
    routes
});

router.beforeEach((to, from, next) => {
    document.title = 'PRCA Sports News';
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Determines if browser back/forward buttons are clicked
    const wasBrowserButtonPushed = window.popStateDetected;
    window.popStateDetected = false;

    // PRODEO-226
    if (wasBrowserButtonPushed && to.name == "Results") {
      to.params.loadResultsFromHistory = true;
    }

    next();
});


router.afterEach((to,from) => {

    if(window.gtag){

        if(to.name === "Missing" && from && from.fullPath != "/"){
            try{
                window.gtag('event', 'page_view', {
                    page_location: window.location.href,
                    page_path:to.fullPath + '?url=' + from.fullPath
                });
            }catch(e){
                console.warn(e);
            }
        }else{
            try {
                window.gtag('event', 'page_view', {
                    page_location: window.location.href,
                    page_path: to.fullPath,
                });
            } catch (e) {
                console.warn(e);
            }
        }

    }

    try {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'dataLayerLoaded',
        'non_interaction_flag': true,
        'gaPropertyID':'UA-195103076-20',
        'schoolAttributes':{
          'Division':'SPECIALPROJECTS',
          'ssClientID':'1821'
        }
      });
    }
    catch(e) {
      console.warn(e);
    }

});

export default router;
