<template>
    <div class="sa-common-title d-flex align-center">
        <img class="sa-common-title__image flex-shrink-0 mr-2" height="33" width="34"  src="@/assets/images/image_stripes.svg" alt=""/>
        <component :is="type" class="sa-common-title__text ma-0 pa-0 flex-shrink-1 d-flex flex-column justify-center align-start text-uppercase">
            <span class="sa-common-title__top secondary--text">
               {{ top }}
            </span>
            <span class="sa-common-title__bottom primary--text">
                {{ bottom }}
            </span>
        </component>
    </div>
</template>

<script>

export default {
  name: "CommonTitle",
  props:{
      top:{
        type:[String, Number],
        default:''
      },
      bottom:{
        type:[String,Number],
        default:''
      },
      type:{
          type:String,
          default:'h2'
      }
  },
  data: () => ({}),

};
</script>

<style lang="scss" scoped>
.sa-common-title{
    &__top{
        font-size: 0.75rem;
        font-weight:900;
    }
    &__bottom{
        line-height: 1;
        font-size: 1.313rem;
        font-weight:900;
    }
}
</style>
