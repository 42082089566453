<template>
<v-dialog v-if="!user || isSignUp" class="sa-block-account-modal" v-model="dialog" overlay-opacity=".8" :fullscreen="$vuetify.breakpoint.smAndDown" persistent overlay-color="#000000" max-width="98%" width="628">
    <template v-slot:activator="{ on, attrs }">
        <v-btn 
            outlined
            v-bind="attrs"
            v-on="on"
            height="34"
            min-width="0"
            class="mx-4 mr-lg-0 ml-lg-2 sa-block-account-modal__button"
            v-if="!user || (isSignUp && !signedUp)"
            :max-width="$vuetify.breakpoint.lgAndUp ? '72' : '60'"
        >Login</v-btn>
    </template>
    <v-card class="sa-drop-shadow" :tile="$vuetify.breakpoint.smAndDown">
            <div class="bb-dashed py-8 pl-8 pr-4 d-flex align-center">
                <common-title class="mr-auto" top="My" bottom="ProRodeo" type="h2"/>
                <v-btn icon text v-if="!user && (isSignUp || isRecover)" @click="isSignUp = false; isRecover = false;" color="secondary"><v-icon>$arrowLeft</v-icon></v-btn>
                <v-btn v-if="!user" icon text @click="dialog = false" aria-label="close modal" color="primary"><v-icon>$close</v-icon></v-btn>
            </div>
            <div v-if="!user && !isSignUp && !isRecover">
                <div class="pa-8">
                    <v-alert v-if="loginError" color="secondary" text dense>{{loginError}}</v-alert>
                    <v-text-field
                    label="Email"
                    single-line
                    outlined
                    v-model="email"
                    hide-details
                    class="mb-2 sa-block-account-modal__input"
                    prepend-inner-icon="$user"
                    :rounded="false"
                    @keydown="handleLogin"
                    ></v-text-field>
                    <v-text-field
                        label="Password"
                        single-line
                        outlined
                        v-model="password"
                        hide-details
                        type="password"
                        class="mb-2 sa-block-account-modal__input"
                        prepend-inner-icon="$password"
                        :rounded="false"
                        @keydown="handleLogin"
                    ></v-text-field>
                    <div class="d-block d-md-flex align-center justify-space-between">
                        <v-checkbox
                        class="mt-0 pt-0 mb-3 mb-md-0"
                        label="Keep me signed in"
                        hide-details
                        v-model="stayLoggedIn"
                        dense
                        color="sa-safe-text"
                        ></v-checkbox>
                        <a @click="isRecover = true" class="sa-block-account-modal__text-link type-subtext font-weight-bold">Forgot Password?</a>
                    </div>

                </div>
                <div class="bt-dashed pa-8">
                    <v-btn block class="mb-2" color="primary"  @click="logInWithEmail">Login</v-btn>
                    <v-btn v-if="false" block class="mb-2" dark color="#3B5999"><v-icon small class="mr-1">$mdiFacebook</v-icon> Login with Facebook</v-btn>
                    <v-btn v-if="false" block class="mb-2" dark color="#B23221"><v-icon small class="mr-1">$mdiEmail</v-icon> Login with GMail</v-btn>
                    <v-btn block  color="sa-background" class="sa-safe-text--text" @click="isSignUp = true">Sign Up</v-btn>
                </div>
            </div>
            <div v-if="!user && isSignUp">
                <v-form ref="signUpForm" lazy-validation>
                    <div class="pa-8">
                         <v-alert v-if="signUpError" color="secondary" text dense>{{signUpError}}</v-alert>
                        <div class="primary--text type-subheading font-weight-black text-uppercase mb-4">
                            <span class="secondary--text">1.</span> Your Details
                        </div>
                        <v-text-field
                        label="First Name"
                        single-line
                        validate-on-blur
                        outlined
                        v-model="signUpFirstName"
                        hide-details="auto"
                         :rules="nameRules"
                        class="mb-2 sa-block-account-modal__input"
                        prepend-inner-icon="$user"
                        :rounded="false"
                        ></v-text-field>
                        <v-text-field
                        label="Last Name"
                        single-line
                        outlined
                        validate-on-blur
                         :rules="nameRules"
                        v-model="signUpLastName"
                        hide-details="auto"
                        class="mb-2 sa-block-account-modal__input"
                        prepend-inner-icon="$user"
                        :rounded="false"
                        ></v-text-field>
                        <v-text-field
                        label="Email"
                        single-line
                        outlined
                        validate-on-blur
                        v-model="signUpEmail"
                        hide-details="auto"
                        :rules="emailRules"
                        class="mb-2 sa-block-account-modal__input"
                        prepend-inner-icon="$mdiEmailOutline"
                        :rounded="false"
                        ></v-text-field>
                        <div class="primary--text type-subheading font-weight-black text-uppercase mb-4 mt-8">
                            <span class="secondary--text">2.</span> Create a Password
                        </div>
                        <v-text-field
                            label="Create Password"
                            single-line
                            outlined
                            v-model="signUpPassword"
                            validate-on-blur
                            hide-details="auto"
                            :rules="passwordRules"
                            type="password"
                            class="mb-2 sa-block-account-modal__input"
                            prepend-inner-icon="$password"
                            :rounded="false"
                        ></v-text-field>
                        <v-text-field
                            label="Verify Password"
                            single-line
                            outlined
                            v-model="signUpPasswordVerify"
                            :rules="[matchRules]"
                            hide-details="auto"
                            type="password"
                            class="mb-2 sa-block-account-modal__input"
                            prepend-inner-icon="$password"
                            :rounded="false"
                        ></v-text-field>
                    </div>
                    <div class="bt-dashed pa-8">
                        <v-btn block class="mb-2" color="primary" @click="signUpWithEmail">Sign Up</v-btn>
                        <v-btn v-if="false" block class="mb-2" dark color="#3B5999"  ><v-icon small class="mr-1">$mdiFacebook</v-icon> Sign Up with Facebook</v-btn>
                        <v-btn v-if="false" block class="mb-2" dark color="#B23221"  ><v-icon small class="mr-1">$mdiEmail</v-icon> Sign Up with Gmail</v-btn>
                    </div>
                </v-form>
            </div>
            <div v-if="user && isSignUp">
                <div class="pa-8">
                    <div class="primary--text type-subheading font-weight-black text-uppercase mb-4">
                        <span class="secondary--text">Welcome,</span> {{ user.name }}
                    </div>
                    <p>Thank you for signing up. Now that you're logged in, you can follow up to {{ maxFollowAllowed}} of your favorite contestants and/or stock.</p>
                    <div v-if="followingContestants.length">
                      
                        <div class="d-flex align-center justify-start flex-wrap">
                            <div class="type-subtext sa-safe-text--text mr-2 mb-2">{{ followingContestants.length }}/{{ maxFollowAllowed}}:</div>
                            <v-chip close @click:close="unfollowContestant(contestant)"  class="mr-2 mb-2" small v-for="contestant in followingContestants" :key="contestant.ContestantId">
                                {{ contestant.FirstName}} {{ contestant.LastName}}
                            </v-chip>
                        </div>
                    </div>
                    <v-text-field class="pa-0 ma-0 ba-solid--light" hide-details label="Search"  v-model="searchInput" @keydown="handleSearchInput" single-line>
                        <template #append>
                            <v-btn :disabled="!searchInput" @click="searchContestants" small text color="secondary" class="mr-n6"><v-icon size="16">$search</v-icon></v-btn>
                        </template>
                    </v-text-field>
                    <div v-if="loadingContestants">
                        <div class="d-flex align-center justify-center pa-12">
                            <v-progress-circular
                            indeterminate
                            color="primary"
                            ></v-progress-circular>
                        </div>
                    </div>
                    <div v-else>
                        <ul class="reset-list">
                            <li v-for="contestant in filteredContestants" :key="contestant.contestantId" class="d-flex align-center justify-start mt-8">
                                <common-avatar-sidearm max-width="32" min-width="32" type="contestant" :src="contestant.PhotoUrl"  :alt="contestant.FirstName + ' ' + contestant.LastName"  tile />
                                <div class="ml-4 mr-auto primary--text font-weight-bold">{{contestant.FirstName}} {{contestant.LastName}}</div>
                                <v-btn small color="sa-background" @click="followContestant(contestant)" class="sa-safe-text--text">Follow</v-btn>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="bt-dashed pa-8">
                    <v-btn block class="mb-2" color="primary" :disabled="followingContestants.length == 0" @click="saveSetting">Save Favorites</v-btn>
                    <v-btn block light color="sa-background" class="sa-safe-text--text" @click="dialog = false; isSignUp = false">Skip for now</v-btn>
                </div>
            </div>
            <div v-if="!user && isRecover">
                <div class="pa-8" >
                    <div class="primary--text type-subheading font-weight-black text-uppercase mb-4">
                            Account Email
                        </div>
                    <v-text-field
                    v-if="!sentRecoverEmail"
                    label="Email"
                    single-line
                    outlined
                    v-model="recoverEmail"
                    hide-details
                    class="sa-block-account-modal__input"
                    prepend-inner-icon="$user"
                    :rounded="false"
                    ></v-text-field>
                    <div v-else class="sa-safe-text--text pt-4">
                        Email submitted.  If there is an account, you will recieve instructions to reset your password.
                    </div>
                </div>
                    <div class="bt-dashed pa-8">
                    <v-btn block class="mb-2" color="primary" :disable="sentRecoverEmail"  @click="resetPassword">Reset Password</v-btn>
                 </div>
            </div>
    </v-card>
</v-dialog>
</template>

<script>
import { get } from "@/helpers";
import findIndex from "lodash/findIndex";
import filter from "lodash/filter";
import { mapActions, mapState } from 'vuex';
import { recover_password }from '@/auth';
import CommonTitle from "@/components/common/CommonTitle";
import CommonAvatarSidearm from "@/components/common/CommonAvatarSidearm";

export default {
    name: "BlockAccountModal",
    components: {
         CommonTitle,
         CommonAvatarSidearm
    },
    data: () => ({
        dialog:false,
        email:'',
        password:'',
        stayLoggedIn:false,
        loginError:null,
        signUpEmail:'',
        signUpPassword:'',
        signUpPasswordVerify:'',
        signUpFirstName:'',
        signUpLastName:'',
        signUpError:null,
        recoverEmail:'',
        sentRecoverEmail:false,
        isSignUp: false,
        signedUp:true,
        isRecover:false,
        emailRules: [
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        nameRules: [
            v => !!v || 'Name is required'
        ],
        passwordRules: [
            v => !!v || 'Password is required'
        ],
        contestants: [],
        followingContestants:[],
        loadingContestants:true,
        searchInput:'',
        searchTerm:'',
        count:4,
        pageSize:50
    }),
    computed:{
        ...mapState({
            user: state => state.user,
            maxFollowAllowed: state => state.maxFollowAllowed
        }),
        matchRules(){
            return () => (this.signUpPassword === this.signUpPasswordVerify) || 'Password must match'
        },
        filteredContestants(){
            return filter(this.contestants, (c) => {
                return findIndex(this.followingContestants, (fc) =>{ return fc.ContestantId === c.ContestantId;}) == -1;
            });
        }
    },
    watch:{
        dialog(newVal){
            if(newVal && this.contestants.length == 0){
                this.getSuggestedContestants();
            }
        }
    },
    methods:{
        ...mapActions(['log_in_user','sign_up_user', 'follow_athletes']),
        handleLogin(e){
           if(e.keyCode == 13){
               this.logInWithEmail();
           }
        },
        logInWithEmail(){
            this.log_in_user([this.email,this.password,this.stayLoggedIn])
                .then(() => {
                    this.loginError = null;
                    this.stayLoggedIn = false;
                    this.email = '';
                    this.password = '';
                    this.dialog = false;
                })
                .catch(e =>{ 
                    this.loginError = e;
                });
        },
        signUpWithEmail(){
            if(this.$refs.signUpForm.validate()){
                this.sign_up_user([this.signUpEmail,this.signUpFirstName, this.signUpLastName, this.signUpPassword])
                    .then(()=>{
                        this.signUpError = null;
                        this.signUpEamil = '';
                        this.signUpFirstName = '';
                        this.signUpLastName = '';
                        this.signUpPassword = '';
                        this.signedUp = true;
                    })
                    .catch( e =>{
                        this.signUpError = e;
                    });
            }
        },
        resetPassword(){
            recover_password(this.recoverEmail).then(() => {
                this.sentRecoverEmail = true;
            });
        },
        saveSetting(){
            let _contestantIds = this.followingContestants.map((c) => c.ContestantId);
            this.follow_athletes({athletes:_contestantIds, type:'followed_contestants'});
            this.dialog = false;
            this.isSignUp = false;
        },
        followContestant(contestant){
            if(this.followingContestants.length < this.maxFollowAllowed){
                this.followingContestants.push(contestant);
            }
        },
        unfollowContestant(contestant){
            let _index = findIndex(this.followingContestants, (c) =>{ return c.ContestantId === contestant.ContestantId;});
            if(_index > -1){
                this.followingContestants.splice(_index,1);
            }
        },
        handleSearchInput(e){
           if(e.keyCode == 13){
               this.searchContestants();
           }
        },
         searchContestants(){
            this.searchTerm = this.searchInput;
            this.searchInput = '';
            this.contestants = [];
            this.getContestants();
        },
        getSuggestedContestants(){
            this.loadingContestants = true;
            let request_options = {
                year:this.$root.currentStaticYear,
                type:"world",
                event:"AA"
            };
            get("/services/pro_rodeo.ashx/standings", request_options).then(response => {
                if(response && response.length){
                    this.contestants = response.slice(0,4);
                }
                this.loadingContestants = false;
            });

        },
        getContestants(){
            this.loadingContestants = true;
            let request_options = {
                search_term:this.searchTerm,
                page_size:this.pageSize, 
                index:1
            };
            get("/services/pro_rodeo.ashx/athletes", request_options).then(response => {
                if(response && response.length){
                        this.contestants = response;
                }
                this.loadingContestants = false;
            });
        },
    }
};
</script>

<style lang="scss">
    .sa-block-account-modal__input{
        .v-input__prepend-inner{
        opacity: 0.5;
        }
        &.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset{
            color:$color-background;
        }
    }
</style>

<style lang="scss" scoped>
.sa-block-account-modal{
    &__button{
        transition: background-color 0.2s, color 0.2s;
        &:hover{
            background:white;
            color:$color-primary;
        }
    }
    &__text-link{
        text-decoration: none;
        color:$color-secondary;
        transition:color 0.2s;
        &:hover, &:focus{
            color:$color-primary;
        }
    }
}
</style>

