<template>
  <div class="sa-butler-square">

  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
import postscribe from "postscribe";

export default {
    name:'ElementAdButlerSqaure',
    props: {
        location: {
            type: String,
            default: ""
        }
    },
    data: () => ({}),
    mounted: function() {
        let adScript = "";
        switch(this.location){
            case "standings":
                adScript = '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script>\r\n<script type=\"text\/javascript\">\r\nvar AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];\r\nvar abkw = window.abkw || \'\';\r\nvar plc379542 = window.plc379542 || 0;\r\n document.write(\'<\'+\'div id=\"placement379542\'+plc379542+\'\"><\/\'+\'div>\');\r\nAdButler.ads.push({handler: function(opt){ AdButler.register(175684, 379542, [240,240], \'placement379542\'+opt.place, opt); }, opt: { place: plc379542++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});\r\n<\/script>';
                break;
            case "barrel-racing-standings":
                adScript = '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script>\r\n<script type=\"text\/javascript\">\r\nvar AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];\r\nvar abkw = window.abkw || \'\';\r\nvar plc493262 = window.plc493262 || 0;\r\ndocument.write(\'<\'+\'div id=\"placement493262\'+plc493262+\'\"><\/\'+\'div>\');\r\nAdButler.ads.push({handler: function(opt){ AdButler.register(175684, 493262, [240,240], \'placement493262\'+opt.place, opt); }, opt: { place: plc493262++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});\r\n<\/script>\r\n';
                break;
            case "breakaway-roping-standings":
                adScript = '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script>\r\n<script type=\"text\/javascript\">\r\nvar AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];\r\nvar abkw = window.abkw || \'\';\r\nvar plc501297 = window.plc501297 || 0;\r\ndocument.write(\'<\'+\'div id=\"placement501297\'+plc501297+\'\"><\/\'+\'div>\');\r\nAdButler.ads.push({handler: function(opt){ AdButler.register(175684, 501297, [240,240], \'placement501297\'+opt.place, opt); }, opt: { place: plc501297++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});\r\n<\/script>\r\n';
                break;
            case "homepage-1":
                adScript = '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script>\r\n<script type=\"text\/javascript\">\r\nvar AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];\r\nvar abkw = window.abkw || \'\';\r\nvar plc454482 = window.plc454482 || 0;\r\ndocument.write(\'<\'+\'div id=\"placement454482\'+plc454482+\'\"><\/\'+\'div>\');\r\nAdButler.ads.push({handler: function(opt){ AdButler.register(175684, 454482, [240,200], \'placement454482\'+opt.place, opt); }, opt: { place: plc454482++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});\r\n<\/script>';
                break;
            default:
                adScript = '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script>\r\n<script type=\"text\/javascript\">\r\nvar AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];\r\nvar abkw = window.abkw || \'\';\r\nvar plc353876 = window.plc353876 || 0;\r\ndocument.write(\'<\'+\'div id=\"placement353876\'+plc353876+\'\"><\/\'+\'div>\');\r\nAdButler.ads.push({handler: function(opt){ AdButler.register(175684, 353876, [240,240], \'placement353876\'+opt.place, opt); }, opt: { place: plc353876++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});\r\n<\/script>\r\n';

        }
        postscribe(this.$el, adScript);
    }
};
</script>
