<template>
<v-dialog class="sa-block-alert sa-block-alert--ie" :value="value" overlay-opacity=".8" :fullscreen="$vuetify.breakpoint.smAndDown" persistent overlay-color="#000000" max-width="98%" width="628">
    <v-card class="sa-drop-shadow" :tile="$vuetify.breakpoint.smAndDown">
            <div class="bb-dashed py-8 pl-8 pr-4 d-flex align-center">
                <common-title class="mr-auto" top="IE11" bottom="Update" type="h2"/>
            </div>
            <div>
                <div class="pa-8">
                    <p>Hi! We noticed that you're using a web browser that is delivering a compromised interactive experience with <a class="text-link" href="//prorodeo.com">prorodeo.com</a>.</p> 

                    <p>In the interest of feature-compatibility and security, support for this browser (Internet Explorer 11) will be discontinued in the near future.</p>
                        
                    <p>We don't want you to miss the latest from <a class="text-link" href="//prorodeo.com">prorodeo.com</a>, so we recommend upgrading to a more modern browser.</p>
                    <div class="pb-4">
                        <i class="sf-arrows-round-right"></i><a class="text-link" href="http://www.sidearmsports.com/about-us/browser-updates/">Learn More about Browser Updates</a>
                    </div>
                    <div class="d-flex align-center justify-space-between mb-8">
                        <a class="d-flex w-33 align-center text-link" href="https://www.microsoft.com/en-us/edge">
                            <img class="sa-block-alert__logo"  src="@/assets/logos/logo_browser_edge.svg" alt="Microsoft Edge logo" />
                            <div class="ml-2">
                                    <div>
                                    <span class="font-weight-bold">Upgrade Now</span>
                                </div>
                                <div class="type-subtext">to Microsoft Edge</div>
                            </div>
                        </a>
                        <a class="d-flex w-33  align-center text-link" href="https://www.google.com/chrome/">
                             <img  class="sa-block-alert__logo"  src="@/assets/logos/logo_browser_chrome.png"  alt="Google Chrome logo" />
                            <div class="ml-2">
                                    <div>
                                    <span class="font-weight-bold">Upgrade Now</span>
                                </div>
                                <div  class="type-subtext">to Google Chrome</div>
                            </div>
                        </a>
                        <a class="d-flex w-33 align-center text-link" href="https://www.mozilla.org/en-US/firefox/new/">
                             <img  class="sa-block-alert__logo" src="@/assets/logos/logo_browser_firefox.svg"  alt="Mozilla Firefox logo" />
                            <div class="ml-2">
                                <div>
                                    <span class="font-weight-bold">Upgrade Now</span>
                                </div>
                                <div  class="type-subtext">to Mozilla Firefox</div>
                            </div>
                        </a>
                    </div>
                    <v-btn block small @click="closeModal">Continue to Site</v-btn>
                </div>
                  
            </div>
    </v-card>
</v-dialog>
</template>

<script>
import CommonTitle from "@/components/common/CommonTitle";


export default {
    name: "BlockAlertIEModal",
     props:{
        value:{
            type:Boolean,
            default:false
        }
    },
    components: {
         CommonTitle,
    },
    data: () => ({}),
    methods:{
        closeModal(){
            var CookieDate = new Date;
            CookieDate.setDate(CookieDate.getDate() + 1);
            document.cookie = "sidearm-rodeo-ie-11-banner_consent=true; expires=' + CookieDate.toGMTString() + ';";
            this.$emit('input',!this.value);
        }
    }
};
</script>

<style lang="scss" scoped>
.sa-block-alert{
    &__logo{
        width:36px;
    }
}
</style>

