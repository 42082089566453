<template>
    <div>
        <!-- Controls -->
        <v-container
            v-if="eventTypes.length"
            class="px-0 py-2 px-lg-8"
        >
            <!-- On small screens, the v-row negative margin causes the component to take up more than 100% of the width. -->
            <v-row :class="{'mx-0': !$vuetify.breakpoint.mdAndUp }">
                <v-col
                    cols="12"
                    md="auto"
                >
                    <!-- Ride Timestamp Date Range -->
                    <!-- <v-menu
                        v-model="showDatePicker"
                        :close-on-content-click="false"
                        :disabled="selectedEventResultsRidetimestampsSorted.length == 0"
                        max-width="290"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                class="sa-filter-panel__select w-100"
                            >
                                <v-text-field
                                    @click:clear="clearDateRange"
                                    :value="computedDateFormattedMomentjs"
                                    :disabled="selectedEventResultsRidetimestampsSorted.length == 0"
                                    v-bind="attrs"
                                    v-on="on"
                                    label="Date"
                                    clearable
                                    readonly
                                    hide-details
                                    class="mx-0 mt-0 mb-4 pa-0"
                                ></v-text-field>
                            </div>
                        </template>
                        <v-date-picker
                            @change="changeDateRange"
                            v-model="filterDateRange"
                            :min="datePickerMin"
                            :max="datePickerMax"
                            :disabled="selectedEventResultsRidetimestampsSorted.length == 0"
                            range
                        ></v-date-picker>
                    </v-menu>
                </v-col> -->

                <!-- Event Type Select -->
                <!-- <v-col
                    cols="12"
                    md="6"
                    lg="auto"
                    class="py-2"
                > -->
                    <v-select
                        v-model="selectedEventType"
                        :items="eventTypes"
                        item-text="EventName"
                        return-object
                        hide-details
                        class="sa-drop-shadow mt-0 pt-0"
                    />
                </v-col>

                <!-- Money Winners Switch -->
                <v-col
                    cols="12"
                    md="auto"
                >
                    <div
                        class="d-flex justify-center"
                    >
                        <v-switch
                            v-if="rodeoResultsInternal && !rodeoResultsInternal.hideGridView && rodeoResultsInternal.ApResults"
                            v-model="showMoneyWinners"
                            label="Money Winners"
                            background-color="rgb(237 237 237)"
                            hide-details
                            class="ma-0 pa-0"
                        />
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <!-- Tables -->
        <!-- Don't ask me why this section uses methods instead of xyz. -->
        <!-- I'm just the refactor guy (¬_¬ ) (and the time to redo this is questionable). -->
        <div
            v-for="(GoRound,index) in eventResults"
            :key="index"
            :set="roundLabel = GoRound[0].GoRoundLabel"
            :id="roundLabel"
            class="pb-8 px-lg-8"
        >
            <v-container
                class="px-0 py-0"
            >
                <header
                    class="py-8 px-8 bt-dashed
                        px-lg-0"
                >
                    <common-title
                        top="Results"
                        :bottom="'Round: ' + roundLabel" type="h3"
                    />
                </header>
            </v-container>

            <div
                v-if="getEventResults(GoRound).length > 0"
                :set="filteredGoRound = getEventResults(GoRound)"
            >
                <common-table-sidearm
                    :columns="getResultColumns()"
                    :rows="formatRows(filteredGoRound)"
                    :key="((filteredGoRound[0])? filteredGoRound[0].EventType : '__') + roundLabel"
                    wide
                ></common-table-sidearm>
            </div>
            <v-container
                v-if="getEventResults(GoRound).length === 0"
                class="px-0 py-0
                    px-lg-8"
            >
                No payout data available.
            </v-container>
        </div>

        <!-- No Results -->
        <div
            v-if="rodeoResultsInternal && (!rodeoResultsInternal.Events || rodeoResultsInternal.Events.length == 0)"
            class="pa-4"
        >
            Results currently unavailable.
        </div>
    </div>
</template>

<script>
import CommonTableSidearm from "@/components/common/CommonTableSidearm"
import CommonTitle from "@/components/common/CommonTitle"

import { mapState } from 'vuex';
import { get, map_athletes} from "@/helpers";
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import uniq from 'lodash/uniq';
import moment from 'moment'

export default {
    name: "ElementResultGridView",

    components: {
        CommonTableSidearm,
        CommonTitle,
    },

    props: {
        // ElementResultGridView requires one of either rodeo-results or rodeo-id
        rodeoResults: {
            type: Object,
            required: false,
            default: null,
        },
        rodeoId: {
            type: Number,
            required: false,
        },
        useQueryParams: {
            type: Boolean,
            default: false,
        }
    },

    data: () => ({
        showDatePicker: false,
        filterDateRange: null,
        actualDate: new Date(),
        selectedEventType: null,
        showMoneyWinners: false,

        rodeoResultsInternal: null,
    }),

    computed: {
        computedDateFormattedMomentjs() {
            let result = ''
            if (this.filterDateRange) {
                let date1 = moment(this.filterDateRange[0]).format('M/D/YYYY')
                let date2 = this.filterDateRange[1] ? moment(this.filterDateRange[1]).format('M/D/YYYY') : null
                if (!date2 || date1 == date2) {
                    return date1
                } else {
                    return `${date1} - ${date2}`
                }
            }
            return result
        },

        queryEventTypeId() {
            return this.$route.query.eventTypeId
        },

        queryShowMoneyWinners() {
            return this.$route.query.showMoneyWinners
        },

        /* selectedEventResultsRidetimestampsSorted() {
            if(this.selectedEventType
            && this.rodeoResultsInternal
            && this.rodeoResultsInternal.Events
            && this.rodeoResultsInternal.Events[this.selectedEventType.EventAbbrev])
            {
                return Object.values(this.rodeoResultsInternal.Events[this.selectedEventType.EventAbbrev])
                    .flat()
                    .map(result => new Date(result.RideTimestamp))
                    .sort((a, b) => a - b) // Ascending is usually b - a, not sure why a - b is ascending in this case.
            }
            
            return []
        }, */

        /*  datePickerMin() {
             return this.selectedEventResultsRidetimestampsSorted.length > 0
                 ? this.selectedEventResultsRidetimestampsSorted[0].toISOString()
                 : null
         },
 
         datePickerMax() {
             const timestampsArrayLength = this.selectedEventResultsRidetimestampsSorted.length
             return timestampsArrayLength > 0
                 ? this.selectedEventResultsRidetimestampsSorted[timestampsArrayLength - 1].toISOString()
                 : null
         }, */

        // START computed code stripped from ResultsRodeo.
        ...mapState({
            user: state => state.user,
        }),
        eventTypes() {
            let _events = [];
            if (this.$root.eventTypes && this.rodeoResultsInternal && this.rodeoResultsInternal.Events) {
                let _rodeoResultsEventTypes = uniq(keys(this.rodeoResultsInternal.Events, "EventTypes"));
                _events = filter(this.$root.eventTypes, (eventType) => {
                    return includes(_rodeoResultsEventTypes, eventType.EventAbbrev);
                });
            }

            return _events;
        },
        eventResults() {
            if (this.rodeoResultsInternal && this.rodeoResultsInternal.Events && this.selectedEventType && this.rodeoResultsInternal.Events[this.selectedEventType.EventAbbrev]) {
                var _results = {};
                Object.keys(this.rodeoResultsInternal.Events[this.selectedEventType.EventAbbrev]).reverse().forEach((key) => {
                    _results[key + '_results'] = this.rodeoResultsInternal.Events[this.selectedEventType.EventAbbrev][key]
                });
                // console.log(_results);
                return _results;
            }
            return null;
        },
        // END computed code stripped from ResultsRodeo.
    },

    methods: {
        clearDateRange() {
            this.filterDateRange = null
        },

        changeDateRange() {
            this.showDatePicker = false
        },

        /* setDefaultDateRange() {
            // selectedEventResultsRidetimestampsSorted
            if(this.selectedEventResultsRidetimestampsSorted.length) {
                this.filterDateRange = [this.datePickerMin, this.datePickerMax]
            }
        }, */

        // START methods code stripped from ResultsRodeo.
        getEventResults(GoRound) {
            if (!this.showMoneyWinners) {
                return GoRound
            } else {
                let eventResults = GoRound.filter(function (el) {
                    return el.Payoff > 0
                });
                return eventResults
            }
        },
        getResultColumns(event = this.selectedEventType) {
            let _columns = [{
                label: 'Place',
                field: 'SortPlace',
                displayField: 'DisplayPlace',
                type: 'number',
                align: 'center',
                divider: true,
                sortable: true,
                fixed: true,
                cellClass: 'secondary--text font-weight-black type-subtext',
            },
            {
                label: 'Contestant',
                field: 'LastName',
                displayField: 'FullName',
                type: 'string',
                align: 'left',
                divider: true,
                sortable: true,
                cellClass: ' font-weight-bold type-paragraph'
            }];

            if (event.isRoughStock) {
                _columns.push({
                    label: 'Stock',
                    field: 'StockName',
                    displayField: 'FormattedStock',
                    type: 'string',
                    align: 'left',
                    divider: true,
                    sortable: true,
                    cellClass: ' font-weight-bold type-paragraph'

                })
                _columns.push({
                    label: 'Stock Score',
                    field: 'Stock.Score',
                    displayField: 'FormattedStockScore',
                    type: 'text',
                    align: 'center',
                    divider: true,
                    cellClass: 'font-weight-black text-no-wrap type-subtext'

                })
            }

            if (event.isTimed) {
                _columns.push(
                    {
                        label: 'Time',
                        field: 'Time',
                        displayField: 'FormattedTime',
                        type: 'number',
                        align: 'center',
                        divider: true,
                        sortable: true,
                        cellClass: ' font-weight-black text-no-wrap type-subtext'
                    }
                );

            } else {
                _columns.push(
                    {
                        label: 'Score',
                        field: 'Score',
                        displayField: 'FormattedScore',
                        type: 'number',
                        align: 'center',
                        divider: true,
                        sortable: true,
                        cellClass: 'font-weight-black text-no-wrap type-subtext'
                    });
            }

            _columns.push(
                {
                    label: 'Payoff',
                    field: 'Payoff',
                    displayField: 'FormattedPayoff',
                    type: 'text',
                    align: 'center',
                    cellClass: 'secondary--text text-no-wrap font-weight-black type-subtext'
                });

            return _columns;
        },
        formatRows(rows, event = this.selectedEventType) {
            let _rawRows = rows; // Used by all events
            let _rowsByTeamIdThenByGoRound = {}; // Used by TR

            if (event.EventAbbrev === "TR") {
                // We want a row for each GoRound that a team participates in. (because _rows is mapped with that expectation)
                /*
                    To accomplish that we do..
                    (1) For each team:
                        (2) For each GoRound:
                            (3) Add one of the RodeoResult objects from the team to _rows

                    Later on we will need to tunnel down to the full list of Rodeo Results, so we store them in a..
                    Dictionary<TeamId, Dictionary<GoRound, List<RodeoResult>>> === _rowsByTeamIdThenByGoRound
                */
                // (1) Seperate the Rodeo Results into groups by TeamId
                let _rowsByTeamId = groupBy(rows, 'TeamId');

                // (2) Seperate those groups further by GoRound
                Object.values(_rowsByTeamId).forEach(rowByTeamId => {
                    _rowsByTeamIdThenByGoRound[rowByTeamId[0].TeamId] = groupBy(rowByTeamId, 'GoRound');
                });

                // (3) Take the first Rodeo Result that will be indicative of the team/round for its row and add it to _rawRows.
                _rawRows = [];
                Object.values(_rowsByTeamIdThenByGoRound).forEach(rodeoResultsByGoRound => {
                    Object.values(rodeoResultsByGoRound).forEach(goRoundRodeoResults => {
                        _rawRows.push(goRoundRodeoResults[0]);
                    });
                });
            }

            let _rows = map(_rawRows, (row) => {

                if (event.EventAbbrev === "TR") {
                    // Grab all of the teammates for this GoRound and put them on the same row.
                    let rodeoResultsForTeamIdAndGoRound = _rowsByTeamIdThenByGoRound[row.TeamId][row.GoRound];
                    let rodeoResultsForTeamIdAndGoRoundDict = {}; // A dict facilitates the removal of duplicates.
                    rodeoResultsForTeamIdAndGoRound.forEach(r => rodeoResultsForTeamIdAndGoRoundDict[r.Contestant[0].ContestantId] = r);

                    row.FullName = '';

                    Object.values(rodeoResultsForTeamIdAndGoRoundDict).forEach(teammate => {
                        let _contestant = "";

                        if (teammate.Contestant) {
                            _contestant = map_athletes(teammate.Contestant[0]);
                        }

                        let _teammateName = _contestant
                            ? _contestant.FullName()
                            : '';

                        if (row.FullName.length && _teammateName.length) {
                            row.FullName += ' / ';
                        }

                        if (_teammateName.length) {
                            row.FullName += `<span class="text-no-wrap"><a class="text-link" href="${_contestant.BioUrl()}">${_teammateName}</a></span>`;
                        }
                        row.IsHighligthed = this.isAthleteFavorite(_contestant.ContestantId) || row.IsHighligthed;
                    });

                } else {
                    let _contestant = '';
                    if (row.Contestant != null && row.Contestant.length) {
                        _contestant = map_athletes(row.Contestant[0]);
                    }
                    row.FullName = _contestant ? `<a class="text-link" href="${_contestant.BioUrl()}">${_contestant.FullName()}</a>` : '';
                    row.IsHighligthed = this.isAthleteFavorite(_contestant.ContestantId);
                }

                if (event.isRoughStock) {
                    let _stock = '';
                    if (row.Stock && row.Stock.length) {
                        _stock = map_athletes(row.Stock[0]);
                    }

                    if (row.StockName) {
                        row.FormattedStock = _stock
                            ? `<div class="text-no-wrap"><a class="text-link" href="${_stock.BioUrl()}">${row.StockName}</a></div>`
                            : `<div class="text-no-wrap">${row.StockName}</div>`;
                    }
                    else if (_stock && _stock.FullName()) {
                        row.FormattedStock = `<div class="text-no-wrap"><a class="text-link" href="${_stock.BioUrl()}">${_stock.FullName()}</a></div>`;
                    }
                    else {
                        row.FormattedStock = '<div>--</div>';
                    }

                    if (row.ContractorName) {
                        row.FormattedStock += `<span class="text-no-wrap type-subtext sa-safe-text--text"><span class="font-weight-bold secondary--text">${row.ContractorName}</span></span>`;
                    }
                    else if (_stock && _stock.Contractor && _stock.Contractor.Name) {
                        row.FormattedStock += `<span class="text-no-wrap type-subtext sa-safe-text--text"><span class="font-weight-bold secondary--text">${_stock.Contractor.Name}</span></span>`;
                    }

                    if (row.LeftStockScore && row.RightStockScore) {
                        row.FormattedStockScore = `${row.LeftStockScore} / ${row.RightStockScore}`;
                    }
                }

                row.FormattedTime = '' + row.Time.toFixed(event.timeDecimalPlaces) + ' s';
                row.FormattedScore = row.Score;

                if (row.NumberScores && row.NumberScores !== null) {
                    row.FormattedTime += ' / ' + row.NumberScores;
                    row.FormattedScore += ' / ' + row.NumberScores;
                }

                row.LastName = row.Contestant ? row.Contestant[0].LastName : '';
                row.SortPlace = row.Place ? row.Place : 9999;
                row.DisplayPlace = row.Place && row.Place != 9999 ? row.Place : '--';
                row.FormattedPayoff = row.Payoff ? this.$options.filters.currency(row.Payoff) : '--';
                return row;
            });

            var sorted_values = [];
            if (event.isTimed) {
                sorted_values = sortBy(_rows, ['SortPlace'], (r) => { return r.Time ? r.Time : 9999 });
                sorted_values = this.orderRows(sorted_values)
            } else {
                sorted_values = sortBy(_rows, ['SortPlace', (r) => { return 9999 - r.Score; }]);
                sorted_values = this.orderRows(sorted_values)
            }

            if (this.filterDateRange) {
                sorted_values = sorted_values
                    .filter(row => new Date(row.RideTimestamp) >= new Date(this.filterDateRange[0])
                        && new Date(row.RideTimestamp) <= new Date(this.filterDateRange[1]))
            }

            return sorted_values;
        },
        isAthleteFavorite(contestantId) {
            if (this.user && this.user.settings && this.user.settings.followed_contestants) {
                return this.user.settings.followed_contestants.includes(contestantId);
            }
            return false;
        },
        orderRows(arry) {
            if (arry.some(x => x.GoRoundLabel == 'Avg')) {
                return orderBy(arry, ['SortPlace', 'NumberScores', 'Time'], ['asc', 'desc', 'asc'])
            }
            return arry;
        },
        // END methods code stripped from ResultsRodeo.

        getRodeoResults(rodeoId) {
            const request_options = {
                rodeoid: rodeoId
            }

            return get("/services/pro_rodeo.ashx/results", request_options)
                .then(response => {
                    if (response && response.length) {
                        this.rodeoResultsInternal = response[0];
                    }
                })
        }
    },

    async created() {
        // Fetch the rodeoResults if necessary
        if (this.rodeoResults) this.rodeoResultsInternal = this.rodeoResults
        else if (this.rodeoId) await this.getRodeoResults(this.rodeoId)
        else console.log("ElementResultGridView requires one of either rodeo-results or rodeo-id")

        if (this.useQueryParams) {
            const query = this.$route.query

            const matchingEventType = this.eventTypes.find(type => type.EventTypeId == query.eventTypeId)
            if (matchingEventType != undefined)
                this.selectedEventType = matchingEventType

            this.showMoneyWinners = query.showMoneyWinners == 'true'
        }

        if (this.selectedEventType == null)
            this.selectedEventType = this.eventTypes[0]
    },

    watch: {
        // selectedEventType(newSelectedEventType) {
        //     console.log("newSelectedEventType", newSelectedEventType)
        //     //this.setDefaultDateRange()
        // }
        selectedEventType(newValue, oldValue) {
            // console.log("selectedEventType", newValue)
            if (!this.useQueryParams) return

            /* Update the route, if it hasn't already been updated. */
            const query = JSON.parse(JSON.stringify(this.$route.query))
            if (query.eventTypeId != newValue.EventTypeId) {
                query.eventTypeId = newValue.EventTypeId

                // Push if selecting a value, replace if setting value for first time.
                if (oldValue == undefined)
                    this.$router.replace({ query })
                else
                    this.$router.push({ query })
            }
        },
        queryEventTypeId(newValue) {
            // console.log("queryEventTypeId", newValue)
            if (!this.useQueryParams) return

            /* Update the data, if it hasn't already been updated. */
            if (newValue == undefined && this.selectedEventType != null)
                this.selectedEventType = null
            else if (this.selectedEventType.EventTypeId != newValue) {
                const matchingEventType = this.eventTypes.find(type => type.EventTypeId == newValue)
                if (matchingEventType != undefined)
                    this.selectedEventType = matchingEventType
            }
        },
        showMoneyWinners(newValue) {
            // console.log("showMoneyWinners", newValue)
            if (!this.useQueryParams) return

            /* Update the route, if it hasn't already been updated. */
            const query = JSON.parse(JSON.stringify(this.$route.query))
            if (query.showMoneyWinners == 'true') query.showMoneyWinners = true
            else if (query.showMoneyWinners == 'false') query.showMoneyWinners = false

            if (query.showMoneyWinners != newValue) {
                query.showMoneyWinners = newValue
                this.$router.push({ query })
            }
        },
        queryShowMoneyWinners(newValue) {
            // console.log("queryShowMoneyWinners", newValue)
            if (!this.useQueryParams) return

            /* Update the data, if it hasn't already been updated. */
            if (newValue == 'true') newValue = true
            else if (newValue == 'false') newValue = false

            if (this.showMoneyWinners != newValue) {
                this.showMoneyWinners = newValue
            }
        }
    }
}
</script>

<style>

</style>