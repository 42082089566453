<template>
<section>
    <h2 class="d-sr-only">Featured Stories</h2>
    <div class="sa-block-stories sa-block-stories--carousel">
        <div v-if="stories && stories.length">
            <tiny-slider ref="tinySlider" :mouse-drag="true" :loop="true" :autoplay="true" :autoplay-text="['<span>play</span>','<span>pause</span>']"  :autoplay-button-output="true" nav-position="bottom" :nav="false" :controls="true"  controls-position="bottom" items="1" slide-by="1" gutter="0">
                <div class="story" v-for="story in stories" :key="story.id">
                    <div class="story__media">
                        <v-img
                        v-if="story.image_source"
                        :src="$root.imageBasePath + story.image_source + '?width=768&height=432&mode=crop&format=jpg&quality=80'"
                        :srcset="$root.imageBasePath + story.image_source + '?width=540&height=304&mode=crop&format=jpg&quality=80 540w,' + $root.imageBasePath + story.image_source + '?width=768&height=432&mode=crop&format=jpg&quality=80 768w,' + $root.imageBasePath + story.image_source + '?width=896&height=504&mode=crop&format=jpg&quality=80 896w,' + $root.imageBasePath + story.image_source + '?width=960&height=540&mode=crop&format=jpg&quality=80 960w'"
                        sizes="(max-width:959px) 100vw, 896px"
                        :aspect-ratio="16/9"
                        :alt="story.image_alt_text"></v-img>
                        <v-img v-else :aspect-ratio="16/9" src="@/assets/images/image_story_default.png" ></v-img>
                    </div>
                    <div class="story__details px-8 px-lg-0 pt-12">
                        <h3 class="story__title type-hero mt-4 mb-2">
                            <component :is="story.redirect_absolute_url ? 'a' : 'router-link'" class="story__link text-link text-uppercase" v-bind="story.redirect_absolute_url ? {href: story.redirect_absolute_url} : {to: story.url}" >{{story.title}}</component>
                        </h3>
                        <common-byline :story="story"/>
                    </div>
                </div>
            </tiny-slider>
        </div>
	</div>
</section>
</template>

<script>
import CommonByline from "@/components/common/CommonByline";

export default {
    name: "BlockStoriesCarousel",
    props:{
        stories:{
            type:Array,
            default:() => []
        },
    },
    components:{
        CommonByline
    },
    data: () => ({
        isPlaying:true,
        activeSlide: 0,
    }),
    methods:{
        togglePause(){
            this.$refs.tinySlider.slider.pause();
        }
    }
};
</script>

<style lang="scss">
.sa-block-stories--carousel{
    outline: none;
    position: relative;
    background:black;
    .tns-ovh{
        background:$color-sa-background;
    }
    .tns-visually-hidden{
            background:white !important;
            color:black !important;
        }
    .tns-controls{
        position:absolute;
        outline: none;
        top:0;
        left:0;
        width:100%;
        margin-top:56.25%;
        pointer-events: none;
        //transform:translateY(-50px);
        //border-top:solid 1px #414141;
        button[data-controls="prev"],button[data-controls="next"]{
            outline: none;
            height:49px;
            pointer-events: all;
            width:33.3333%;
            background:$color-primary;
            transition:background-color 0.2s;
            box-shadow:none;
            border-right:solid 1px $color-border-dark;
            &:hover, &:focus{
                background:$color-secondary;
            }
        }
        button[data-controls="next"]{
            outline: none;
            position:absolute;
            right:0;
            border-right:none;
            border-left:solid 1px $color-border-dark;
        }
    }

        button[data-action="start"],button[data-action="stop"]
        {
            @include base-reset();
            outline: none;
            position:absolute;
            z-index:4;
            top:0;
            left:33.333%;
            margin-top:56.25%;
            width:33.3333%;
            height:49px;
            color:white;
            //transform:translateY(-50px);
            transition:background-color 0.2s;
            background-color:$color-primary;
            text-transform:uppercase;
                font-weight:900;
                font-size:0.75rem;

            & > span{
                display:flex;
                align-items:center;
                justify-content:center;
                &::before{
                    padding-right:4px;
                    font-size:1rem;
                    font-family:"sidearm_font_v2";
                    content:'\e200';
                }
            }
            &:hover, &:focus{
                background:$color-secondary;
            }
          &:hover, &:focus, &:active, &:focus-visible {
              outline: none;
          }
        }
        button[data-action="start"]{
            & > span{
                &::before{
                    content:'\e050';
                }
            }
        }

}
</style>

<style lang="scss" scoped>
.story{
    // fixes images flickering on safari
    -webkit-transform: translateZ(0) !important;
    -moz-transform: translateZ(0) !important;
    &__media{
        position:relative;
        width:100%;
        height:0;
        padding-bottom:56.25%;
        overflow:hidden;
        background:$color-sa-safe-text;
        img{
            position:absolute;
            top:0;
            left:0;
            width:100%;
        }

    }
}
</style>
