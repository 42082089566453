import Vue from "vue";
import App from "./App.vue";
import ElementAdButler from "@/components/elements/ads/ElementAdButler.vue";
import CommonLoader from "@/components/common/CommonLoader"
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMoment from "vue-moment";
import { get } from "@/helpers";
import VueTinySlider from 'vue-tiny-slider';
import keyBy from "lodash/keyBy";
import setMetaSidearm from "@/mixins/setMetaSidearm";

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.use(VueMoment);

Vue.component("ElementAdButler", ElementAdButler);

Vue.component("CommonLoader", CommonLoader);

Vue.component("TinySlider", VueTinySlider);

Vue.filter('currency', function (value, decimalCount = 0, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = value < 0 ? "-" : "";

        let i = parseInt(value = Math.abs(Number(value) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + "$" + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(value - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
});

Vue.mixin(setMetaSidearm);


//Hide console error that should be a vue warn
//Related: https://github.com/vuetifyjs/vuetify/issues/9999
const ignoredMessage = "The .native modifier for v-on is only valid on components but it was used on <svg>.";
Vue.config.warnHandler = (message, vm, componentTrace) => {
    if (message !== ignoredMessage) {
        console.error(message + componentTrace);
    }
};


const applyCustomEventTypeOrder = (arr, desiredOrder) => {
    const orderForIndexVals = desiredOrder.slice(0).reverse();
    arr.sort((a, b) => {
      const aIndex = -orderForIndexVals.indexOf(a.EventAbbrev);
      const bIndex = -orderForIndexVals.indexOf(b.EventAbbrev);
      return aIndex - bIndex;
    });
  }

new Vue({
    data:() => ({
        circuits:null,
        tours:null,
        eventTypesRaw:null,
        // getMonth is 0-indexed, so new Date().getMonth() >= 9 is true when the month is Oct, Nov, or Dec
        // currentYear: new Date().getFullYear() + ((new Date().getMonth() >= 9) ? 1 : 0),
        currentYear: new Date().getFullYear(),
        currentStaticYear: new Date().getFullYear(),
        proRodeoTourId: 14,
        nfrPlayoffSeriesId: 17,
        siteTitle: 'PRCA Sports News',
        bucket: '//s3.amazonaws.com/prorodeo.com',
        serviceBasePath: process.env.VUE_APP_SERVICE_ROOT,
        imageBasePath: process.env.VUE_APP_IMAGE_ROOT,
        defaultEventType:{ EventAbbrev:"BB", EventName:"Bareback Riding", EventTypeId: 1}
    }),
    methods:{
        getTours(){
            get("/services/pro_rodeo.ashx/tours").then(response => {
                this.tours = response;
                this.$root.tours.forEach(tour => {
                    if(tour.Name === 'XBRONCS'){
                        tour.Name = 'X Broncs';
                    }
                });
            });
        },
        getCircuits(){
            get("/services/pro_rodeo.ashx/circuits").then(response => {
                // Hide "N/A" circuit (ID 14)
                // Hide "Mexico (G)" circuit (ID 15)
                // Hide "Brazil (B)" circuit (ID 16)
                this.circuits = response.filter(circuit => 
                    circuit.CircuitId !== 14 && 
                    circuit.CircuitId !== 15 && 
                    circuit.CircuitId !== 16
                );
            });
        },
        getEventTypes(){
            get("/services/pro_rodeo.ashx/eventtypes").then(response => {
                this.eventTypesRaw = response;
            });
        }
    },
    computed:{
        allStandingsYears() {
          // We have standings data going back to 2009.
          let years = [];
          for(let i=this.$root.currentYear; i >= 2009; --i)
            years.push(i)
          
          // Make the next year an option in Oct, Nov, or Dec (after season ends).
          if(new Date().getMonth() >= 9) years = [new Date().getFullYear() + 1, ...years];

          return years;
        },
        recentStandingsYears() {
          let years = [this.$root.currentYear, this.$root.currentYear - 1];

          // If Oct, Nov, Dec (i.e. after season ends).
          if(new Date().getMonth() > 0 && new Date().getMonth() >= 9)
            years = [this.$root.currentYear + 1, ...years];

          return years;
        },
        eventTypes(){
            let _eventTypes = [];
            if(this.eventTypesRaw){
                _eventTypes = JSON.parse(JSON.stringify(this.eventTypesRaw));
                _eventTypes.forEach(eventType => {
                    eventType.isTimed = ["SW","TD","TR","SR","GB","LB"].includes(eventType.EventAbbrev);
                    eventType.timeDecimalPlaces = ["GB"].includes(eventType.EventAbbrev) ? 2 : 1;
                    eventType.isRoughStock = ["BB","SB","BR"].includes(eventType.EventAbbrev);
                    eventType.inStandings = !(["XB", "TR"].includes(eventType.EventAbbrev));
                    eventType.inAthleteDirectory = !(["AA","GB", "XB", "TR","LB"].includes(eventType.EventAbbrev));
                    eventType.isNFR = ["BB","SW","TRHD","TRHL","SB","TD","GB", "BR"].includes(eventType.EventAbbrev);
                    eventType.isContractor = ["BB", "SB", "BR"].includes(eventType.EventAbbrev);
                });

                let customOrder = ["AA", "BB", "SB","BR","SW","TR","TRHD","TRHL","TD","SR","GB","LB"];

                applyCustomEventTypeOrder(_eventTypes, customOrder);


            }
            return _eventTypes;
        }, 
        eventTypesByAbbrev(){
            let _eventTypes = [];
            if(this.eventTypes){
                _eventTypes = JSON.parse(JSON.stringify(this.eventTypes));
                _eventTypes = keyBy(_eventTypes, event => { return event.EventAbbrev});
            }
            return _eventTypes;
        }
    },
    created(){
        this.getTours();
        this.getCircuits();
        this.getEventTypes();
    },
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app");