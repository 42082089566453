import { render, staticRenderFns } from "./BlockSlideshowDynamicContent.vue?vue&type=template&id=7565e218&scoped=true"
import script from "./BlockSlideshowDynamicContent.vue?vue&type=script&lang=js"
export * from "./BlockSlideshowDynamicContent.vue?vue&type=script&lang=js"
import style0 from "./BlockSlideshowDynamicContent.vue?vue&type=style&index=0&id=7565e218&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7565e218",
  null
  
)

export default component.exports