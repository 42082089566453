<template>
  <div class="sa-butler ">

  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
import postscribe from "postscribe";

export default {
    name:'ElementAdButlerContentSponsor',
    props: {
        location: {
            type: String,
            default: ""
        }
    },
    data: () => ({}),
    mounted: function() {
        postscribe(this.$el, '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script>\r\n<script type=\"text\/javascript\">\r\nvar AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];\r\nvar abkw = window.abkw || \'\';\r\nvar plc454198 = window.plc454198 || 0;\r\ndocument.write(\'<\'+\'div id=\"placement454198\'+plc454198+\'\"><\/\'+\'div>\');\r\nAdButler.ads.push({handler: function(opt){ AdButler.register(175684, 454198, [150,50], \'placement454198\'+opt.place, opt); }, opt: { place: plc454198++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});\r\n<\/script>');
    }
};
</script>
