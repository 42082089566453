<template>
  <aside class="sa-block-standings-limited-list">
    <div class="pb-8">
      <common-title
        class=" mr-auto mb-4"
        :top="tourName ? tourName : circuit ? 'Circuit' : 'Contestant'"
        bottom="Standings"
        type="h2"
      />
      <div
        v-if="!tourId && !circuit"
        class="sa-block-standings__buttons d-flex align-center  w-100  mb-4 "
      >
        <v-btn
          height="40"
          class="w-50 flex-shrink-1"
          @click="switchTab('world')"
          :color="isWorldSelected ? 'secondary' : 'primary'"
          rounded
          >World</v-btn
        >
        <v-btn
          height="40"
          class="ml-2 w-50 flex-shrink-1"
          @click="switchTab('nfrplayoffseries')"
          :color="isNfrPlayoffSeriesSelected ? 'secondary' : 'primary'"
          rounded
          >PRCA Playoff Series</v-btn
        >
      </div>
      <v-select
        v-if="circuit"
        class=" sa-block-standings__select pt-0 pb-4 mt-0 w-100 sa-drop-shadow"
        :items="circuits"
        hide-details
        aria-label="Select a Tour"
        @change="getStandings"
        v-model="selectedCircuit"
        return-object
      ></v-select>
      <v-select
        v-if="hasEventTypes"
        class=" sa-block-standings__select pt-0 mt-0 w-100 sa-drop-shadow"
        :items="events"
        :item-text="'EventName'"
        :item-value="'EventAbbrev'"
        hide-details
        aria-label="Select an Event"
        @change="getStandings"
        v-model="selectedEvent"
      ></v-select>
      <v-select
        class=" sa-block-standings__select mt-4 pt-0 mt-0 w-100 sa-drop-shadow"
        :items="years"
        hide-details
        aria-label="Select a Year"
        @change="getStandings"
        v-model="selectedYear"
      ></v-select>
    </div>
    <transition name="fade" mode="out-in">
      <div :key="'has-items'" v-if="!loadingContestants">
        <div v-if="mobileContestants.length">
          <div
            class="standing mb-4 d-flex align-center"
            v-for="athlete in mobileContestants"
            :key="athlete.StandingId"
          >
            <div class="standing__avatar mr-4">
              <common-avatar-sidearm
                max-width="60"
                :src="athlete.SidearmPhotoUrl"
                :alt="athlete.FullName()"
                type="contestant"
                tile
              />
            </div>
            <div>
              <div class="d-flex align-center mb-2">
                <div
                  class="standing__rank standing__rank--widget d-flex align-center justify-center secondary white--text font-weight-black mr-2"
                >
                  {{ athlete.Place }}
                </div>
                <div
                  class="standing__name primary--text font-weight-bold type-hero--sm"
                >
                  <router-link class="text-link" :to="athlete.BioLink()">{{
                    athlete.FullName()
                  }}</router-link>
                </div>
              </div>
              <div class="standing__details type-subtext sa-safe-text--text">
                <span class="secondary--text font-weight-bold">{{
                  showEarningsOrPoints(athlete)
                }}</span>
                <span v-if="athlete.Hometown" class="d-inline-block mx-2"
                  >&bull;</span
                >
                <span v-if="athlete.Hometown">{{ athlete.Hometown }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          Standings not available for this event.
        </div>
      </div>
      <div v-else :key="'no-items'">
        <div
          class="sa-block-standings__loading pa-6 d-flex align-center justify-center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </div>
    </transition>
  </aside>
</template>

<script>
import { get, map_athletes } from "@/helpers";
import find from "lodash/find";
import CommonTitle from "@/components/common/CommonTitle";
import CommonAvatarSidearm from "@/components/common/CommonAvatarSidearm";
import remove from "lodash/remove";

export default {
  name: "BlockStandingsLimitedList",
  props: {
    tourId: {
      type: [String, Number],
      default: null,
    },
    circuit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CommonTitle,
    CommonAvatarSidearm,
  },
  data: () => ({
    selectedEvent: "AA",
    selectedTab: "world",
    selectedCircuit: null,
    contestants: [],
    loadingContestants: true,
    selectedYear: null,
  }),
  computed: {
    isWorldSelected() {
      return this.selectedTab == "world";
    },
    isProRodeoSelected() {
      return this.selectedTab == "prorodeo";
    },
    isNfrPlayoffSeriesSelected() {
      return this.selectedTab == "nfrplayoffseries";
    },
    events() {
      let _events = this.$root.eventTypes
        ? JSON.parse(JSON.stringify(this.$root.eventTypes))
        : [];

      remove(_events, (e) => {
        return !e.inStandings || ["GB", "LB"].includes(e.EventAbbrev);
      });

      return _events;
    },
    years() {
      return this.$root.recentStandingsYears;
    },
    hasEventTypes() {
      // TODO: figure in nfr playoff series??
      return !this.tourId || this.tourId === this.$root.proRodeoTourId;
    },
    mobileContestants() {
      return this.contestants.slice(0, 3);
    },
    tourName() {
      let _name = "";

      if (this.$root.tours && this.tourId) {
        let _tour = find(this.$root.tours, ["TourId", parseInt(this.tourId)]);
        if (_tour) {
          _name = _tour.Name;
        }
      }
      return _name;
    },
    circuits() {
      let _circuits = [];
      if (this.$root.circuits && this.$root.circuits.length) {
        this.$root.circuits.forEach((circuit) => {
            _circuits.push({ text: circuit.Name, value: circuit.CircuitId });
        });
      }
      return _circuits;
    },
  },
  methods: {
    switchTab(tab) {
      this.selectedTab = tab;
      this.getStandings(tab);
    },
    getStandings() {
      if (this.tourId) {
        this.getStandingsByTourId();
      } else {
        this.loadingContestants = true;
        let request_options = {};
        if (!this.circuit) {
          switch (this.selectedTab) {
            case "world":
              request_options = {
                year: this.selectedYear
                  ? this.selectedYear
                  : this.$root.currentYear,
                type: "world",
                event: this.selectedEvent,
              };
              break;

            case "prorodeo":
              request_options = {
                year: this.selectedYear
                  ? this.selectedYear
                  : this.$root.currentYear,
                type: "tour",
                id: this.$root.proRodeoTourId,
                event: this.selectedEvent,
              };
              break;
            case "nfrplayoffseries":
              request_options = {
                year: this.selectedYear
                  ? this.selectedYear
                  : this.$root.currentYear,
                type: "tour",
                id: this.$root.nfrPlayoffSeriesId,
                event: this.selectedEvent,
              };
              break;
            default:
              request_options = {
                year: this.selectedYear
                  ? this.selectedYear
                  : this.$root.currentYear,
                type: "world",
                event: this.selectedEvent,
              };
          }
        } else {
          request_options = {
            year: this.selectedYear
              ? this.selectedYear
              : this.$root.currentYear,
            type: "circuit",
            id: this.selectedCircuit ? this.selectedCircuit.value : "",
            event: this.selectedEvent,
          };
        }

        get("/services/pro_rodeo.ashx/standings", request_options).then(
          (response) => {
            this.contestants = map_athletes(response);
            this.loadingContestants = false;
          }
        );
      }
    },
    getStandingsByTourId() {
      this.loadingContestants = true;

      let _event = this.selectedEvent;

      if (!this.hasEventTypes) {
        _event = "AA";
      }

      let request_options = {
        year: this.selectedYear ? this.selectedYear : this.$root.currentYear,
        type: "tour",
        id: this.tourId,
        event: _event,
      };
      get("/services/pro_rodeo.ashx/standings", request_options).then(
        (response) => {
          this.contestants = map_athletes(response);
          this.loadingContestants = false;
        }
      );
    },
    showEarningsOrPoints(athlete) {
      if (
        athlete.TourId === this.$root.proRodeoTourId ||
        athlete.TourId === this.$root.nfrPlayoffSeriesId
      ) {
        return athlete.Points + " pts";
      } else {
        return this.$options.filters.currency(athlete.Earnings, 2);
      }
    },
  },
  created() {
    this.selectedYear = this.$root.currentYear;
    if (this.tourId) {
      this.getStandingsByTourId();
    } else {
      this.selectedCircuit = this.circuits[0];
      this.getStandings();
    }
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.sa-block-standings-limited-list {
  .sa-block-standings {
    &__select {
      max-width: none;
    }
  }

  .standing {
    &__avatar {
      position: relative;
    }
    &__rank {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      font-size: 10px;
    }
  }
}

.sa-block-standings-limited {
  .standing {
    &__avatar {
      padding-bottom: 12px;
    }
  }
}
</style>
