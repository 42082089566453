<template>
    <div class="sa-block-slideshow sa-block-slideshow--promo-slider">
        <div class="sa-block-slideshow__inner">
           <tiny-slider ref="carousel" v-if="slideshowItems.length" v-bind="tinySlideOptions" class="sa-block-slideshow__items">
                <div v-for="item in slideshowItems" :key="item.id" class="sa-block-slideshow__item" >
                    <a v-if="item.primary_link != '' && item.primary_link != '#'" :href="item.primary_link" :aria-label="typeof item.primary_image.images[0] != 'undefined' ? item.primary_image.images[0].alt : item.title" rel="noopener" target="_blank">
                        <common-picture :images="item.primary_image.images"/>
                    </a>
                    <span v-if="item.primary_link == '' || item.primary_ink == '#'">
                        <common-picture :images="item.primary_image.images"/>
                    </span>
                </div>
            </tiny-slider>
        </div>
	</div>
</template>

<script>
import { get } from "@/helpers";
import CommonPicture from "@/components/common/CommonPicture";

export default {
    name:"BlockSlideshowPromo",
    components:{
        CommonPicture
    },
    data: () => ({
        isPlayiing:false,
        slideshowItems:[],
        tinySlideOptions: {
                            items: 1,
                            slideBy: 1,
                            nav:false,
                            controls:true,
                            autoplayText: ['<span>play</span>','<span>pause</span>'],
                            gutter:0,
                            autoplayHoverPause:false,
                            controlsPosition:"bottom",
                            navPosition:"bottom",
                            autoplaySpeed:5000,
                            autoplay:true
                        }
    }),
    methods: {
        togglePause(){
          this.$refs.carousel.toggleAutoPlay();
          this.isPlaying = !this.isPlaying;
        },
        getSlideshow() {
            const request_options = {
                type: "slideshow",
                sport_id: 0,
                extra:JSON.stringify({slideshow_id:4}),
                count:5
            };

            get("/services/adaptive_components.ashx", request_options).then(slideshow => {
                if(slideshow && slideshow.length){
                    this.slideshowItems = slideshow;
                }
            });
        }
    },
    created() {
        this.getSlideshow();
    }
};
</script>

<style lang="scss">
.sa-block-slideshow--promo-slider{
    .sa-block-slideshow__inner{
    background:$color-white;
    position: relative;
    max-width:896px;
    margin: 0 auto;
    }
    .sa-block-slideshow__item img{ 
        display:inline-block;
        max-width:100%;
        vertical-align: bottom;
    }
    .tns-controls{
        button[data-controls="prev"],button[data-controls="next"]{
            width:33.3333%;
            background:$color-white;
            height:50px;
            color:$color-primary;
            border-right:solid 1px $color-border-light;
            transition:background-color 0.2s;
            box-shadow:none;
            &::before{
                color:$color-primary;
            }
            &:hover, &:focus{
                background:$color-secondary;
            }
        }
        button[data-controls="next"]{
            position:absolute;
            right:0;
            border-right:none;
            border-left:solid 1px $color-border-light;
        }
        
    }
    button[data-action="start"],button[data-action="stop"]
        {
            @include base-reset();
            position:absolute;
            z-index:4;
            bottom:0;
            left:50%;
            transform:translateX(-50%);
            width:33.3333%;
            height:50px;
            color:$color-primary;
            transition:background-color 0.2s;
            text-transform:uppercase;
            font-weight:900;
            font-size:0.75rem;

            & > span{
                display:flex;
                justify-content:center;
                align-items:center;
                &::before{
                    padding-right:4px;
                    font-family:"sidearm_font_v2";
                    content:'\e200';
                    font-size:1rem;
                    line-height:0.75;
                }
            }
            &:hover, &:focus{
                background:$color-secondary;
                color:white;
            }
        }
        button[data-action="start"]{
            & > span{
                &::before{
                    content:'\e050';
                }
            }
        }
}
</style>
