<template>
  <div class="ad-butler-ad" >
    <component
      v-if="type"
      :is="type"
      v-bind="$props"
    />
  </div>
</template>

<script>
import ElementAdButlerSquare from "./ElementAdButlerSquare.vue";
import ElementAdButlerLeaderboard from "./ElementAdButlerLeaderboard.vue";
import ElementAdButlerLeaderboardMobile from "./ElementAdButlerLeaderboardMobile.vue";
import ElementAdButlerContentSponsor from "./ElementAdButlerContentSponsor.vue";
import ElementAdButlerContentSponsorMobile from "./ElementAdButlerContentSponsorMobile.vue";
import ElementAdButlerVertical from "./ElementAdButlerVertical.vue";

export default {
    name:'ElementAdButler',
    props: {
        type: {
            type: String,
            required: true,
            default: "square"
        },
        location: {
            type: String,
            default: ""
        }
    },
    components: {
        leaderboard: ElementAdButlerLeaderboard,
        square: ElementAdButlerSquare,
        leaderboardMobile: ElementAdButlerLeaderboardMobile,
        contentSponsor: ElementAdButlerContentSponsor,
        contentSponsorMobile: ElementAdButlerContentSponsorMobile,
        vertical: ElementAdButlerVertical
    },
    data: () => ({}),
};
</script>

<style lang="scss">
.ad-butler-ad{
    img{
        vertical-align: bottom;
    }
}
</style>
