<template>
    <picture>
        <source v-for="image in imageSet" :key="image.breakpoint" :media="'(min-width:'+ image.breakpoint +'px)'" :srcset="$root.imageBasePath + image.image">
        <img :src="$root.imageBasePath + imageSet[0].image" :alt="imageSet[0].alt"/>
    </picture>
</template>

<script>

import orderBy from 'lodash/orderBy';

export default {
  name: "CommonPicture",
  props:{
      images:{
        type:Array,
        required:true,
        default:()=>{}
      }
  },
  data: () => ({}),
  computed:{
      imageSet(){
          if(this.images && this.images.length){
              return orderBy(this.images, 'breakpoint', 'desc');
          }else{
              return [];
          }
      }
  }
};
</script>

