<template>
     <v-avatar class="sa-common-avatar" v-bind="$attrs"  width="100%" height="auto">
      <img class="sa-common-avatar__image" :width="$attrs['max-width']  ? $attrs['max-width'] : ''" :height="$attrs['max-width']  ? $attrs['max-width'] : ''" :src="properties.src" :alt="properties.alt"/>
    </v-avatar>
</template>

<script>


export default {
  name: "CommonAvatarSidearm",
  props:{
      type:{
        type:String,
        default:'contestant'
      },
      src:{
          type:String,
          default:''
      },
      alt:{
          type:String,
          default:''
      }
  },
  data: () => ({}),
  computed:{
      properties(){
          let _properties = {};
          if(this.src){
            if(this.$attrs['max-width']){

                //strip the bucket from the url so we can use resizer
                let _src = this.src;

                let _index = _src.indexOf(this.$root.bucket);

                if(_index > -1){
                    _src = _src.substring(_index + this.$root.bucket.length);
                }

                _properties['src'] = this.$root.imageBasePath + _src + '?width=' + this.$attrs['max-width'] + '&height=' + this.$attrs['max-width'] + '&mode=crop&scale=both&anchor=topcenter';
            }else{
                _properties['src'] = this.$root.imageBasePath + this.src;
            }
          }else{
              switch(this.type){
                  case 'contestant':
                      _properties['src'] = require('@/assets/images/image_avatar_contestant.svg');
                    break;
                case 'rodeo':
                    _properties['src'] = require('@/assets/images/image_avatar_rodeo.svg');
                    break;
                 case 'stock':
                     _properties['src'] = require('@/assets/images/image_avatar_stock.svg');
                    break;
                default:
                    _properties['src'] = require('@/assets/images/image_avatar_rodeo.svg');
                    break;
            }
          }
        

          _properties.alt=this.alt;

          return _properties;

      }
  }

};
</script>

<style lang="scss" scoped>
.sa-common-avatar{
    &__image{
        max-width:100%;
    }
}
</style>
