import Vue from "vue";
import Vuetify from "vuetify/lib";
import { mdiChevronDown, mdiFacebook, mdiEmail, mdiEmailOutline, mdiTwitter,mdiPrinter,mdiTag, mdiPound } from '@mdi/js'
import { AwardIcon, ArrowRightIcon, ArrowLeftIcon, CameraIcon, SearchIcon, XIcon, MenuIcon, Share2Icon, UserIcon, LockIcon, FilterIcon, PlusIcon, YoutubeIcon, ShoppingCartIcon, PlayIcon, PlayCircleIcon, CheckIcon, CheckCircleIcon, TrashIcon, StarIcon, HomeIcon } from 'vue-feather-icons'

Vue.use(Vuetify);

import { VBtn } from "vuetify/lib";
import { VSelect } from "vuetify/lib";

VBtn.options.props.depressed.default = true;


VSelect.options.props.flat.default = true;
VSelect.options.props.rounded.default = true;
VSelect.options.props.backgroundColor.default = '#ffffff';
VSelect.options.props.appendIcon.default = '$mdiChevronDown';
VSelect.options.props.height.default = 40;

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#002554",
        secondary: "#CE2030",
        accent: "#BC945C",
        'sa-background': "#EDEDED",
        'sa-safe-text': '#666666',
        'sa-sate-text-light': '#707070'
      }
    }
  },
  breakpoint:{
      thresholds:{
          xs:375,
          sm:768,
          md:976,
          lg:1280
      }
  },
  icons:{
      iconfont:'mdiSvg',
      values:{
        mdiChevronDown:mdiChevronDown,
        mdiFacebook:mdiFacebook,
        mdiEmail:mdiEmail,
        mdiEmailOutline:mdiEmailOutline,
        mdiTwitter:mdiTwitter,
        mdiPrinter:mdiPrinter,
        mdiTag:mdiTag,
        mdiPound:mdiPound,
        arrowRight:{
            component:ArrowRightIcon
        },
        arrowLeft:{
            component:ArrowLeftIcon
        },
        award:{
            component:AwardIcon
        },
        camera:{
            component:CameraIcon
        },
        cart:{
            component:ShoppingCartIcon
        },
        close:{
            component:XIcon
        },
        filter:{
            component:FilterIcon
        },
        menu:{
            component:MenuIcon
        },
        password:{
            component:LockIcon
        },
        plus:{
            component:PlusIcon
        },
        search:{
            component:SearchIcon
        },
        share:{
            component:Share2Icon
        },
        user:{
            component:UserIcon
        },
        youtube:{
            component:YoutubeIcon
        },
        play:{
            component: PlayIcon
        },
        playCircle:{
            component:PlayCircleIcon
        },
        check: {
            component: CheckIcon
        },
        checkCircle: {
            component: CheckCircleIcon
        },
        trash: {
            component: TrashIcon
        },
        star: {
            component: StarIcon
        },
        home: {
            component: HomeIcon
        }
      }
  }
});
