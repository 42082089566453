<template>
  <div class="sa-butler d-flex align-center">

  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
import postscribe from "postscribe";

export default {
    name:'ElementAdButlerLeaderboard',
    props: {
        location: {
            type: String,
            default: ""
        }
    },
    data: () => ({}),
    watch: {
        showXtremeBullsAd() {
            // Switch out the ad.
            this.$el.innerHTML = "";
            postscribe(this.$el, this.adScript);
        },
        showPlayoffSeriesAd() {
            // Switch out the ad.
            this.$el.innerHTML = "";
            postscribe(this.$el, this.adScript);
        }
    },
    computed: {
        showXtremeBullsAd() {
            // Xtreme Bulls https://sidearm.atlassian.net/browse/PRODEO-158
            return this.location == "standings" && this.$route.query.id == 4;
        },
        showPlayoffSeriesAd() {
            // Playoff Series https://sidearm.atlassian.net/browse/PRODEO-163
            return this.location == "standings" && this.$route.query.id == 17;
        },
        adScript() {
            let adScript = "";
            switch(this.location){
                case "standings":
                    if(this.showXtremeBullsAd) adScript = '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script><script type=\"text\/javascript\">var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];var abkw = window.abkw || \'\';var plc596356 = window.plc596356 || 0;document.write(\'<\'+\'div id=\"placement_596356_\'+plc596356+\'\"><\/\'+\'div>\');AdButler.ads.push({handler: function(opt){ AdButler.register(175684, 596356, [728,90], \'placement_596356_\'+opt.place, opt); }, opt: { place: plc596356++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});<\/script>'
                    else if(this.showPlayoffSeriesAd) adScript = '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script><script type=\"text\/javascript\">var AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];var abkw = window.abkw || \'\';var plc606913 = window.plc606913 || 0;document.write(\'<\'+\'div id=\"placement_606913_\'+plc606913+\'\"><\/\'+\'div>\');AdButler.ads.push({handler: function(opt){ AdButler.register(175684, 606913, [728,90], \'placement_606913_\'+opt.place, opt); }, opt: { place: plc606913++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});<\/script>';
                    else adScript = '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script>\r\n<script type=\"text\/javascript\">\r\nvar AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];\r\nvar abkw = window.abkw || \'\';\r\nvar plc353891 = window.plc353891 || 0;\r\ndocument.write(\'<\'+\'div id=\"placement353891\'+plc353891+\'\"><\/\'+\'div>\');\r\nAdButler.ads.push({handler: function(opt){ AdButler.register(175684, 353891, [728,90], \'placement353891\'+opt.place, opt); }, opt: { place: plc353891++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});\r\n<\/script>';
                    break;
                case "barrel-racing-standings":
                    adScript = '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script>\r\n<script type=\"text\/javascript\">\r\nvar AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];\r\nvar abkw = window.abkw || \'\';\r\nvar plc493419 = window.plc493419 || 0;\r\ndocument.write(\'<\'+\'div id=\"placement493419\'+plc493419+\'\"><\/\'+\'div>\');\r\nAdButler.ads.push({handler: function(opt){ AdButler.register(175684, 493419, [728,90], \'placement493419\'+opt.place, opt); }, opt: { place: plc493419++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});\r\n<\/script>';
                    break;
                case "breakaway-roping-standings":
                    adScript = '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script>\r\n<script type=\"text\/javascript\">\r\nvar AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];\r\nvar abkw = window.abkw || \'\';\r\nvar plc501264 = window.plc501264 || 0;\r\ndocument.write(\'<\'+\'div id=\"placement501264\'+plc501264+\'\"><\/\'+\'div>\');\r\nAdButler.ads.push({handler: function(opt){ AdButler.register(175684, 501264, [728,90], \'placement501264\'+opt.place, opt); }, opt: { place: plc501264++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});\r\n<\/script>';
                    break;
                default:
                    adScript = '<script type=\"text\/javascript\">if (!window.AdButler){(function(){var s = document.createElement(\"script\"); s.async = true; s.type = \"text\/javascript\";s.src = \'https:\/\/servedbyadbutler.com\/app.js\';var n = document.getElementsByTagName(\"script\")[0]; n.parentNode.insertBefore(s, n);}());}<\/script>\r\n<script type=\"text\/javascript\">\r\nvar AdButler = AdButler || {}; AdButler.ads = AdButler.ads || [];\r\nvar abkw = window.abkw || \'\';\r\nvar plc353875 = window.plc353875 || 0;\r\ndocument.write(\'<\'+\'div id=\"placement353875\'+plc353875+\'\"><\/\'+\'div>\');\r\nAdButler.ads.push({handler: function(opt){ AdButler.register(175684, 353875, [728,90], \'placement353875\'+opt.place, opt); }, opt: { place: plc353875++, keywords: abkw, domain: \'servedbyadbutler.com\', click:\'CLICK_MACRO_PLACEHOLDER\' }});\r\n<\/script>';
            }
            return adScript;
        },
    },
    mounted: function() {
        postscribe(this.$el, this.adScript);
    }
};
</script>
