import { create_url_params_from_object } from './helpers';

const auth_client = (url, config = {}) => {
    let request_config = {
        headers: new Headers(),
        method: 'GET',
    };

    request_config.headers.append('Content-Type', 'application/x-www-form-urlencoded');

    request_config = {
        ...request_config,
        ...config
    }

    return new Request(url, request_config);
}


export function sign_up(email, first_name, last_name, password, accept_marketing ) {
    return new Promise((resolve, reject) => {
        fetch(new auth_client(process.env.VUE_APP_AUTH_ROOT + '/api/auth/sidearm/signup', {
            method: 'POST',
            body: JSON.stringify({email, first_name, last_name, password, accept_marketing}),
        }))
            .then(response => response.json())
            .then(response => {
                if (response && response.error && response.error.messages) {
                    //reject(response.error.messages.jo)n('\n');
                    throw new Error(response.error.messages.join('\n'));
                }
                else if (response.data == null) {
                    throw new Error(JSON.stringify(response));
                }

                if (response.data && response.data.accessToken) {
                    resolve(response.data.accessToken)
                }
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function recover_password(email) {
    return new Promise((resolve, reject) => {
        fetch(new auth_client(process.env.VUE_APP_AUTH_ROOT + '/api/auth/sidearm/forgotPassword' + create_url_params_from_object({email}), {
            method: 'POST',
        }))
            .then(response => response.json())
            .then(response => {
                if (response && response.error && response.error.messages) {
                    reject();
                    throw new Error(response.error.messages.join('\n'));
                }
                else if (response.data == null) {
                    reject();
                    throw new Error(JSON.stringify(response));
                }

                resolve()
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function check_guid(guid){
    return new Promise((resolve, reject) => {
        fetch(new auth_client(process.env.VUE_APP_AUTH_ROOT + '/api/auth/sidearm/checkGuid' + create_url_params_from_object({guid}), {
            method: 'POST'
        }))
            .then(response => response.json())
            .then(response => {
                if (response && response.error && response.error.messages) {
                    reject();
                    throw new Error(response.error.messages.join('\n'));
                }
                else if (response.data == null) {
                    reject();
                    throw new Error(JSON.stringify(response));
                }

                resolve(response.data.result)
            })
            .catch(error => {
                reject(error);
            })
    })
}

export function update_password(email, password){
    return new Promise((resolve, reject) => {
        fetch(new auth_client(process.env.VUE_APP_AUTH_ROOT + '/api/auth/sidearm/updatePassword', {
            method: 'POST',
            body: JSON.stringify({email, password})
        }))
            .then(response => response.json())
            .then(response => {
                if(response && response.error && response.error.messages){
                    throw new Error(response.error.messages.join('\n'));
                } 
                else if (response.data == null) {
                    throw new Error(JSON.stringify(response));
                }

                if (response.data) {
                    resolve(response.data.result)
                }

            })
            .catch(error => {
                reject(error);
            })
    })
}

export function login( email, password, ) {
    return new Promise((resolve, reject) => {
        fetch(new auth_client(process.env.VUE_APP_AUTH_ROOT + '/api/auth/sidearm/login', {
            method: 'POST',
            body: JSON.stringify({email, password})
        }))
            .then(response => response.json())
            .then(response => {
                if (response && response.error && response.error.messages) {
                    throw new Error(response.error.messages.join('\n'));
                }
                else if (response.data == null) {
                    throw new Error(JSON.stringify(response));
                }

                if (response.data && response.data.accessToken) {
                    resolve(response.data.accessToken)
                }
            })
            .catch(error => {
                reject(error);
            })
    });
}

export function get_user_from_access_token(token) {
    return new Promise((resolve, reject) => {
        fetch(new auth_client(process.env.VUE_APP_AUTH_ROOT + '/api/auth/me' + create_url_params_from_object({access_token: token})))
            .then(response => response.json())
            .then(response => {
                if (response && response.error && response.error.messages) {
                    throw new Error(response.error.messages.join('\n'));
                }
                else if (response.data == null) {
                    throw new Error(JSON.stringify(response));
                }

                if (response.data) {
                    const user = {
                        access_token: response.data.accessToken,
                        id: response.data.sub,
                        name: response.data.name,
                        profile_picture: response.data.picture,
                        email: !response.data.email || response.data.email.includes('@example.org') ? '' : response.data.email,
                    }

                    resolve(user)
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

export function get_user_setting(setting_name = '', token = '') {
    return new Promise((resolve, reject) => {
        fetch(new auth_client(process.env.VUE_APP_AUTH_ROOT + '/api/auth/me/setting' + create_url_params_from_object({
            access_token: token,
            key: setting_name,
        })))
            .then(response => response.json())
            .then(response => {
                if (response && response.error && response.error.messages) {
                    throw new Error(response.error.messages.join('\n'));
                }
                else if (response.data == null) {
                    throw new Error(JSON.stringify(response));
                }

                if (response.data) {
                    resolve(response.data);
                }

            })
            .catch(error => {
                reject(error);
            });
    });
}

export function save_user_setting(setting_name = '', setting_value = '', token = '') {
    return new Promise((resolve, reject) => {
        const url = process.env.VUE_APP_AUTH_ROOT + '/api/auth/me/setting' + create_url_params_from_object({
            access_token: token,
            key: setting_name,
            value: setting_value,
        });

        fetch(new auth_client(url, {
            method: 'POST'
        }))
            .then(response => response.json())
            .then(response => {

                if (response && response.error && response.error.messages) {
                    throw new Error(response.error.messages.join('\n'));
                }
                resolve(response);

            })
            .catch(error => {
                reject(error);
            });
    });
}


// let _jwt = '';

// const internal_auth_provider = Vue.observable({
// 	user: null,

// 	get jwt() {
// 		return _jwt;
// 	},
// 	set jwt(jwt) {
// 		_jwt = jwt;
// 		localStorage.setItem('jwt', jwt || '');
// 		if (jwt) {
//             get_user_from_access_token(jwt)
//                 .then(user => (internal_auth_provider.user = user))
//                 .catch(() => {
//                     internal_auth_provider.jwt = null;
//                 });
// 		} else {
// 			this.user = null;
// 		}
// 	},
// });

// internal_auth_provider.jwt = localStorage.getItem('jwt') || null;

// export const auth_provider = internal_auth_provider;



// import { mapState, mapActions } from 'vuex';


// export default {
//     computed: {
//         ...mapState([
//             'user'
//         ])
//     },

//     methods: {
//         ...mapActions([
//             'get_setting'
//         ]),

//         get_user_setting() {
//             this.get_setting('foobar', this.user.access_token)
//         }
//     }
// }