<template>
    <div class="sa-layout sa-layout--right-sidebar">
		<div class="sa-row d-lg-flex">
            <div class="sa-layout__main flex-grow-1 flex-shrink-1 br-lg-dashed" >
                <slot></slot>
            </div>
            <div class="sa-layout__aside flex-grow-0 flex-shrink-0" >
                <slot name="sidebar"></slot>
            </div>
        </div>
	</div>
</template>

<script>
export default {
  name: "LayoutSidebarRight",

  data: () => ({})
};
</script>

<style lang="scss" scoped>
.sa-layout__aside, .sa-layout__main{
    min-width:1px;
}
@media #{map-get($display-breakpoints, 'lg-and-up')}{
    .sa-layout__aside{
        width:336px;
    }
}
</style>
