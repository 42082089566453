import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import Intersect from 'vuetify/lib/directives/intersect';
import Resize from 'vuetify/lib/directives/resize';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{def: Resize,name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"},{def: Intersect,name:"intersect",rawName:"v-intersect.once",value:(_vm.onResize),expression:"onResize",modifiers:{"once":true}}],staticClass:"sa-common-vgt-table",class:[_vm.wide ? 'is-wide':'', _vm.dense ? 'is-dense' : '']},[_c('vue-good-table',{ref:"table",attrs:{"columns":_vm.vgtColumns,"rows":_vm.vgtRows,"sort-options":_vm.sort,"style-class":"sa-common-vgt-table__table"},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [_c('div',{staticClass:"d-flex flex-column align-stretch justify-center sa-common-vgt-table__header-column"},[_c('div',{staticClass:"sa-common-vgt-table__header py-2",class:[props.column.align ? props.column.align : '', props.column.divider ? '--divider' : '', props.column.headerClass ? props.column.headerClass : '', _vm.dense ? 'px-2' : 'px-4' ]},[_c('span',{staticClass:"sa-common-vgt-table__header-label",class:props.column.sublabel ? 'sa-common-vgt-table__header-label--sub': ''},[_vm._v(_vm._s(props.column.label)),(props.column.sublabel)?_c('span',{staticClass:"sa-common-vgt-table__header-sublabel"},[_vm._v(_vm._s(props.column.sublabel))]):_vm._e()]),(props.column.sortable)?_c(VIcon,{staticClass:"mt-n1 ml-1",attrs:{"size":"18","color":"white"}},[_vm._v("$mdiChevronDown")]):_vm._e()],1)])]}},{key:"table-row",fn:function(props){return [_c('div',{staticClass:"sa-common-vgt-table__cell py-2",class:[
                    props.column.align ? props.column.align : '',
                    props.column.divider ? '--divider' : '',
                    props.column.cellClass ? props.column.cellClass : '',
                    _vm.dense ? 'px-2' : 'px-4',
                    props.row.IsHighligthed ? 'highlighted_item': '',
                    _vm.selectableRows ? `row-id--${props.row[_vm.indexField]}` : '',
                    _vm.selectableRows
                    ? props.row[_vm.indexField] == _vm.selectedRow
                        ? 'selected-row-column'
                        : ''
                    : '',
                ],on:{"click":function($event){if(_vm.selectableRows) _vm.selectRow(props.row[_vm.indexField])}}},[_c('span',{domProps:{"innerHTML":_vm._s(props.row[props.column.displayField]
                        ? props.row[props.column.displayField]
                        : props.formattedRow[props.column.field])}})])]}}])},[_c('div',{staticClass:"text-center pa-6",attrs:{"slot":"emptystate"},slot:"emptystate"},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }