import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sa-view-account"},[_c('common-title-page',{attrs:{"title":"Account"}}),_c(VContainer,{staticClass:"px-0 px-lg-8 py-0"},[_c('div',[_c('div',{staticClass:"pa-3"},[_c('h2',[_vm._v("Hello, "+_vm._s(_vm.email))])]),_c(VForm,{ref:"passwordForm"},[_c('div',{staticClass:"d-flex align-stretch justify-center"},[_c(VTextField,{staticClass:"input-group--focused {valid:passwordValidation.valid}",attrs:{"label":"New Password","type":"password","single-line":"","outlined":"","prepend-inner-icon":"$password","rounded":false,"required":"","clearable":""},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.newPassword && _vm.newPassword.length && _vm.passwordValidation.errors),expression:"newPassword && newPassword.length && passwordValidation.errors"}],staticClass:"caption mb-3"},_vm._l((_vm.passwordValidation.errors),function(error,index){return _c('li',{key:index,staticClass:"red--text"},[_vm._v(_vm._s(error))])}),0),_c('div',[_c(VTextField,{attrs:{"label":"Verify Password","type":"password","single-line":"","outlined":"","rules":[_vm.matchRules],"prepend-inner-icon":"$password","rounded":false,"required":"","clearable":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)]),_c('div',{staticClass:"align-center justify-space-between d-block d-md-flex"},[_c(VSpacer),_c(VBtn,{staticClass:"mb-2",attrs:{"dark":"","color":"#3B5999"},on:{"click":function($event){return _vm.updatePassword()}}},[_vm._v("Update Password")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }