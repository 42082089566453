<template>
    <div class="sa-common-tabs">
        <header class="sa-common-tabs__header d-flex flex-wrap" :class="headerClass">
            <slot name="header-left"/>
            <v-select
                class="pt-0 mt-0 text-uppercase font-weight-bold type-subtext"
                :items="selectItems"
                :value="value"
                background-color="primary"
                dark
                @change="change_active_tab"
                hide-details
                v-if="dropdown"
            ></v-select>
            <ul v-else class="reset-list d-flex sa-common-tabs__nav-items w-100 w-md-auto">
                <li class="sa-common-tabs__nav-item" v-for="(tab, tab_index) in tabs" :key="tab_index">
                    <v-btn
                    rounded
                    height="40"
                        @click="change_active_tab(tab_index);"
                        :color="value == tab_index ? 'secondary':'primary'"
                        
                    >
                        {{ tab.name }}
                    </v-btn>
                
                </li>
            </ul>
            <slot name="headerRight"/>
        </header>
       <slot/>
    </div>
</template>

<script>


export default {
  name: "CommonTabs",
  props: {
    value: {
      type: Number,
      required: true,
      default: 0
    },
    headerClass:{
        type:String,
        default:''
    },
    dropdown:{
        type:Boolean,
        default:false
    }
  },

  data: () => ({
    tabs: []
  }),

  watch: {
    value(newVal) {
        if (this.tabs.length){
            this.tabs = this.tabs.map((tab, index) => {
                if (index === newVal) {
                tab.is_active = true;
                } else {
                tab.is_active = false;
                }
                return tab;
            });
        }
    }
  },
  computed:{
      selectItems(){
          let _items = [];
          if(this.tabs.length){
            this.tabs.forEach((tab, index) =>{
                //console.log('INDEX',index);
                //console.log('TAB',tab.$options.propsData.name);
                _items.push({
                    text: tab.$options.propsData.name,
                    value:index
                });
            });
          }
          return _items;
      }
  },

  methods: {
    change_active_tab(tab_index = 0) {
      this.$emit("input", tab_index);
    },
  },

  created() {
    //this.tabs = this.$children;
    this.$nextTick(() => {
        this.tabs = this.$children.filter(c => c.$options._componentTag == 'common-tab');
        this.tabs[this.value].is_active = true;
    });
  }
};
</script>

<style lang="scss" scoped>
    .sa-common-tabs{
        .sa-common-tabs__nav-item{
            margin:0 $spacer*0.5;
            &:first-child{
                margin-left:0;
            }
            &:last-child{
                margin-right:0;
            }
        }
    }

    @media #{map-get($display-breakpoints, 'md-and-up')}{
    .sa-common-tabs{
        .sa-common-tabs__nav-item{
            margin:0 $spacer*1;
            &:first-child{
                margin-left:0;
            }
            &:last-child{
                margin-right:0;
            }
        }
    }

    }
</style>
