import { render, staticRenderFns } from "./CommonAvatarSidearm.vue?vue&type=template&id=ca45f804&scoped=true"
import script from "./CommonAvatarSidearm.vue?vue&type=script&lang=js"
export * from "./CommonAvatarSidearm.vue?vue&type=script&lang=js"
import style0 from "./CommonAvatarSidearm.vue?vue&type=style&index=0&id=ca45f804&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca45f804",
  null
  
)

export default component.exports