<template>
  <div v-if="content" v-html="content" class="sa-block-slideshow sa-block-slideshow--promo-dynamic-content"></div>
</template>

<script>
export default {
  name: "BlockSlideshowDynamicContent",
  props: {
    content: {
      type: String,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
  .sa-block-slideshow--promo-dynamic-content::v-deep {
    img {
      width: 100% !important;
      height: auto !important;
    }
  }
</style>
