<template>
    <div class="sa-common-byline d-flex type-subtext">
        <div class="sa-common-byline__author" v-if="story.byline">
            {{ story.byline}}
        </div>
        <span class="sa-safe-text--text d-inline-block px-2" v-if="story.byline && story.date">&bull;</span>
        <time class="sa-safe-text--text" v-if="story.date" :datetime="story.date">
            {{ story.date | moment("MMM D, YYYY") }}
        </time>
    </div>
</template>

<script>


export default {
  name: "CommonByline",
  props:{
      story:{
        type:Object,
        required:true,
        default:()=>{}
      }
  },
  data: () => ({}),
};
</script>

