import Vue from "vue";
import Vuex from "vuex";
import { login, sign_up, get_user_from_access_token, get_user_setting, save_user_setting }from '@/auth';

Vue.use(Vuex);

const initialState = () => ({
    user:null,
    maxFollowAllowed:10
});

const store = new Vuex.Store({
    state: initialState(),
    mutations: {
        SET_USER: (store,payload) =>{
        store.user = payload;
        },
        SET_USER_SETTINGS:(store,payload) =>{
            Vue.set(store.user,'settings',payload);
        }
  },
  actions: {
        log_in_user(context, payload) {
          return new Promise((resolve, reject) => {
              login(...payload)
                .then(jwt => {
                    if(payload[2]){
                        localStorage.setItem('SA-PRCA', JSON.stringify(jwt));
                    }else{
                        sessionStorage.setItem('SA-PRCA', JSON.stringify(jwt));
                    }
                    resolve();

                    get_user_from_access_token(jwt)
                        .then(user => {
                            context.commit('SET_USER', user);
                            context.dispatch('get_user_settings');
                        });
                })
              .catch(error => {
                    context.commit('SET_USER', null);
                    reject(error);
              });
          })
        },

        look_for_user(context){
            if(localStorage.getItem('SA-PRCA') || sessionStorage.getItem('SA-PRCA')) {
                let jwt = "";
                if(localStorage.getItem('SA-PRCA')){
                    jwt = JSON.parse(localStorage.getItem('SA-PRCA'));
                }else{
                    jwt = JSON.parse(sessionStorage.getItem('SA-PRCA'));
                }

                get_user_from_access_token(jwt)
                        .then(user => {
                            context.commit('SET_USER', user);
                            context.dispatch('get_user_settings');
                        }).catch(() => {
                            localStorage.removeItem('SA-PRCA');
                            sessionStorage.removeItem('SA-PRCA');
                            context.commit('SET_USER', null);
                        });
            }
        },

        sign_up_user(context, payload) {
            return new Promise((resolve, reject) => {
                sign_up(...payload)
                .then(jwt => {
                    resolve();

                    get_user_from_access_token(jwt)
                        .then(user => {
                            context.commit('SET_USER', user);
                            context.dispatch('get_user_settings');
                        });
                })
                .catch(error => {
                    context.commit('SET_USER', null);
                    reject(error);
                })
            })
        },

        log_out_user(context){
            localStorage.removeItem('SA-PRCA');
            sessionStorage.removeItem('SA-PRCA');
            context.commit('SET_USER',null);
        },

        get_user_settings(context){
            if(context.state.user){
                get_user_setting('settings', context.state.user.access_token)
                .then(value => {
                    context.commit('SET_USER_SETTINGS',JSON.parse(value));
                })
                .catch(() =>{
                    context.commit('SET_USER_SETTINGS',{ followed_contestants:[], followed_stock:[]});
                });
            }
        },

        follow_athletes(context,payload){
            let _settings = JSON.parse(JSON.stringify(context.state.user.settings));
            if(!_settings[payload.type]){
                _settings[payload.type] = [];
            }
            if(Array.isArray(payload.athletes)){
                _settings[payload.type] = payload.athletes;
            }else{
                _settings[payload.type].push(payload.athletes);
            }
            save_user_setting('settings', JSON.stringify(_settings), context.state.user.access_token)
            .then(() => {
                context.commit('SET_USER_SETTINGS',_settings);
            });
        },

        unfollow_athlete(context,payload){
            let _settings = JSON.parse(JSON.stringify(context.state.user.settings));
            if(!_settings[payload.type]){
                _settings[payload.type] = [];
            }
            if(_settings[payload.type].indexOf(payload.athlete) > -1){
                _settings[payload.type].splice(_settings[payload.type].indexOf(payload.athlete),1);

                save_user_setting('settings', JSON.stringify(_settings), context.state.user.access_token)
                .then(() => {
                    context.commit('SET_USER_SETTINGS',_settings);
                });
            }
        },

        set_user_defaults(context, payload){
            let _settings = JSON.parse(JSON.stringify(context.state.user.settings));
            if (!_settings['defaults']) {
                _settings['defaults'] = {};
            }

            if(!_settings['defaults'][payload.type]){
                _settings['defaults'][payload.type] = '';
            }
            _settings['defaults'][payload.type] = payload.value;
            save_user_setting('settings', JSON.stringify(_settings), context.state.user.access_token)
                .then(() => {
                    context.commit('SET_USER_SETTINGS',_settings);
                });
        },

        save_favorites(context,payload){
          let _settings = JSON.parse(JSON.stringify(context.state.user.settings));
          if(!_settings[payload.type]){
              _settings[payload.type] = [];
          }
          _settings[payload.type] = payload.favorites;
          save_user_setting('settings', JSON.stringify(_settings), context.state.user.access_token)
              .then(() => {
                  context.commit('SET_USER_SETTINGS',_settings);
              });
        },


        toggle_favorite(context,payload){
            let _settings = JSON.parse(JSON.stringify(context.state.user.settings));
            if(!_settings[payload.type]){
              _settings[payload.type] = [];
            }
            let _index = _settings[payload.type].findIndex((v) => v === payload.favorite);
            if(_index > -1){
                _settings[payload.type].splice(_index,1);
            }else{
                _settings[payload.type].push(payload.favorite);
            }
            save_user_setting('settings', JSON.stringify(_settings), context.state.user.access_token)
              .then(() => {
                  context.commit('SET_USER_SETTINGS',_settings);
              });
        },

  },
  getters:{
      is_logged_in: state =>{
          return state.user;
      },
      is_max_follows_reached: state =>{
          return state.user ? state.user.settings.followed_contestants.length + state.user.settings.followed_stock.length >= state.maxFollowAllowed : false;
      },
      has_default_settings: state => {
          if (state.user &&  state.user.settings && state.user.settings.defaults) {
              return true;
          }
          return false;
      },
      user_favorites: state => {
          if (state.user && state.user.settings) {
              if (state.user.settings.favorites && state.user.settings.favorites.length > 0) {
                  return state.user.settings.favorites;
              }
          }
          return [];
      }
  },
  modules: {}

});

export default store;
