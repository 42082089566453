import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sa-view-stock"},[_c('common-title-page',{attrs:{"title":"Stock"}}),_c(VContainer,{staticClass:"px-0 px-lg-8 py-0"},[_c('element-ad-butler',{staticClass:"py-8 bb-dashed d-flex justify-center",attrs:{"type":_vm.$vuetify.breakpoint.smAndDown ? 'leaderboard-mobile' : 'leaderboard'}})],1),(_vm.featuredStock.length || _vm.featuredContestants.length)?_c(VContainer,{staticClass:"px-0 py-0"},[_c('div',{staticClass:"d-flex align-stretch justify-center"},[(_vm.featuredContestants.length && (_vm.$vuetify.breakpoint.xlOnly || _vm.activeFeaturedTab === 'contestants'))?_c('div',{staticClass:"pt-8 w-100 w-xl-50",class:_vm.featuredStock.length ? 'br-xl-dashed' : ''},[_c('header',{staticClass:"d-flex align-center justify-space-between pb-4 pb-xl-8 px-8"},[_c('common-title',{attrs:{"top":"Featured","bottom":_vm.$vuetify.breakpoint.xlOnly ? 'Contestants' : 'Athletes',"type":"h3"}})],1),(_vm.featuredStock.length)?_c('div',{staticClass:"d-flex d-xl-none pb-8 px-7"},[_c('div',{staticClass:"w-50 px-1"},[_c(VBtn,{attrs:{"rounded":"","block":"","height":"40","color":_vm.activeFeaturedTab === 'contestants' ? 'secondary' : 'primary'},on:{"click":function($event){_vm.activeFeaturedTab = 'contestants'}}},[_vm._v("Contestants")])],1),_c('div',{staticClass:"w-50 px-1"},[_c(VBtn,{attrs:{"rounded":"","block":"","height":"40","color":_vm.activeFeaturedTab === 'stock' ? 'secondary' : 'primary'},on:{"click":function($event){_vm.activeFeaturedTab = 'stock'}}},[_vm._v("Stock")])],1)]):_vm._e(),(_vm.featuredContestants.length)?_c('div',{key:'contestants' + _vm.featuredContestants.length,staticClass:"sa-athletes__slider pb-8 px-4"},[_c('tiny-slider',{staticClass:"mb-8",attrs:{"mouse-drag":true,"loop":false,"nav-position":"bottom","nav":true,"controls":false,"controls-position":"bottom","items":"1","slide-by":"1","gutter":"0","responsive":{
                            540:{
                                items:2,
                                slideBy:2
                            },
                            768:{
                                items:3,
                                slideBy:3
                            },
                            960:{
                                items:4,
                                slideBy:4
                            },
                            1264:{
                                items:3,
                                slideBy:3
                            }
                        }}},_vm._l((_vm.featuredContestants),function(athlete){return _c('element-athletes-featured-item',{key:athlete.Type() + athlete.AthleteId(),staticClass:"text-center px-4",attrs:{"athlete":athlete}})}),1)],1):_vm._e()]):_vm._e(),(_vm.featuredStock.length && (_vm.$vuetify.breakpoint.xlOnly || _vm.activeFeaturedTab === 'stock'))?_c('div',{staticClass:"pt-8 w-100 w-xl-50"},[_c('header',{staticClass:"d-flex align-center justify-space-between pb-4 pb-xl-8 px-8"},[_c('common-title',{attrs:{"top":"Featured","bottom":_vm.$vuetify.breakpoint.xlOnly ? 'Stock' : 'Athletes',"type":"h3"}})],1),(_vm.featuredContestants.length)?_c('div',{staticClass:"d-flex d-xl-none pb-8 px-7"},[_c('div',{staticClass:"w-50 px-1"},[_c(VBtn,{attrs:{"rounded":"","block":"","height":"40","color":_vm.activeFeaturedTab === 'contestants' ? 'secondary' : 'primary'},on:{"click":function($event){_vm.activeFeaturedTab = 'contestants'}}},[_vm._v("Contestants")])],1),_c('div',{staticClass:"w-50 px-1"},[_c(VBtn,{attrs:{"rounded":"","block":"","height":"40","color":_vm.activeFeaturedTab === 'stock' ? 'secondary' : 'primary'},on:{"click":function($event){_vm.activeFeaturedTab = 'stock'}}},[_vm._v("Stock")])],1)]):_vm._e(),(_vm.featuredStock.length)?_c('div',{key:'stock' + _vm.featuredStock.length,staticClass:"pb-8 px-4"},[_c('tiny-slider',{staticClass:"mb-8",attrs:{"mouse-drag":true,"loop":false,"nav-position":"bottom","nav":true,"controls":false,"controls-position":"bottom","items":"1","slide-by":"1","gutter":"0","responsive":{
                            540:{
                                items:2,
                                slideBy:2
                            },
                            768:{
                                items:3,
                                slideBy:3
                            },
                            960:{
                                items:4,
                                slideBy:4
                            },
                            1264:{
                                items:3,
                                slideBy:3
                            }
                        }}},_vm._l((_vm.featuredStock),function(athlete){return _c('element-athletes-featured-item',{key:athlete.Type() + athlete.AthleteId(),staticClass:"text-center px-4",attrs:{"athlete":athlete}})}),1)],1):_vm._e()]):_vm._e()]),_c('div',{staticClass:"mx-8 bb-dashed"})]):_vm._e(),_c(VContainer,{staticClass:"px-0 py-0"},[_c('header',{staticClass:"d-flex align-center justify-space-between mb-4 px-8 pt-8"},[_c('common-title',{attrs:{"top":"All ProRodeo","bottom":"Stock Stats","type":"h3"}}),_c('element-ad-butler',{attrs:{"type":_vm.$vuetify.breakpoint.smAndDown ? 'content-sponsor-mobile' : 'content-sponsor'}})],1),_c('div',{staticClass:"d-flex flex-column align-center px-8 pb-8"},[_c('iframe',{staticClass:"d-flex",staticStyle:{"min-height":"1150px","width":"100%","border":"none"},attrs:{"id":"stats-iframe","src":"https://stockstats.prorodeo.org/prorodeo/prca-stock-stats/stock"}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }