<template>
    <div class="sa-common-title-page d-flex align-center justify-center w-100 py-8 px-8 py-lg-12">
        <img width="48" height="41" class="sa-common-title-page__image flex-shrink-0 mr-4" src="@/assets/images/image_stripes_wide.svg" alt=""/>
        <h2 class="sa-common-title-page__text ma-0 pa-0 flex-shrink-1 d-flex flex-column justify-center align-start text-uppercase white--text type-heading--lg type-lg-heading--xl">
            {{ title }}
        </h2>
    </div>
</template>

<script>

export default {
  name: "CommonTitlePage",
  props:{
      title:{
        type:String,
        required:true,
        default:''
      }
  },
  data: () => ({}),

};
</script>

<style lang="scss" scoped>
    .sa-common-title-page{
        background:url("@/assets/images/image_background_wood.jpg") top center no-repeat $color-primary;
        &__image{
            width:30px;
        }
    }

    @media #{map-get($display-breakpoints, 'lg-and-up')}{
        .sa-common-title-page{
            &__image{
                width:48px;
            }
        }
    }

    @media screen and (min-width:1921px){
        .sa-common-title-page{
            background-size: cover;
        }
    }
</style>
