<template>
<div class="sa-view-home">
    <v-container class="px-0 px-lg-8 py-0">
      <layout-sidebar-right>
        <element-ad-butler :type="$vuetify.breakpoint.smAndDown || $vuetify.breakpoint.lgOnly ? 'leaderboard-mobile' : 'leaderboard'" class="py-4 py-lg-8 bb-lg-dashed d-flex justify-center sa-view-home__leaderboard" />
        <block-rodeos-payout class="px-8 pt-8" :total-payout="totalPayout" v-if="$vuetify.breakpoint.mdAndDown && totalPayout && totalPayoutEnabled == true"/>
        <block-stories-carousel :stories="featuredStories" class="py-8 pr-lg-8 bb-dashed"/>
        <block-slideshow-vimeo-embed class="py-8 px-8 pl-lg-0"/>
        <div v-if="user && user.settings && user.settings.followed_contestants && user.settings.followed_contestants.length">
            <block-account-favorites-carousel  class="py-8 px-8 pl-lg-0 bt-dashed"/>
        </div>
        <template #sidebar>
            <div v-if="$vuetify.breakpoint.lgAndUp">
                <block-rodeos-payout class="py-8 pl-lg-8 bb-dashed"  :total-payout="totalPayout" v-if="totalPayout && totalPayoutEnabled == true" />
                <element-ad-butler class="py-8 pl-lg-8 bb-dashed " type="square" location="homepage-1" />
                <block-stories-list  class="py-8 pl-lg-8 bb-dashed"/>
                <block-ad-social-icons class="py-8 pl-lg-8 bb-dashed"/>
            </div>
            <block-standings-limited class="pa-8 bt-dashed bb-dashed" v-if="$vuetify.breakpoint.mdAndDown" :type="$vuetify.breakpoint.mdAndUp ? 'carousel' : 'list' "/>
            <element-ad-butler class="py-8  d-flex justify-center" type="square"/>
        </template>
      </layout-sidebar-right>
    </v-container>
      <block-slideshow-homepage />
        <v-container class="px-0 px-lg-8 py-0">
        <layout-sidebar-right>
            <block-standings-limited class="py-8 pr-lg-8 bb-dashed" v-if="$vuetify.breakpoint.lgAndUp" type="carousel"/>
            <block-stories-list  class="pa-8 bb-dashed" v-if="$vuetify.breakpoint.mdAndDown"/>
            <block-slideshow-dynamic-content class="mx-1 mx-md-0 py-8 pr-lg-8 bb-dashed" v-if="promoContentEnabled" :content="promoContentHTML" />
            <block-slideshow-promo class="py-8 pr-lg-8 bb-dashed"/>
            <block-rodeos-list class="py-8 pr-lg-8 bb-dashed bb-lg-none"/>
            <template #sidebar>
                <element-ad-butler class="py-8 pl-lg-8 d-flex justify-center bb-dashed" type="square"/>
                <block-slideshow-sports-news class="pa-8 pr-lg-0 bb-dashed"/>
            </template>
        </layout-sidebar-right>
    </v-container>
</div>

</template>

<script>
import { get } from "@/helpers";
import { mapState } from 'vuex';

import LayoutSidebarRight from "@/components/layouts/LayoutSidebarRight";
import BlockStoriesList from "@/components/blocks/stories/BlockStoriesList";
import BlockStoriesCarousel from "@/components/blocks/stories/BlockStoriesCarousel";
import BlockStandingsLimited from "@/components/blocks/standings/BlockStandingsLimited";
import BlockRodeosPayout from "@/components/blocks/rodeos/BlockRodeosPayout";
import BlockRodeosList from "@/components/blocks/rodeos/BlockRodeosList";
import BlockAdSocialIcons from "@/components/blocks/ads/BlockAdSocialIcons";
import BlockSlideshowPromo from "@/components/blocks/slideshow/BlockSlideshowPromo";
import BlockSlideshowSportsNews from "@/components/blocks/slideshow/BlockSlideshowSportsNews";
import BlockSlideshowHomepage from "@/components/blocks/slideshow/BlockSlideshowHomepage";
import BlockSlideshowDynamicContent from "@/components/blocks/slideshow/BlockSlideshowDynamicContent";

export default {
    name: "Home",
    components: {
        LayoutSidebarRight,
        BlockStoriesList,
        BlockStoriesCarousel,
        BlockStandingsLimited,
        BlockRodeosList,
        BlockAdSocialIcons,
        BlockSlideshowPromo,
        BlockSlideshowDynamicContent,
        BlockSlideshowSportsNews,
        BlockRodeosPayout,
        BlockSlideshowHomepage,
        BlockAccountFavoritesCarousel: () => import("@/components/blocks/account/BlockAccountFavoritesCarousel"),
        BlockSlideshowVimeoEmbed : () => import("@/components/blocks/slideshow/BlockSlideshowVimeoEmbed")
    },
    data: () => ({
        featuredStories:[],
        promoContentHTML: '',
        promoContentEnabled: false,
        totalPayout: null,
        totalPayoutEnabled: false,
    }),
    computed:{
        ...mapState({
            user: state => state.user,
        })
    },
    
    methods:{
        get_stories(){
            const featured_request_options = {
               type:"stories",
               count:5,
               extra:JSON.stringify({featured:true})
           }
           get("/services/adaptive_components.ashx", featured_request_options).then(response => {
               this.featuredStories = response;
           });
        },
        get_promo_content() {
            get("/services/pro_rodeo.ashx/get_homepage_settings", {})
              .then(response => {
                this.promoContentHTML = response.HomepagePromoContent;
                this.promoContentEnabled = response.HomepagePromoContentEnabled;
                this.totalPayout = response.HomepageTotalPayout,
                this.totalPayoutEnabled = response.HomepageTotalPayoutEnabled
              }) 
        }
    },
    created(){
        this.get_stories();

        this.setMetaSidearm('Rodeo News & Information | Rodeo Schedule', [
            {
                name: 'description',
                content: 'The PRCA is the number one official source for rodeo news, events, schedules, past results and other rodeo-related information. Visit PRCA now!'
            },
        ]);
        this.appendMetaSidearmDefaultImage();
        this.get_promo_content();
    }

};
</script>

<style lang="scss" scoped>
.sa-view-home{
    &__leaderboard{
        background:$color-white;
    }
}

@media #{map-get($display-breakpoints, 'lg-and-up')}{
    .sa-view-home{
        &__leaderboard{
            background:none;
        }
    }
}
</style>
