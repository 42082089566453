<template>
    <div class="sa-common-loader">
       <object class="sa-common-loader__object" type="image/svg+xml" data="/assets/images/image_common_loader.svg"></object>
    </div>
</template>

<script>

export default {
  name: "CommonLoader",
  data: () => ({})
};
</script>


<style lang="scss" scoped>
.sa-common-loader__object{
    width:100%;
    max-width:60px;
}
</style>
