<template>
    <section class="sa-block-rodeos sa-block-rodeos--list">
            <common-tabs v-model="active_tab">
                <template #header-left>
                    <common-title class="mr-auto mb-4 mb-md-0" :top="$root.currentYear" bottom="ProRodeos" type="h2"/>
                </template>
                <common-tab name="Upcoming" >
                    <ol class="reset-list sa-drop-shadow mt-8" v-if="upcomingRodeos.length" >
                        <li class="bb-solid--light" v-for="rodeo in upcomingRodeos" :key="rodeo.name">
                            <element-rodeos-list-item :rodeo="rodeo" />
                        </li>
                    </ol>
                    <div class="sa-drop-shadow mt-8 pa-8 white" v-else>No upcoming rodeos available.</div>
                </common-tab>
                <common-tab name="In Progress" >
                    <ol class="reset-list sa-drop-shadow mt-8" v-if="inProgressRodeos.length" >
                        <li class="bb-solid--light" v-for="rodeo in inProgressRodeos" :key="rodeo.name">
                            <element-rodeos-list-item :rodeo="rodeo" />
                        </li>
                    </ol>
                    <div class="sa-drop-shadow mt-8 pa-8 white" v-else>No rodeos currently in progress.</div>
                </common-tab>
                <common-tab name="Results">
                    <ol class="reset-list sa-drop-shadow mt-8" v-if="resultsRodeos.length" >
                        <li class="bb-solid--light" v-for="rodeo in resultsRodeos" :key="rodeo.name">
                            <element-rodeos-list-item :rodeo="rodeo" />
                        </li>
                    </ol>
                    <div class="sa-drop-shadow mt-8 pa-8 white" v-else>No rodeo results available.</div>
                </common-tab>
            </common-tabs>
           
            <div class="sa-block-rodeos__footer d-flex align-center justify-start mt-8 px-8 px-lg-0">
                 <v-btn color="primary" :to="{name: active_tab === 0 ? 'Schedule' : 'Results'}" class="mr-4">
                     {{ $vuetify.breakpoint.mdAndUp ? 'Full Schedule' : 'All'}}
                    <v-icon right>$arrowRight</v-icon>
                </v-btn>
                <element-ad-butler type="content-sponsor" />
            </div>
	</section>
</template>

<script>

import { get, map_rodeos } from "@/helpers";
import CommonTitle from "@/components/common/CommonTitle";
import CommonTabs from "@/components/common/tabs/CommonTabs";
import CommonTab from "@/components/common/tabs/CommonTab";
import ElementRodeosListItem from "@/components/elements/rodeos/ElementRodeosListItem"


export default {
    name: "BlockRodeosList",
    components: {
        CommonTitle,
        CommonTabs,
        CommonTab,
        ElementRodeosListItem
    },
    data: () => ({
        active_tab:0,
        upcomingRodeos:[],
        inProgressRodeos:[],
        resultsRodeos:[]
    }),
    methods:{
        getUpcomingRodeos(){
            const request_options = {
                year: this.$root.currentYear,
                active:true,
                type:"schedule",
                page_size:5
            };
            get("/services/pro_rodeo.ashx/schedule", request_options).then(response => {
                if(response && response.length){
                    this.upcomingRodeos = map_rodeos(response);
                }
            });
        },
        getInProgressRodeos(){
            const request_options = {
                year: this.$root.currentYear,
                type:"inprogress",
                page_size:5
            };
            get("/services/pro_rodeo.ashx/schedule", request_options).then(response => {
                if(response && response.length){
                    this.inProgressRodeos = map_rodeos(response);
                }
            });
        },
        getResultsRodeos(){
            const request_options = {
                year: this.$root.currentYear,
                type:"results",
                active:true,
                page_size:5
            };
            get("/services/pro_rodeo.ashx/schedule", request_options).then(response => {
                if(response && response.length){
                    this.resultsRodeos = map_rodeos(response);
                }
            });
        }
    },
    created(){
        this.getUpcomingRodeos();
        this.getInProgressRodeos();
        this.getResultsRodeos();
    }
};
</script>

<style lang="scss">
.sa-block-rodeos{
    .sa-common-tabs__header{
        padding:0 $spacer*8;
    }
    .sa-common-tabs__nav-item{
        flex:0 1 auto;
        min-width:1px;
        .v-btn{
            max-width:100%;
        }
    }
}

@media #{map-get($display-breakpoints, 'lg-and-up')}{
    .sa-block-rodeos{
        .sa-common-tabs__header{
            padding:0;
        }
    }
}
</style>

