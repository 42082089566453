<template>
<!-- to do: trending and related stories -->
    <aside class="sa-block-stories sa-block-stories--list">
        <common-title  :top="properties.titleTop" :bottom="properties.titleBottom" type="h2"/>
            <div v-if="type === 'top-stories'" class="mt-4 d-flex">
                <v-btn rounded height="40" class="flex-grow-1 mr-1" @click="getLatestStories" :color="tab === 'latest' ? 'secondary': 'primary'">
                    Latest
                </v-btn>
                <v-btn rounded height="40" class="flex-grow-1 ml-1" @click="getTrendingStories" :color="tab != 'latest' ? 'secondary': 'primary'">
                    Trending
                </v-btn>
            </div>
            <ul class="mx-0 mb-0 mt-8 pa-0 reset-list">
                <li class="mb-4" v-for="story in stories" :key="story.id">
                    <component :is="story.redirect_absolute_url ? 'a' : 'router-link'" class="text-link font-weight-bold mb-2" v-bind="story.redirect_absolute_url ? {href: story.redirect_absolute_url} : {to: story.url}" >{{story.title}}</component>
                    <span class="type-subtext d-block"> {{ story.date | moment("MMM D, YYYY") }}</span>
                </li>
            </ul>
            <div class="sa-block-news__footer d-flex align-center justify-start mt-8">
                 <v-btn color="primary" title="View More Stories" v-if="!onThisRoute" :exact="true" :to="{name: properties.link}" class="mr-4">
                    More<span class="d-sr-only"> News in Archives</span>
                    <v-icon right>$arrowRight</v-icon>
                </v-btn>
                  <element-ad-butler type="content-sponsor" />
            </div>
	</aside>
</template>

<script>
import { get } from "@/helpers";

import CommonTitle from "@/components/common/CommonTitle";

export default {
    name: "BlockStoriesList",
    props:{
        type:{
            type:String,
            default:'latest'
        },
        count:{
            type:[Number,String],
            default:4
        },
    },
    components: {
        CommonTitle,
    },
    data: () => ({
        tab:'latest',
        stories:[],
    }),
    computed:{
        properties(){
            let _properties = {};
            switch(this.type){
                    case 'latest':
                            _properties['titleTop'] = "The";
                            _properties['titleBottom'] = "Latest";
                            _properties['link'] = "News";
                        break;
                    case 'related':
                            _properties['titleTop'] = "Related";
                            _properties['titleBottom'] = "Stories";
                            _properties['link'] = "News";
                        break;
                    case 'top-stories':
                             _properties['titleTop'] = "Top";
                            _properties['titleBottom'] = "Stories";
                            _properties['link'] = "News";
                        break;
                    default:
                            _properties['titleTop'] = "The";
                            _properties['titleBottom'] = "Latest";
                            _properties['link'] = "News";
                        break;
                }

            return _properties;
      },
      onThisRoute(){
          return this.properties.link === this.$route.name;
      }
    },
    methods:{
        getLatestStories(){
            this.tab = 'latest';

            const latest_request_options = {
               type:"stories",
               count:this.count,
            }
            get("/services/adaptive_components.ashx", latest_request_options).then(response => {
                this.stories = response;
            });

        },
        getTrendingStories(){
            this.tab = 'trending';

            const latest_request_options = {
               type:"stories",
               count:this.count,
            }
            get("/services/adaptive_components.ashx", latest_request_options).then(response => {
                this.stories = response;
            });

        },
    },
    created(){
        switch(this.type){
            case 'related':
                this.getRelatedStories();
                break;
            case 'top-stories':
                this.getLatestStories();
                break;
            case 'latest':
                this.getLatestStories();
                break;
        }

    }
};
</script>

