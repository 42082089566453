<template>
    <nav class="sa-main-nav d-flex align-center flex-grow-1" >
        <h2 class="d-sr-only">Main Navigation</h2>
        <div class="ml-auto mr-0 ml-md-0 mr-md-auto d-flex sa-main-nav__desktop">
            <div v-for="(item,itemIndex) in navItems" :key="'level-1-' + item.id"  >
                <v-menu @input="$emit('nav-hover', $event)" v-if="item.items && item.items.length && item.class_name != 'more' && item.class_name != 'footer' && ($vuetify.breakpoint.xlOnly || (itemIndex < 3 && $vuetify.breakpoint.mdAndUp) || (item.class_name === 'results'))"  open-on-hover attach min-width="100%" z-index="100" fixed>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    color="primary"
                    class="sa-main-nav__url--level-1"
                    dark
                    exact
                    tile
                    v-on="on"
                    v-bind="attrs"
                    :height="$vuetify.breakpoint.lgAndUp ? '72' : '60' "
                    :rel="item.url.includes('http') ? 'noopener': ''"
                    :href="item.url.includes('http') ? item.url : undefined"
                    :target="item.url.includes('http') ? item.open_in_new_window ? '_blank' :'_self' : undefined"
                    :to="item.url.includes('http') ? undefined : item.url"
                    >
                    {{ item.title }}
                    </v-btn>
                </template>
                    <v-container class="sa-main-nav__dropdown py-6">
                        <v-row class="d-flex px-5" >
                            <v-list class="sa-main-nav__column py-0 px-3 flex-grow-0 flex-shrink-1" v-for="column in item.columns" :key="'level-2-' + column.column">
                                <v-list-item
                                dense
                                class="bb-dashed px-0 py-0 align-stretch sa-main-nav__item--level-2 "
                                :class="!column_item.url || column_item.url === '#' ? 'sa-main-nav__separator': ''"
                                v-for="(column_item,index) in column.items"
                                :href="column_item.url.includes('http') ? column_item.url : undefined"
                                :to="column_item.url.includes('http') || column_item.url === '#' ? undefined : column_item.url"
                                :target="column_item.url && column_item.url != '#' ? column_item.open_in_new_window ? '_blank' : '_self' : undefined "
                                :rel="column_item.url.includes('http') ? 'noopener': ''"
                                :key="'item-level-2-' + item.id + '' + index"
                                >

                                            <span v-if="column_item.url" class=" px-4 d-flex align-center sa-main-nav__url--level-2 " :class="column_item.class_name === 'highlight' ? 'sa-main-nav__url--highlight' : ''" >{{ column_item.class_name === 'highlight' ? '&bull; ' + column_item.title : column_item.title }}</span>
                                            <span v-else class="sa-main-nav__separator d-flex align-start px-4 pt-2 font-weight-bold text-uppercase">{{ column_item.title }}</span>

                                </v-list-item>
                            </v-list>
                        </v-row>
                    </v-container>
                </v-menu>
                <v-btn v-else-if="(!item.items || item.items.length < 1 || (item.items.length > 0 && item.class_name === 'nfr') ) && item.class_name != 'more' && item.class_name != 'footer' && ($vuetify.breakpoint.xlOnly || (itemIndex < 3 && $vuetify.breakpoint.mdAndUp ) || (item.class_name === 'results') || (item.class_name === 'nfr'))"
                    color="primary"
                    class="sa-main-nav__url--level-1"
                    dark
                    tile
                    exact
                    :rel="item.url.includes('http') ? 'noopener': ''"
                    :height="$vuetify.breakpoint.lgAndUp ? '72' : '60' "
                    :href="item.url.includes('http') ? item.url : undefined"
                    :target="item.url.includes('http') ? item.open_in_new_window ? '_blank' :'_self' : undefined"
                    :to="item.url.includes('http') ? undefined : item.url"
                    min-width="0"
                    >
                    {{ item.title }}
                </v-btn>
            </div>
        </div>
        <slot/>
         <v-menu @input="$emit('input',!value)" :value="value" class="sa-main-nav__mobile" attach tile :max-width="$vuetify.breakpoint.lgOnly || $vuetify.breakpoint.mdOnly  ? '400px' : '100%'" :min-width="$vuetify.breakpoint.lgOnly || $vuetify.breakpoint.mdOnly  ? '400px' : '100%'" z-index="100" fixed :close-on-content-click="false" :close-on-click="true" :max-height="maxHeight" v-resize="onResize" >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                color="primary"
                dark
                tile
                class="sa-main-nav__mobile-button px-1"
                small
                v-on="on"
                :min-width="$vuetify.breakpoint.lgAndUp ? 40 : 59"
                v-bind="attrs"
                :height="$vuetify.breakpoint.lgAndUp ? '72' : '60' "
                aria-label="toggle navigation menu"
                >
                <v-icon size="24">{{ value ? '$close' : '$menu' }}</v-icon>
                </v-btn>
            </template>
            <block-ad-social-icons v-if="$vuetify.breakpoint.mdAndDown" class="py-3 bb-solid--light sa-main-nav__social"/>
            <v-expansion-panels flat tile class="white">
                <v-expansion-panel  v-for="item in mobileNavItems" :key="'level-1-' + item.id + '--mobile'" class="mt-0" >
                      <v-expansion-panel-header class="type-subheading font-weight-black  text-uppercase sa-main-nav__item--level-1" :class="!item.items || item.items.length < 1 ? 'pa-0 align-stretch' : 'text-link'" :hide-actions="!item.items || item.items.length < 1">
                          <span v-if="item.items && item.items.length">{{ item.title }}</span>
                          <a class="sa-main-nav__url--level-1 d-flex align-center px-6 text-link" v-else-if="item.url.includes('http')" :href="item.url" rel="noopener" :target="item.open_in_new_window ? '_blank' :'_self'"  >{{item.title}}</a>
                          <router-link class="sa-main-nav__url--level-1 d-flex align-center px-6 text-link" v-else :to="item.url"  @click.native.stop="goToMobileLink" >{{item.title}}</router-link>

                        </v-expansion-panel-header>
                       <v-expansion-panel-content class="sa-main-nav__dropdown my-0" v-if="item.items && item.items.length">
                           <v-list class="sa-background my-8 py-0 px-4">
                                <v-list-item
                                class="bb-dashed sa-main-nav__item--level-2"
                                dense
                                v-for="(column_item,index) in item.items"
                                :key="'item-level-2-' + item.id + '' + index"
                                :class="column_item.url && column_item.url != '#' ? '' : 'mt-3'"
                                :href="column_item.url.includes('http') ? column_item.url : undefined"
                                :target="column_item.url.includes('http') ? column_item.open_in_new_window ? '_blank' :'_self' : undefined"
                                :rel="column_item.url.includes('http') ? 'noopener': ''"
                                :to="column_item.url.includes('http') ? undefined : column_item.url"
                                @click.native.stop="goToMobileLink"
                                >
                                <v-list-item-title>
                                      <span v-if="column_item.url" class=" d-flex align-center sa-main-nav__url--level-2" :class="column_item.class_name === 'highlight' ? 'sa-main-nav__url--highlight' : ''">{{column_item.class_name === 'highlight' ? '&bull; ' + column_item.title : column_item.title}}</span>
                                        <span v-else class="sa-main-nav__separator type-subtext d-flex align-start pb-2 font-weight-bold text-uppercase">{{ column_item.title }}</span>
                                </v-list-item-title>
                                </v-list-item>
                            </v-list>

                       </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-container class="sa-main-nav__dropdown py-6 sa-main-nav__dropdown--additional">
                <v-row class="d-flex px-5" v-for="additionalItem in additionalNavItems" :key="additionalItem.id">
                    <v-list class="sa-main-nav__column py-0 px-xl-3 flex-grow-0 flex-shrink-1" v-for="column in additionalItem.columns" :key="'level-2-' + column.column + '--additional'" >
                        <v-list-item
                        dense
                        class="bb-dashed px-0 py-0 align-stretch sa-main-nav__item--level-2"
                        :class="!column_item.url || column_item.url === '#' ? 'sa-main-nav__separator': ''"
                        v-for="(column_item,index) in column.items"
                        :key="'item-level-2-' + additionalItem.id + '' + index"
                         @click.native.stop="goToMobileLink"
                        :href="column_item.url.includes('http') ? column_item.url : undefined"
                        :target="column_item.url.includes('http') ? column_item.open_in_new_window ? '_blank' :'_self' : undefined"
                        :to="column_item.url.includes('http') ? undefined : column_item.url"
                        :rel="column_item.url.includes('http') ? 'noopener': ''"
                        >
                            <span v-if="column_item.url" class=" px-4 d-flex align-center sa-main-nav__url--level-2" :class="column_item.class_name === 'highlight' ? 'sa-main-nav__url--highlight' : ''">{{ column_item.class_name === 'highlight' ? '&bull; ' + column_item.title : column_item.title }}</span>
                            <span v-else class="sa-main-nav__separator d-flex align-start px-4 pt-2 font-weight-bold text-uppercase">{{ column_item.title }}</span>
                            <span class="sa-main-nav__url--level-2 mt-2 ml-n1" v-if="column_item.url && column_item.open_in_new_window">
                              <external-link-icon size="1x" class="font-weight-thin mt-1"></external-link-icon>
                            </span>
                        </v-list-item>
                    </v-list>
                </v-row>
            </v-container>
        </v-menu>

    </nav>
</template>

<script>

import BlockAdSocialIcons from "@/components/blocks/ads/BlockAdSocialIcons";
import { ExternalLinkIcon } from "vue-feather-icons";
import filter from 'lodash/filter';

import { get } from "@/helpers";

export default {
    props:{
        value:{
            type:Boolean,
            default:false
        }
    },
    name: "TheNavigationMenu",
    components:{
        BlockAdSocialIcons,
        ExternalLinkIcon
    },
   data: () => ({
       navItems: [],
       mobileMenuActive:null,
       maxHeight: window.innerHeight - 60
   }),
   methods:{
       onResize(){
           this.maxHeight = window.innerHeight - 60;
       },
       getNavigation(){
           const request_options = {
               type:"main-navigation"
           }
           get("/services/adaptive_components.ashx", request_options).then(response => {
               this.navItems = response;
            });
       },
       goToMobileLink(){
           this.$emit('input',false);
       }
   },
   computed:{
       mobileNavItems(){
           let navWithoutAdditionalItems = filter(this.navItems, function(navItem){
               return navItem.class_name != 'more' && navItem.class_name != 'footer';
           });
           if(this.$vuetify.breakpoint.lgOnly || this.$vuetify.breakpoint.mdOnly ){
               return filter(navWithoutAdditionalItems.slice(3), function(navItem){
                   return navItem.class_name != 'results';
               });
           }else if(this.$vuetify.breakpoint.smAndDown){
               return filter(navWithoutAdditionalItems, function(navItem){
                   return navItem.class_name != 'results';
               });
           }
           return [];
       },
       additionalNavItems(){
           return filter(this.navItems, function(navItem){
               return navItem.class_name === 'more';
           });
       }
   },
   created(){
       this.getNavigation();
   }
};
</script>

<style lang="scss">
    .sa-main-nav{
        .sa-main-nav__mobile{
            .v-menu__content{
                border-radius:0;
            }
            .v-expansion-panel-content__wrap{
                padding:0;
            }
            .v-expansion-panel-header__icon .v-icon.theme--light{
                color:$color-primary;
            }
            .v-list-item__title{
                overflow: visible;
                white-space: normal;
            }

        }
    }
</style>

<style lang="scss" scoped>
.sa-main-nav{
    .v-menu__content{
        position: fixed;
        right:0 !important;
        left:auto !important;
        top:100% !important;
        background:white;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 5px 0px rgba(0, 0, 0, 0.07), 0px 1px 10px 0px rgba(0, 0, 0, 0.06);

    }
    .sa-main-nav{
         &__item--level-2 + .sa-main-nav__separator{
                margin-top:24px;
            }

    }
    .sa-main-nav__desktop{
        position: relative;
        z-index: 902;
        .sa-main-nav{
            &__url--level-1.v-btn{
                padding-left:$spacer*4;
                padding-right:$spacer*4;
                min-width:auto;
                 position: relative;
                &::after{
                    position: absolute;
                    top: 0;
                    left:0;
                    width:1px;
                    height:100%;
                    content:'';
                    background:rgba(0, 0, 0, 0.3);
                }
            }
            &__url--parent{
                cursor:default;
            }
            &__column{
                background:none;
                width:50%;
            }
            &__separator{
                color:$color-gray-dark;
                font-size:0.75rem;
            }

            &__item--level-2{
                position:relative;
                &:hover, &:focus{
                    .sa-main-nav__url--level-2{
                       &::after{
                           opacity: 1;

                       }
                    }
                }
            }

            &__url--level-2{
                text-decoration: none;
                color:$color-gray-dark;
                width: 100%;

                &::after{
                    position:absolute;
                    bottom: 0;
                    left: 0;
                    width:2px;
                    height:100%;
                    content:'';
                    background:$color-primary;
                    opacity:0;
                    z-index: 3;
                }
            }
            &__url--highlight{
                color:$color-secondary;
                &::after{
                    background:$color-secondary;
                }
            }

        }
    }
    .sa-main-nav{
         &__mobile-button{
                position: relative;
                &:hover, &:focus{
                    color:$color-accent;
                    &::before{
                        display: none;
                    }
                }
                &::after{
                    position: absolute;
                    top: 0;
                    left:0;
                    width:1px;
                    height:100%;
                    content:'';
                    background:rgba(0, 0, 0, 0.3);
                }
            }
    }
    .sa-main-nav__mobile{
        .sa-main-nav{

            &__social{
                background:$color-white;
            }
            &__item--level-1{
                box-shadow: inset 0px -1px 0px $color-background;
            }
            &__url--level-1{
                text-decoration:none;
            }
            &__dropdown{
                background:$color-background;
            }

            &__column{
                width:100%;
                background:$color-background;
            }
            &__separator{
                color:$color-gray-dark;
                font-size:0.75rem;
            }
            &__item--level-2{
                position:relative;
                &:hover, &:focus{
                    .sa-main-nav__url--level-2{
                       &::after{
                           opacity: 1;
                       }
                    }
                }
            }

            &__url--level-2{
                color:$color-gray-dark;
                text-decoration: none;
                font-size:1rem;
                &::after{
                    position:absolute;
                    bottom: 0;
                    left: 0;
                    width:2px;
                    height:100%;
                    content:'';
                    background:$color-primary;
                    opacity:0;
                }
            }
            &__url--highlight{
                color:$color-secondary;
                &::after{
                    background:$color-secondary;
                }
            }
        }
    }
}
@media #{map-get($display-breakpoints, 'md-and-up')}{
    .sa-main-nav{
        .sa-main-nav__desktop{
            .sa-main-nav{
                &__url--level-1.v-btn{
                    padding-left:$spacer*4;
                    padding-right:$spacer*4;
                    &::after{
                        display:none;
                    }
                }
            }
        }
    }
}

@media #{map-get($display-breakpoints, 'lg-and-up')}{
    .sa-main-nav{
        .sa-main-nav{
            &__mobile-button{
                &::after{
                    display: none;
                }
            }
        }
    }
}

@media #{map-get($display-breakpoints, 'xl-only')}{
    .sa-main-nav{
        .sa-main-nav__mobile{
            .sa-main-nav{
                &__dropdown{
                    background:white;
                }
                &__column{
                    background:white;
                    width:50%;
                }
            }
        }
    }
}
</style>
