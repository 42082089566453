<template>
<div>
    <div class="sa-block-standings">
        <component :is="standingType" v-bind="$attrs"></component>
        <div class="sa-block-standings__footer d-flex align-center justify-start mt-8">
            <v-btn color="primary" :to="{name:'Standings'}" class="mr-4">
                {{ type == 'carousel' ? 'Full Standings' : 'Full'}}
                <v-icon right>$arrowRight</v-icon>
            </v-btn>
            <element-ad-butler type="content-sponsor" />
        </div>
	</div>
</div>
</template>

<script>
import BlockStandingsLimitedCarousel from "@/components/blocks/standings/BlockStandingsLimitedCarousel";
import BlockStandingsLimitedList from "@/components/blocks/standings/BlockStandingsLimitedList";
export default {
    props:{
        type:{
            type: String,
            default:'carousel'
        }
    },
    name: "BlockStandingsLimited",
    components:{
        BlockStandingsLimitedCarousel,
        BlockStandingsLimitedList
    },
    data: () => ({}),
    computed:{
        standingType(){
            return this.type === 'carousel' ? 'BlockStandingsLimitedCarousel' : 'BlockStandingsLimitedList';
        }
    }
};
</script>
