export const create_url_params_from_object = (params_obj) => {
  let param_string = '';

  Object.keys(params_obj).forEach((key, index) => {
    param_string += index === 0 ? "?" : "&";

    param_string += `${key}=${encodeURIComponent(params_obj[key])}`;
  });

  return param_string;
}

export const clean_response_data = (data, options) =>{
    if(options.type && options.type === "stories"){
        data.forEach((story) =>{
            story.url = story.url.replace(".aspx","")
        });
        return data;
    }
    return data;
};

export const get = (
  url = "",
  options = {},
  callback = data => {
    return data;
  }
) => {
  return new Promise((resolve, reject) => {
    const param_string = create_url_params_from_object(options);

    if(url.startsWith('/services/')){
        url =  process.env.VUE_APP_SERVICE_ROOT + url;
    }

    fetch(url + param_string)
      .then(response => response.json())
      .then(response => {
        if (response.error) {
          reject(response.error);
          return;
        }

        if (response.data) {
          resolve(callback(clean_response_data(response.data, options)));
          return;
        }

        resolve(callback(clean_response_data(response,options)));
      })
      .catch(response => {
        if (response.error) {
          reject(response.error);
          return;
        }

        reject(response);
      });
  });
};

export const is_json = text => {
  try {
    JSON.parse(text);
    return true;
  } catch (e) {
    return false;
  }
};

export const are_objects_equal = (obj_a, obj_b) => {
  let obj_a_props = Object.getOwnPropertyNames(obj_a),
    obj_b_props = Object.getOwnPropertyNames(obj_b);

  if (obj_a_props.length != obj_b_props.length) {
    return false;
  }

  obj_a_props.forEach(prop => {
    if (obj_a[prop] !== obj_b[prop]) {
      return false;
    }
  });

  return true;
};

export const focusable_elements = [
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
];

export const copy_to_clipboard = text => {
  const fake = document.createElement("textarea");

  fake.value = text;
  fake.style.position = "fixed";
  // fake.style.visibility = 'hidden';

  document.body.appendChild(fake);
  fake.focus();
  fake.select();

  document.execCommand("copy");
  document.body.removeChild(fake);
};

export const hex_to_rbg = hex_color_code => {
  var shorthand_hex_regex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  hex_color_code = hex_color_code.replace(shorthand_hex_regex, function(
    m,
    r,
    g,
    b
  ) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex_color_code);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

export const create_script = (url, async = false) => {
  var script = document.createElement("script");
  script.src = url;
  script.async = async;
  document.head.appendChild(script);
};

export const value_by_stringed_key = (object, string_key_path) => {
  string_key_path = string_key_path.replace(/\[(\w+)\]/g, ".$1");
  string_key_path = string_key_path.replace(/^\./, "");
  var pieces = string_key_path.split(".");

  for (let i = 0, n = pieces.length; i < n; ++i) {
    var k = pieces[i];
    if (k in object) {
      object = object[k];
    } else {
      return;
    }
  }

  return object;
};

export const update_value_by_stringed_key = (
  object,
  string_key_path,
  new_value
) => {
  string_key_path = string_key_path.replace(/\[(\w+)\]/g, ".$1");
  string_key_path = string_key_path.replace(/^\./, "");

  const pieces = string_key_path.split(".");
  let current_object_ref = object;

  for (let i = 0; i < pieces.length; i++) {
    const k = pieces[i];

    if (k in current_object_ref) {
      if (i === pieces.length - 1) {
        current_object_ref[k] = new_value;
      } else {
        current_object_ref = current_object_ref[k];
      }
    }
  }
};

export const set_default_value_on_object_prop = (obj, key, value) => {
  return new Promise(resolve => {
    const current_value = value_by_stringed_key(obj, key);
    let updated_obj = Object.assign({}, obj);

    if (["", null, undefined].includes(current_value)) {
      update_value_by_stringed_key(updated_obj, key, value);
    }

    resolve(updated_obj);
  });
};

export const sort_by = (property_name, type) => {
  return (a, b) => {
    let a_prime = value_by_stringed_key(a, property_name);
    let b_prime = value_by_stringed_key(b, property_name);

    if (type == "number") {
      a_prime = parseFloat(a_prime);
      b_prime = parseFloat(b_prime);
    }

    return a_prime < b_prime ? -1 : a_prime > b_prime ? 1 : 0;
  };
};


export const register_ga_event = (type, label) => {
  if (window.gtag) {
    try {
      window.gtag("event", "click", {
        event_category: `Season Review 2020: ${type}`,
        event_label: label
      });
    } catch (e) {
      console.warn(e);
    }
  } else if (window.ga) {
    try {
      window.ga("send", "event", {
        eventCategory: `Season Review 2020: ${type}`,
        eventAction: "click",
        eventLabel: label
      });
    } catch (e) {
      console.warn(e);
    }
  }
};

export const preload_image = (image_src) => {
  const image = new Image();

  image.onerror = () => {
    console.warn(`Failed to preload image: ${image_src}`);
  };

  image.src = image_src;
};

export const state_list = [
    {
    "text": "Alabama",
    "value": "AL"
    },
    {
    "text": "Alaska",
    "value": "AK"
    },
    {
    "text": "American Samoa",
    "value": "AS"
    },
    {
    "text": "Arizona",
    "value": "AZ"
    },
    {
    "text": "Arkansas",
    "value": "AR"
    },
    {
    "text": "California",
    "value": "CA"
    },
    {
    "text": "Colorado",
    "value": "CO"
    },
    {
    "text": "Connecticut",
    "value": "CT"
    },
    {
    "text": "Delaware",
    "value": "DE"
    },
    {
    "text": "District Of Columbia",
    "value": "DC"
    },
    {
    "text": "Federated States Of Micronesia",
    "value": "FM"
    },
    {
    "text": "Florida",
    "value": "FL"
    },
    {
    "text": "Georgia",
    "value": "GA"
    },
    {
    "text": "Guam",
    "value": "GU"
    },
    {
    "text": "Hawaii",
    "value": "HI"
    },
    {
    "text": "Idaho",
    "value": "ID"
    },
    {
    "text": "Illinois",
    "value": "IL"
    },
    {
    "text": "Indiana",
    "value": "IN"
    },
    {
    "text": "Iowa",
    "value": "IA"
    },
    {
    "text": "Kansas",
    "value": "KS"
    },
    {
    "text": "Kentucky",
    "value": "KY"
    },
    {
    "text": "Louisiana",
    "value": "LA"
    },
    {
    "text": "Maine",
    "value": "ME"
    },
    {
    "text": "Marshall Islands",
    "value": "MH"
    },
    {
    "text": "Maryland",
    "value": "MD"
    },
    {
    "text": "Massachusetts",
    "value": "MA"
    },
    {
    "text": "Michigan",
    "value": "MI"
    },
    {
    "text": "Minnesota",
    "value": "MN"
    },
    {
    "text": "Mississippi",
    "value": "MS"
    },
    {
    "text": "Missouri",
    "value": "MO"
    },
    {
    "text": "Montana",
    "value": "MT"
    },
    {
    "text": "Nebraska",
    "value": "NE"
    },
    {
    "text": "Nevada",
    "value": "NV"
    },
    {
    "text": "New Hampshire",
    "value": "NH"
    },
    {
    "text": "New Jersey",
    "value": "NJ"
    },
    {
    "text": "New Mexico",
    "value": "NM"
    },
    {
    "text": "New York",
    "value": "NY"
    },
    {
    "text": "North Carolina",
    "value": "NC"
    },
    {
    "text": "North Dakota",
    "value": "ND"
    },
    {
    "text": "Northern Mariana Islands",
    "value": "MP"
    },
    {
    "text": "Ohio",
    "value": "OH"
    },
    {
    "text": "Oklahoma",
    "value": "OK"
    },
    {
    "text": "Oregon",
    "value": "OR"
    },
    {
    "text": "Palau",
    "value": "PW"
    },
    {
    "text": "Pennsylvania",
    "value": "PA"
    },
    {
    "text": "Puerto Rico",
    "value": "PR"
    },
    {
    "text": "Rhode Island",
    "value": "RI"
    },
    {
    "text": "South Carolina",
    "value": "SC"
    },
    {
    "text": "South Dakota",
    "value": "SD"
    },
    {
    "text": "Tennessee",
    "value": "TN"
    },
    {
    "text": "Texas",
    "value": "TX"
    },
    {
    "text": "Utah",
    "value": "UT"
    },
    {
    "text": "Vermont",
    "value": "VT"
    },
    {
    "text": "Virgin Islands",
    "value": "VI"
    },
    {
    "text": "Virginia",
    "value": "VA"
    },
    {
    "text": "Washington",
    "value": "WA"
    },
    {
    "text": "West Virginia",
    "value": "WV"
    },
    {
    "text": "Wisconsin",
    "value": "WI"
    },
    {
    "text": "Wyoming",
    "value": "WY"
    }
    ];

export const alphabet_list = [
"#","A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"
];

export const event_order = [
  { name:"Bareback", abbrev: "" },
  { name:"Bareback Riding", abbrev: "BB" },
  { name:"Breakaway Roping", abbrev: "" },
  { name:"Steer Wrestling", abbrev: "SW" },
  { name:"Team Roping", abbrev: "TR" },
  { name:"Saddle Bronc", abbrev: "" },
  { name:"Saddle Bronc Riding", abbrev: "SB" },
  { name:"Tie-down Roping", abbrev: "TD" },
  { name:"Barrel Racing", abbrev: "GB" },
  { name:"Steer Roping", abbrev: "SR" },
  { name:"Bull Riding", abbrev: "BR" },
];

export const table_stat_with_footnote = (primary,secondary) =>{
    return '<div class="font-weight-black type-subtext">' + primary + 
    '</div><span class="type-label sa-safe-text--text font-weight-regular footnote">' + secondary + '</span>';
}

export const table_link = (type, obj) =>{
    let link = ""; 
    let _obj = {};

    switch(type){
        case 'result':
            _obj = new Rodeo({ StartDate: obj.StartDate, Name: obj.RodeoName, RodeoId: obj.RodeoId });
            link = _obj.ResultUrl();
            break;
        case 'contestant':
            _obj = new Athlete(obj.Contestant ? obj.Contestant : obj);
            link = _obj.BioUrl();
            break;
        case 'stock':
            _obj = new Athlete(obj.Stock);
            link = _obj.BioUrl();
            break;
        case 'rodeo':
            _obj = new Rodeo({ StartDate: obj.StartDate, Name: obj.RodeoName, RodeoId: obj.RodeoId });
            link = _obj.ProfileUrl();
            break;
        case 'stock-contestant':
          _obj = new Athlete(obj);
          link = _obj.BioUrl();
          break;
        default:
            return '#';

    }

    return link;
}

export class Athlete{  
    constructor(athlete){
        Object.assign(this,athlete);
    }

    BioLink(){
        return {name : 'Bio', params:{ 
          type: this.IsStock() ? 'stock'  : (this.IsContestant()) ? "contestant" : (this.IsContractor()) ? "contractor": "", 
          id:this.AthleteId(), 
          name:this.EscapedName()}};
    }
    BioUrl(){
        return '/bio/' + this.Type() + '/' + this.EscapedName() + '/' + this.AthleteId();
    }
    IsStock(){
        return this.StockId ? true : false;
    }
    IsContestant(){
        return this.ContestantId ? true : false;
    }
    IsContractor(){
      return this.ContractorId ? true : false;
    }
    Type(){
      return (this.IsStock()) ? 'stock' 
            : (this.IsContestant()) ? "contestant"
            : (this.IsContractor()) ? "contractor"
            : "";
    }

    AthleteId(){
      return (this.IsStock()) ? this.StockId
      : (this.IsContestant()) ? this.ContestantId
      : (this.IsContractor()) ? this.ContractorId
      : undefined;
    }
    FullName(){
        if(this.IsStock()){
            let _brand = this.Brand ? '( Brand: ' + this.Brand + ' )' : 'unnamed';
            return this.Name ? this.Name : _brand;
        }

        if(this.IsContractor()){
          return this.Name;
        }
        return this.FirstName + ' ' + this.LastName;
    }
    EscapedName(){
        return this.FullName().toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,'');
    }
}

export const map_athletes = (athletes) =>{
    if(Array.isArray(athletes)){
        let _athletes = [];
        athletes.forEach(athlete =>{
            _athletes.push(new Athlete(athlete));
        });
        return _athletes;
    }else{
        return new Athlete(athletes);
    }
}


export class Rodeo{  

    constructor(rodeo){
        Object.assign(this,rodeo);
    }

    ProfileLink(){
        return {name : 'Rodeo', params:{ id:this.RodeoId, year:this.Year(), name:this.EscapedName()}};
    }
    ProfileUrl(){
        return '/schedule/' + this.Year() + '/'+ this.EscapedName() + '/' + this.RodeoId;
    }
    DaysheetProfileUrl(){
      return {name : 'Rodeo', params:{ id:this.RodeoId, year:this.Year(), name:this.EscapedName(), daysheet: 'daysheet'}};
    }
    ResultLink(){
        return {name : 'Result', params:{ year: this.Year() ,id:this.RodeoId, name:this.EscapedName()}};
    }
    DaysheetResultLink(){
      return {name : 'Result', params:{ year: this.Year() ,id:this.RodeoId, name:this.EscapedName(), daysheet: 'daysheet'}};
    }
    ResultUrl(){
        return '/result/' + this.Year() + '/'+ this.EscapedName() + '/' + this.RodeoId;
    }
    EscapedName(){
        return this.Name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g,'');
    }
    Year(){
        return new Date(this.StartDate).getFullYear();
    }
    
}

export const map_rodeos = (rodeos) =>{
    if(Array.isArray(rodeos)){
        let _rodeos = [];
        rodeos.forEach(rodeo =>{
            _rodeos.push(new Rodeo(rodeo));
        });
        return _rodeos;
    }else{
        return new Rodeo(rodeos);
    }
}
