export default {
  methods: {
    setMetaSidearm(title, tags) {
      if (title) {
        document.title = title + " | " + this.$root.siteTitle;
      }
      
      // Remove current vue-controlled meta tags
      Array.from(
        document.querySelectorAll("[data-vue-controlled]")
      ).forEach((el) => el.parentNode.removeChild(el));

      this.appendMetaSidearm(tags);
    },
    appendMetaSidearm(tags) {
      if (tags && tags.length) {
        tags
          .map((tagDef) => {
            const tag = document.createElement("meta");

            Object.keys(tagDef).forEach((key) => {
              tag.setAttribute(key, tagDef[key]);
            });
            tag.setAttribute("data-vue-controlled", "");

            return tag;
          })
          .forEach((tag) => document.head.appendChild(tag));
      }
    },
    appendMetaSidearmDefaultImage() {
      let imageSet = document.querySelectorAll("meta[name='og:image']").length !== 0;

      if (!imageSet) {
        const tag = document.createElement("meta");
        tag.setAttribute("name", "og:image");
        tag.setAttribute("content",`${process.env.BASE_URL}assets/images/image_share.png`);
        tag.setAttribute("data-vue-controlled", "");
        document.head.appendChild(tag);
      }
    },
    setTwitterCard(title, description, image) {
      document.querySelector("meta[name='twitter:title']").setAttribute("content", title);
      document.querySelector("meta[name='twitter:description']").setAttribute("content", description);
      document.querySelector("meta[name='twitter:image']").setAttribute("content", image);
    }
  },
};
