<template>
  <section class="sa-block-standings-limited-carousel">
    <div class="d-flex flex-wrap flex-xl-nowrap align-center pb-8">
      <common-title
        class="order-0 mr-auto mr-xl-4"
        top="Contestant"
        bottom="Standings"
        type="h2"
      />
      <v-select
        class=" sa-block-standings__select sa-block-standings__select--carousel sa-drop-shadow mt-4 mt-xl-0 pt-0 mr-auto order-2 order-xl-1 w-100"
        :items="events"
        :item-text="'EventName'"
        :item-value="'EventAbbrev'"
        hide-details
        aria-label="Select an Event"
        @change="getStandings"
        v-model="selectedEvent"
      ></v-select>
      <v-select
        class=" sa-block-standings__select sa-block-standings__select--carousel sa-drop-shadow mt-4 mt-xl-0 pt-0 mr-auto ml-xl-4 order-3 order-xl-2 w-100"
        :items="years"
        hide-details
        aria-label="Select a Year"
        @change="getStandings"
        v-model="selectedYear"
      ></v-select>
      <div
        class="sa-block-standings__buttons d-flex align-center order-1 order-xl-3 w-100 w-md-auto bb-solid--light bb-md-none ml-xl-4 py-4 py-md-0"
      >
        <v-btn
          height="40"
          class="w-50 w-lg-auto px-md-8 flex-shrink-1"
          @click="switchTab('world')"
          :color="isWorldSelected ? 'secondary' : 'primary'"
          rounded
          >World</v-btn
        >
        <v-btn
          height="40"
          class="ml-2 w-50 w-lg-auto px-md-8 flex-shrink-1"
          @click="switchTab('nfrplayoffseries')"
          :color="isNfrPlayoffSeriesSelected ? 'secondary' : 'primary'"
          rounded
          >PRCA Playoff Series</v-btn
        >
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div
        :key="'has-items'"
        v-if="!loadingContestants"
        class="sa-block-standings__tiny-slider sa-drop-shadow"
      >
        <div v-if="contestants.length">
          <tiny-slider
            class=" white"
            :mouse-drag="true"
            :loop="false"
            nav-position="bottom"
            :nav="false"
            :controls="true"
            controls-position="bottom"
            items="2"
            slide-by="1"
            gutter="0"
            :responsive="{
              820: {
                items: 3,
              },
              960: {
                items: 2,
              },
              1100: {
                items: 3,
              },
            }"
          >
            <div
              class="standing text-center pa-8 br-solid--light"
              v-for="athlete in contestants"
              :key="athlete.StandingId"
            >
              <div class="standing__avatar mb-4">
                <common-avatar-sidearm
                  max-width="120"
                  type="contestant"
                  :src="athlete.SidearmPhotoUrl"
                  :alt="athlete.FullName()"
                  tile
                />
                <div
                  class="standing__rank  standing__rank--carousel d-flex align-center justify-center secondary white--text font-weight-black"
                >
                  {{ athlete.Place }}
                </div>
              </div>
              <div
                class="standing__name primary--text font-weight-bold type-hero--sm mb-2"
              >
                <router-link class="text-link" :to="athlete.BioLink()">{{
                  athlete.FullName()
                }}</router-link>
              </div>
              <div class="standing__details type-subtext sa-safe-text--text">
                <span class="secondary--text font-weight-bold">{{
                  showEarningsOrPoints(athlete)
                }}</span>
                <span v-if="athlete.Hometown" class="d-inline-block mx-2"
                  >&bull;</span
                >
                <span v-if="athlete.Hometown">{{ athlete.Hometown }}</span>
              </div>
            </div>
          </tiny-slider>
        </div>
        <div v-else>
          <div class="white py-8">
            Standings not available for this event.
          </div>
        </div>
      </div>
      <div v-else :key="'no-items'">
        <div
          class="sa-block-standings__loading pa-6 d-flex align-center justify-center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import { get, map_athletes } from "@/helpers";
import CommonTitle from "@/components/common/CommonTitle";
import CommonAvatarSidearm from "@/components/common/CommonAvatarSidearm";
import remove from "lodash/remove";

export default {
  name: "BlockStandingsLimitedCarousel",
  components: {
    CommonTitle,
    CommonAvatarSidearm,
  },
  data: () => ({
    selectedEvent: "AA",
    selectedTab: "world",
    contestants: [],
    loadingContestants: true,
    selectedYear: null,
  }),
  computed: {
    isWorldSelected() {
      return this.selectedTab == "world";
    },
    isProRodeoSelected() {
      return this.selectedTab == "prorodeo";
    },
    isNfrPlayoffSeriesSelected() {
      return this.selectedTab == "nfrplayoffseries";
    },
    years() {
      return this.$root.recentStandingsYears;
    },
    events() {
      let _events = this.$root.eventTypes
        ? JSON.parse(JSON.stringify(this.$root.eventTypes))
        : [];

      remove(_events, (e) => {
        return !e.inStandings || ["GB", "LB"].includes(e.EventAbbrev);
      });

      return _events;
    },
    mobileContestants() {
      return this.contestants.slice(0, 3);
    },
  },
  methods: {
    switchTab(tab) {
      this.selectedTab = tab;
      this.getStandings();
    },
    getStandings() {
      this.loadingContestants = true;
      let request_options = {};
      switch (this.selectedTab) {
        case "world":
          request_options = {
            year: this.selectedYear
              ? this.selectedYear
              : this.$root.currentYear,
            type: "world",
            event: this.selectedEvent,
          };
          break;
        case "nfrplayoffseries":
          request_options = {
            year: this.selectedYear
              ? this.selectedYear
              : this.$root.currentYear,
            type: "tour",
            id: this.$root.nfrPlayoffSeriesId,
            event: this.selectedEvent,
          };
          break;
        default:
          request_options = {
            year: this.selectedYear
              ? this.selectedYear
              : this.$root.currentYear,
            type: "world",
            event: this.selectedEvent,
          };
      }

      get("/services/pro_rodeo.ashx/standings", request_options).then(
        (response) => {
          this.contestants = map_athletes(response);
          this.loadingContestants = false;
        }
      );
    },
    showEarningsOrPoints(athlete) {
      if (
        athlete.TourId === this.$root.proRodeoTourId ||
        athlete.TourId === this.$root.nfrPlayoffSeriesId
      ) {
        return athlete.Points + " pts";
      } else {
        return this.$options.filters.currency(athlete.Earnings, 2);
      }
    },
  },
  created() {
    this.selectedYear = this.$root.currentYear;
    this.getStandings();
  },
};
</script>

<style lang="scss">
.sa-block-standings__tiny-slider {
  outline: none;
  padding: 0 50px;
  position: relative;
  background: white;
  .tns-controls {
    outline: none;
    button[data-controls="prev"],
    button[data-controls="next"] {
      outline: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 100%;
      background: white;
      box-shadow: inset -1px 0px 0px $color-border-light;
      &::before {
        color: $color-primary;
      }
    }
    button[data-controls="next"] {
      outline: none;
      left: auto;
      right: 0;
      box-shadow: none;
    }

    button {
      &:hover,
      &:focus,
      &:active,
      &:focus-visible {
        outline: none;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.sa-block-standings-limited-carousel {
  .sa-block-standings {
    &__select {
      max-width: none;
    }
    &__loading {
      height: 296px;
    }
  }

  .standing {
    &__avatar {
      position: relative;
    }
    &__rank {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      font-size: 10px;
    }
  }
}

.sa-block-standings-limited-carousel {
  .standing {
    &__avatar {
      padding-bottom: 12px;
    }
    &__rank--carousel {
      position: absolute;
      width: 24px;
      height: 24px;
      font-size: 0.75rem;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

@media #{map-get($display-breakpoints, 'xl-only')} {
  .sa-block-standings-limited-carousel {
    .sa-block-standings {
      &__select--carousel {
        max-width: 260px;
      }
    }
  }
}
</style>
