<template>
    <transition name="fade" mode="in-out">
        <div class="sa-common-tab" v-show="is_active">
            <slot/>
        </div>
    </transition>
</template>

<script>


export default {
  name: "UiTab",
   props: {
    name: {
      type: String,
      required: true,
      default: ""
    }
  },

  data: () => ({
    is_active: false
  })
};
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s, height 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  height:0;
  overflow:hidden;
}
</style>

