<template>
    <div class="sa-view-stock">
        <common-title-page title="Stock"/>
        <v-container class="px-0 px-lg-8 py-0">
          <element-ad-butler 
            :type="$vuetify.breakpoint.smAndDown ? 'leaderboard-mobile' : 'leaderboard'" 
            class="py-8 bb-dashed d-flex justify-center" 
          />
        </v-container>
        
          <v-container v-if="featuredStock.length || featuredContestants.length" class="px-0 py-0">
            <div class="d-flex align-stretch justify-center">
                <div class="pt-8 w-100 w-xl-50" :class="featuredStock.length ? 'br-xl-dashed' : ''" v-if="featuredContestants.length && ($vuetify.breakpoint.xlOnly || activeFeaturedTab === 'contestants')">
                    <header class="d-flex align-center justify-space-between pb-4 pb-xl-8 px-8">
                        <common-title top="Featured" :bottom="$vuetify.breakpoint.xlOnly ? 'Contestants' : 'Athletes'" type="h3"/>
                    </header>
                    <div class="d-flex d-xl-none pb-8 px-7" v-if="featuredStock.length">
                        <div class="w-50 px-1">
                            <v-btn rounded block height="40"  :color="activeFeaturedTab === 'contestants' ? 'secondary' : 'primary'" @click="activeFeaturedTab = 'contestants'">Contestants</v-btn>
                        </div>
                        <div class="w-50 px-1">
                            <v-btn rounded block height="40" :color="activeFeaturedTab === 'stock' ? 'secondary' : 'primary'" @click="activeFeaturedTab = 'stock'">Stock</v-btn>
                        </div>
                    </div>
                    <div class="sa-athletes__slider pb-8 px-4" v-if="featuredContestants.length" :key="'contestants' + featuredContestants.length">
                        <tiny-slider class="mb-8" :mouse-drag="true" :loop="false" nav-position="bottom" :nav="true" :controls="false"  controls-position="bottom" items="1" slide-by="1" gutter="0" :responsive="{
                                540:{
                                    items:2,
                                    slideBy:2
                                },
                                768:{
                                    items:3,
                                    slideBy:3
                                },
                                960:{
                                    items:4,
                                    slideBy:4
                                },
                                1264:{
                                    items:3,
                                    slideBy:3
                                }
                            }">
                            <element-athletes-featured-item class="text-center px-4" v-for="athlete in featuredContestants" :key="athlete.Type() + athlete.AthleteId()" :athlete="athlete" />
                        </tiny-slider>
                    </div>
                </div>
                <div class="pt-8 w-100 w-xl-50" v-if="featuredStock.length && ($vuetify.breakpoint.xlOnly || activeFeaturedTab === 'stock')">
                    <header class="d-flex align-center justify-space-between pb-4 pb-xl-8 px-8">
                        <common-title top="Featured" :bottom="$vuetify.breakpoint.xlOnly ? 'Stock' : 'Athletes'" type="h3"/>
                    </header>
                    <div class="d-flex d-xl-none pb-8 px-7" v-if="featuredContestants.length">
                        <div class="w-50 px-1">
                            <v-btn rounded block height="40"  :color="activeFeaturedTab === 'contestants' ? 'secondary' : 'primary'" @click="activeFeaturedTab = 'contestants'">Contestants</v-btn>
                        </div>
                        <div class="w-50 px-1">
                            <v-btn rounded block height="40" :color="activeFeaturedTab === 'stock' ? 'secondary' : 'primary'" @click="activeFeaturedTab = 'stock'">Stock</v-btn>
                        </div>
                    </div>
                    <div v-if="featuredStock.length" class="pb-8 px-4" :key="'stock' + featuredStock.length">
                        <tiny-slider class="mb-8" :mouse-drag="true" :loop="false" nav-position="bottom" :nav="true" :controls="false"  controls-position="bottom" items="1" slide-by="1" gutter="0" :responsive="{
                                540:{
                                    items:2,
                                    slideBy:2
                                },
                                768:{
                                    items:3,
                                    slideBy:3
                                },
                                960:{
                                    items:4,
                                    slideBy:4
                                },
                                1264:{
                                    items:3,
                                    slideBy:3
                                }
                            }">
                            <element-athletes-featured-item class="text-center px-4" v-for="athlete in featuredStock" :key="athlete.Type() + athlete.AthleteId()" :athlete="athlete" />
                        </tiny-slider>
                    </div>
                </div>
            </div>
            <div class="mx-8 bb-dashed"></div>
        </v-container> 
        
        <v-container class="px-0 py-0">
            <header class="d-flex align-center justify-space-between mb-4 px-8 pt-8">
                <common-title top="All ProRodeo" bottom="Stock Stats" type="h3"/>
                <element-ad-butler :type="$vuetify.breakpoint.smAndDown ? 'content-sponsor-mobile' : 'content-sponsor'"/>
            </header>
            
            <div class="d-flex flex-column align-center px-8 pb-8">
                <!--NOTE: IFRAME content from prorodeo.org; keep until new feature is determined. -->
                <iframe 
                    id="stats-iframe" 
                    class="d-flex" 
                    style="min-height: 1150px; width: 100%; border: none" 
                    src="https://stockstats.prorodeo.org/prorodeo/prca-stock-stats/stock">
                </iframe>
                <!-- START Tabs -->
                <!-- <div
                    class="d-flex flex-wrap w-100"
                >
                    <div
                        v-for="(tabName, tabIndex) in ['Stock', 'Rodeo', 'Contestant', 'Contractor']"
                        :key="tabIndex"
                        class="w-50 my-1
                            w-md-auto mr-md-1"
                    >
                        <v-btn
                            @click="switchTab(tabName.toLowerCase())"
                            :color="activeTab === tabName.toLowerCase() ? 'secondary' : 'primary'"
                            :disabled="loading"
                            height="40"
                            rounded
                            block
                        >
                            {{ tabName }}
                        </v-btn>
                    </div>
                </div> -->
                <!-- END Tabs -->

                <!-- START Tab Content -->
                <!-- <block-stock-search
                    @update-loading="(newStatus) => loading = newStatus"
                    v-if="activeTab == 'stock'"
                    set-meta
                    use-query-params
                    show-stats
                />
                <block-rodeos-search
                    @update-loading="(newStatus) => loading = newStatus"
                    v-else-if="activeTab == 'rodeo'"
                    type="results"
                    set-meta
                    use-query-params
                    show-stats
                />
                <block-contestants-search
                    @update-loading="(newStatus) => loading = newStatus"
                    v-else-if="activeTab == 'contestant'"
                    set-meta
                    use-query-params
                    show-stats
                />
                <block-contractors-search
                    @update-loading="(newStatus) => loading = newStatus"
                    v-else-if="activeTab == 'contractor'"
                    set-meta
                    use-query-params
                    show-stats
                /> -->
                <!-- END Tab Content -->
            </div>
        </v-container>
    </div>
</template>

<script>
import CommonTitlePage from "@/components/common/CommonTitlePage";
import CommonTitle from "@/components/common/CommonTitle";
import ElementAthletesFeaturedItem from "@/components/elements/athletes/ElementAthletesFeaturedItem";
/* import BlockStockSearch from "@/components/blocks/athletes/BlockStockSearch"
import BlockRodeosSearch from "@/components/blocks/rodeos/BlockRodeosSearch"
import BlockContestantsSearch from "@/components/blocks/athletes/BlockContestantsSearch"
import BlockContractorsSearch from "@/components/blocks/athletes/BlockContractorsSearch" */

import { get, map_athletes } from "@/helpers";

export default {
     name: "Stock",

     components:{
        CommonTitlePage,
        CommonTitle,
        ElementAthletesFeaturedItem,
        /* BlockStockSearch,
        BlockRodeosSearch,
        BlockContestantsSearch,
        BlockContractorsSearch, */
    },

    data() {
        return{
            featuredStock:[],
            loading:true,
            activeTab: null,
            activeFeaturedTab:'stock',
            selectedEventType:null,
            searchTerm:null,
            pageSize: 10,
            moreToLoad:false,
            featuredContestants:[], // never fetched, I have no idea why it's here (same for the associated template)
        }
    },

    computed: {
        //
    },

    watch: {
        //
    },
    
    methods:{
        switchTab(tab){
            this.activeTab = tab;
            this.pushQuery();
        },
        
        getFeaturedStock(){
            let request_options = {
                featured:true,
                index:1,
                page_size:12
            };
            get("/services/pro_rodeo.ashx/stocklist", request_options).then(response => {
                if(response && response.length){
                    this.featuredStock = map_athletes(response);
                }
            });
        },

        pushQuery(){
            let _query = {
                type: this.activeTab,
            }

            this.$router.push({name: 'Stock', query: _query});
        },
        
        matchQuery(){
            let _query = this.$route.query;
            this.activeTab = _query.type ? _query.type : 'stock';
        },

    },
    
    created() {
        this.setMetaSidearm('Rodeo Stock Profiles');
        this.appendMetaSidearmDefaultImage();
        this.getFeaturedStock();
        this.matchQuery();
    },
}
</script>

<style lang="scss" >
.sa-view-stock{
    .sa-stocks__slider{
        .tns-slider{
            display: flex;
        }
    }
}
</style>

<style scoped lang="scss">
.sa-stock{
    &__letter{
        &.is-disabled{
            pointer-events: none;
            opacity:0.6;
            color:$color-sa-safe-text;
        }
    }
    &__scroll-container{
         overflow-anchor:none;
    }
}
</style>
