<template>
    <div class="sa-common-vgt-table" :class="[wide ? 'is-wide':'', dense ? 'is-dense' : '']" v-resize="onResize" v-intersect.once="onResize">
        <vue-good-table
            ref="table"
            :columns="vgtColumns"
            :rows="vgtRows"
            :sort-options="sort"
            style-class="sa-common-vgt-table__table"
        >
            <template slot="table-column" slot-scope="props">
                <div class="d-flex flex-column align-stretch justify-center sa-common-vgt-table__header-column">
                    <div class="sa-common-vgt-table__header  py-2"  :class="[props.column.align ? props.column.align : '', props.column.divider ? '--divider' : '', props.column.headerClass ? props.column.headerClass : '', dense ? 'px-2' : 'px-4' ]">
                        <span class="sa-common-vgt-table__header-label" :class="props.column.sublabel ? 'sa-common-vgt-table__header-label--sub': ''">{{props.column.label}}<span v-if="props.column.sublabel" class="sa-common-vgt-table__header-sublabel">{{props.column.sublabel}}</span></span>
                        <v-icon v-if="props.column.sortable" size="18" class="mt-n1 ml-1" color="white">$mdiChevronDown</v-icon>
                    </div>
                </div>
            </template>
            <template slot="table-row" slot-scope="props">
                <div
                    @click="if(selectableRows) selectRow(props.row[indexField])"
                    class="sa-common-vgt-table__cell py-2 "
                    :class="[
                        props.column.align ? props.column.align : '',
                        props.column.divider ? '--divider' : '',
                        props.column.cellClass ? props.column.cellClass : '',
                        dense ? 'px-2' : 'px-4',
                        props.row.IsHighligthed ? 'highlighted_item': '',
                        selectableRows ? `row-id--${props.row[indexField]}` : '',
                        selectableRows
                        ? props.row[indexField] == selectedRow
                            ? 'selected-row-column'
                            : ''
                        : '',
                    ]"
                >
                    <span
                        v-html="props.row[props.column.displayField]
                            ? props.row[props.column.displayField]
                            : props.formattedRow[props.column.field]"
                    >
                    </span>
                </div>
            </template>
            <div class="text-center pa-6" slot="emptystate">
                <v-progress-circular
                indeterminate
                color="primary"
                ></v-progress-circular>
            </div>
        </vue-good-table>
    </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table';
import forEach from 'lodash/forEach';

export default {
  name: "CommonTableSidearm",
  components:{
      VueGoodTable
  },
  props:{
      columns:{
        type:Array,
        required:true,
        default:() =>{ return [];}
      },
      rows:{
        type:Array,
        required:true,
        default:() =>{ return [];}
      },
      wide:{
          type:Boolean,
          required:false,
          default:false
      },
      dense:{
          type:Boolean,
          required:false,
          default:false
      },
      sort:{
          type:Object, 
          required: false,
          default: () => {return { enabled: false} }
      },
      selectableRows: {
          type: Boolean,
          required: false,
          default: false,
      },
      indexField: {
          type: String,
          required: false,
      },
      selectedRow: {
          type: [String, Number],
          required: false,
      }
  },

  data: () => ({
      //
  }),
  
  computed:{
      vgtColumns: function(){
          let transformedColumns = JSON.parse(JSON.stringify(this.columns));
            transformedColumns = forEach(transformedColumns, function(col){
                if(col.fixed){
                    col.thClass = '--fixed';
                    col.tdClass = '--fixed';
                }
                if(col.align){
                    switch(col.align){
                        case 'center':
                            col.align = 'text-center';
                            break;
                        case 'left':
                            col.align = 'text-left';
                            break;
                        case 'right':
                            col.align = 'text-right';
                            break;                            
                    }
                }
            });
            return transformedColumns;
      },
      vgtRows:function(){
          return this.rows;
      }
  },
  watch:{
      rows:function(){
          this.onResize();
      },
  },
  methods:{
    selectRow(selectedRow) {
        this.$emit("select-row", selectedRow)

        // Apply a border to the selected row. (CSS is applied correctly, but does nothing { for some reason??? })
        const rowElements = Array.from(this.$refs.table.$el.querySelector("tbody").children)
        rowElements.forEach(row => {
            // The columns hold the index of the row in their classlist.
            row.querySelector(`.row-id--${selectedRow}`) != null
            ? row.classList.add("selected-row")
            : row.classList.remove("selected-row")
        })
    },
    onResize(){
        if(window.innerWidth < 960){
            let elements = this.$el.getElementsByClassName('--fixed');
            let headerEl = this.$el.getElementsByTagName('thead')[0];
            let _headerHeight = headerEl.getBoundingClientRect().height;

            let _totalWidth = 0;
            let _positions = [];
            elements.forEach(e =>{
                if(e.tagName == 'TH'){
                    e.style.left = _totalWidth + 'px';
                    e.style.height = _headerHeight + 'px';
                    _positions.push({size: e.getBoundingClientRect().width, left: _totalWidth});
                    _totalWidth += e.getBoundingClientRect().width;

                }
            });

            let rows = this.$el.getElementsByTagName('TR');

            rows.forEach(row =>{
                let fixedEls = row.getElementsByClassName('--fixed');
                fixedEls.forEach((e, index) =>{
                    if(e.tagName == 'TD'){
                        e.style.left = _positions[index].left + 'px';
                        e.style.width = _positions[index].size + 'px';
                    }
                });

            });

            let _outerTable = this.$el.getElementsByClassName('vgt-responsive')[0];

            let _calcPad = "calc(100% - " + _totalWidth + "px)"; 

            _outerTable.style.marginLeft = _totalWidth + "px";
            _outerTable.style.width = _calcPad;
        }else{
             let elements = this.$el.getElementsByClassName('--fixed');
              elements.forEach(e =>{
                  e.style.left = '';
                  e.style.width = '';
                  e.style.height = '';
              });

            let _outerTable = this.$el.getElementsByClassName('vgt-responsive')[0];
            _outerTable.style.marginLeft = '';
            _outerTable.style.width = '';

        }
    },
  },
  updated(){
      this.onResize();
  },
};
</script>


<style lang="scss" scoped>
.selected-row {
    border: 2px solid black !important;

    &-column {
        background-color: rgb(255 232 208);
        // filter: drop-shadow(0 0 0.05rem black);
    }
}

.sa-common-vgt-table::v-deep{

    

    .vgt-responsive{
        width:100%;
        overflow-x: auto;
    }
    .sa-common-vgt-table__table{
        min-width:100%;
        height: auto;
        margin: 0;
        padding: 0;
        border: none;
        border-collapse: inherit;
        border-spacing: 0;
        border-color: inherit;
        vertical-align: middle;
        font-weight: inherit;
        -webkit-border-horizontal-spacing: 0;
        -webkit-border-vertical-spacing: 0;
        thead,
        tbody,
        tfoot,
        tr,
        th,
        td {
            margin: 0;
            padding: 0;
            border: none;
            border-collapse: inherit;
            border-spacing: 0;
            border-color: inherit;
            vertical-align: inherit;
            -webkit-border-horizontal-spacing: 0;
            -webkit-border-vertical-spacing: 0;
        }
        th{
            background:$color-secondary;
            position:relative;
            white-space: nowrap;
        }
        td{
            background:$color-white;
            position:relative;
        }
        tr:hover{
            td{ background:$color-gray-lightest;}
        }
        thead{
            background:$color-secondary;
            color:$color-white;
        }
        th.sortable{
            &:hover{
                cursor:pointer;
            }
            &:hover, &:focus{
                .sa-common-vgt-table__header-label{
                    text-decoration: underline;
                }
            }
        }
        th.sorting-asc{
            .sa-common-vgt-table__header .v-icon{
                transform:none;
                opacity: 1;
            }
        }
        th.sorting-desc{
            .sa-common-vgt-table__header .v-icon{
                transform:rotate(180deg);
                opacity:1;
            }
        }

        tbody tr td{
            border-bottom:solid 1px $color-border-lightest;
        }
        .sa-common-vgt-table{
            &__header{
                text-transform: uppercase;
                font-weight:900;
                font-size:0.75rem;
                &.--divider{
                    &:after{
                        position:absolute;
                        top:0;
                        right:0;
                        width:1px;
                        height:100%;
                        background:rgba(0, 0, 0, 0.2);
                        content:'';
                    }
                }
                .v-icon{
                    transform:none;
                    transition:0.2s;
                    opacity:0.3;
                }
            }
            &__cell{
                min-height:72px;
                display:flex;
                flex-direction:column;
                justify-content:center;
                align-items:stretch;
                &.--divider{
                    &:after{
                        position:absolute;
                        top:24px;
                        right:0;
                        width:1px;
                        height:calc(100% - 48px);
                        content:'';
                        border-right:dashed 1px $color-border-lightest;
                    }
                }
            }
            
        }
    }

    .sa-common-vgt-table__header-column{
        min-height:50px;
    }

    .sa-common-vgt-table__header-label--sub{
        display:inline-block;
        line-height: 1.1;
    }
    .sa-common-vgt-table__header-sublabel{
        font-size: 0.625rem;
        display:block;
        font-weight:700;
    }
    .vgt-inner-wrap{
        width: 100%;
        overflow-x: auto;
    }
    

    th.--fixed, td.--fixed{
        position: absolute;
        z-index:3;
        
    }

    th.--fixed{
        .sa-common-vgt-table__header-column{
            height:100%;
        }
    }

    th{
        &:first-child{
            &.--fixed{
                .sa-common-vgt-table__header-column{
                    &::before{
                        position: absolute;
                        top:0;
                        left:100vw;
                        height:100%;
                        content:'';
                        width:24px;
                        background: linear-gradient(90deg, rgba(206,32,48,0) 0%, rgba(206,32,48,0.8) 63%);
                        transform:translateX(-100%);
                    }
                }

            }
        }
    }

    td{
        &:first-child{
            &.--fixed{
                .sa-common-vgt-table__cell{
                    &::before{
                        position: absolute;
                        top:0;
                        left:100vw;
                        height:100%;
                        content:'';
                        width:24px;
                        background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 63%);
                        transform:translateX(-100%);
                    }
                }
            }

        }
        .highlighted_item {
            background: rgba($color-accent, .35) !important;
        }
    }
    

    @supports(position:sticky){
         @media #{map-get($display-breakpoints, 'md-and-down')}{
        

            .vgt-responsive{
               
                overflow: auto !important;
                width:100% !important;
                margin-left: 0 !important;
                position:relative;
                max-height:calc(100vh - 60px);
            }

            .sa-common-vgt-table__table{
                thead tr th{
                    position: sticky;
                    top:0;
                    z-index:2;
                }
                th.--fixed{
                    z-index: 4;
                }
                td.--fixed{
                    position: sticky;
                    z-index:1;
                }
            }
         }
    }




    @media #{map-get($display-breakpoints, 'lg-and-up')}{

        th.--fixed, td.--fixed{
            position: relative;
            left: auto;
            .sa-common-vgt-table__header, .sa-common-vgt-table__cell{
                width:auto;
            }
        }

        th{
            &:first-child{
                &.--fixed{
                    .sa-common-vgt-table__header-column{
                        &::before{
                            display:none;
                        }
                    }
                }
            }
        }
        td{
            &:first-child{
                &.--fixed{
                    .sa-common-vgt-table__cell{
                        &::before{
                            display:none;
                        }
                    }
                }
            }
        }
    }
    @media #{map-get($display-breakpoints, 'xl-only')}{

        &.is-wide{
            overflow: hidden;
            .vgt-wrap{
                max-width:1264px;
                margin-left:auto;
                margin-right:auto;
            }
            .vgt-inner-wrap, .vgt-responsive{
                overflow-x: visible;
            }
            .sa-common-vgt-table__table{
                thead tr{
                    position: relative;
                    th:first-child::before{
                        position:absolute;
                        width:9999px;
                        height:100%;
                        content:'';
                        background:$color-secondary;
                        top:0;
                        right:-5000px;
                        z-index: -1;
                        pointer-events: none;
                    }
                }
                thead th{
                    position:relative;
                    z-index: 2;
                }
                tbody tr{
                    position: relative;
                    td{
                        position:relative;
                        z-index: 2;
                    }
                    td:first-child{
                        position: relative;
                        &::before{
                            position:absolute;
                            width:9999px;
                            height:calc(100% + 1px);
                            border-bottom: solid 1px $color-border-lightest;
                            content:'';
                            background:white;
                            top:0;
                            right:-5000px;
                            z-index: -1;
                        }
                    }
                    &:hover{
                        td:first-child{
                            &::before{
                                background:$color-gray-lightest;
                            }
                        }
                    }
                }
            }
        }
    }
}

</style>