<template>
    <v-footer class="main-footer pa-0 d-flex flex-column align-stretch d-print-none" color="primary">
        <div class="main-footer__brand">
            <div class="main-footer__parallax">
                <transition name="fade">
                <div v-if="intersected">
                <v-parallax
                    dark
                    height="500"
                    :src="$vuetify.breakpoint.mdAndUp ? '@/assets/images/image_background_footer.jpg' : '@/assets/images/image_background_footer_mobile.jpg'"
                >
                </v-parallax>
                </div>
                </transition>
                <div class="main-footer__parallax-content d-flex justify-center">
                    <v-container class="main-footer__parallax-container d-flex flex-column align-stretch justify-end" >
                        <v-row no-gutters>
                            <v-col xs-12 class="main-footer__parallax-col d-flex flex-column justify-end align-start pl-8">
                                <div class="text-uppercase white--text parallax-text pb-4 pb-lg-8">
                                    <span class="parallax-text__upper">We Are</span>
                                    <span class="parallax-text__lower">ProRodeo</span>
                                </div>
                                <div class="parallax-stripes d-flex align-stretch justify-space-between">
                                    <div class="parallax-stripe"></div>
                                    <div class="parallax-stripe"></div>
                                    <div class="parallax-stripe"></div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </div>
            <div class="d-md-none py-8 main-footer__copyright-spacer"></div>
            <v-container class="py-2">
                <v-row>
                    <v-col xs-12 class="py-6 py-md-3 d-flex justify-center justify-md-end main-footer__copyright">
                        <span class="main-footer__copyright-text white--text text-center text-md-left">{{ Date.now() | moment('YYYY') }} &copy; Copyright ProRodeo. <br v-if="$vuetify.breakpoint.smAndDown" />All rights reserved.</span>
                          <v-img class="main-footer__copyright-img" src="@/assets/logos/logo_primary.svg" :width="logoWidth"/>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="main-footer__nav">
            <v-container>
                <v-row class="main-footer__nav-inner px-6 pt-4 pb-md-4">
                    <v-col class="py-0" cols="12" md="6" lg="4" v-for="column in footerNav.columns" :key="'footer' + column.column">
                        <ul class="reset-list main-footer__nav-items">
                            <li class="main-footer__nav-item mb-4" v-for="item in column.items" :key="'footer-item' + item.column + item.id">

                                 <a class="main-footer__nav-link d-flex align-center" v-if="item.url.includes('http')" :href="item.url" rel="noopener" :target="item.open_in_new_window ? '_blank' :'_self'"  ><v-icon color="white" class="mr-2" small>$arrowRight</v-icon>{{item.title}}</a>
                                <router-link class="main-footer__nav-link d-flex align-center" v-else :to="item.url"  ><v-icon color="white" class="mr-2" small>$arrowRight</v-icon>{{item.title}}</router-link>
                            </li>
                        </ul>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="main-footer__legal">
            <v-container >
                <v-row class="py-5 py-md-8">
                    <v-col xs-12 class="d-flex flex-column flex-md-row align-center py-lg-5 px-md-8">
                        <a class="mr-md-4" href="https://sidearmsports.com" target="_blank" rel="noopener">
                            <img class="sidearm-logo" width="158" height="45" src="https://images.sidearmsports.com/logos.sidearmsports.com/sidearm/sidearm-stacked_one-color.svg" alt="Sidearm Sports Logo"/>
                        </a>
                        <ul class="reset-list d-flex main-footer__link-items">
                            <li class="main-footer__link-item white--text"><a href="https://www.sidearmsports.com/privacy-policy/" target="_blank" rel="noopener" class="main-footer__link white--text mx-4">Privacy Policy</a></li>
                            <li class="main-footer__link-item white--text"><a href="https://www.sidearmsports.com/termsofservice/" target="_blank" rel="noopener" class="main-footer__link white--text mx-4">Terms of Service</a></li>
                        </ul>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </v-footer>
</template>

<script>
import { get } from "@/helpers";
import IntersectionObserver from 'intersection-observer-polyfill';
import filter from 'lodash/filter';

export default {
  name: "TheFooter",
   data: () => ({
       navItems:[],
       intersected:false
   }),
   computed:{
       logoWidth(){
           if(this.$vuetify.breakpoint.lgAndUp){
               return 94;
           }
           if(this.$vuetify.breakpoint.mdOnly){
               return 60;
           }
           return 43;
       },
       footerNav(){
           let _footerNavItems =  filter(this.navItems, function(navItem){
               return navItem.class_name === 'footer';
           });

           return _footerNavItems && _footerNavItems.length ? _footerNavItems[0] : [];
       }

   },
   methods:{
       getNavigation(){
           const request_options = {
               type:"main-navigation"
           }
           get("/services/adaptive_components.ashx", request_options).then(response => {
               this.navItems = response;
            });
       },
       createIntersectionObserver(){
            const target = document.querySelector('.main-footer');

            const observer = new IntersectionObserver((el) =>{
                if(el[0].isIntersecting){
                    this.intersected = true;
                }
            }, {
                threshold:0.1
            });

            observer.observe(target);
        }

   },
   created(){
       this.getNavigation();
       this.$nextTick(() => {
            this.createIntersectionObserver();
        });
   }
};
</script>

<style lang="scss">
.main-footer{
         .v-parallax__image-container{
        &::after{
            content:'';
            position:absolute;
            bottom:0;
            left:0;
            width:100%;
            height:100%;
            z-index:2;
            background:linear-gradient(180deg, rgba(0, 37, 84, 0) 0%, rgba(0, 0, 0, 0.4) 100%), linear-gradient(180deg, rgba(0, 37, 84, 0) 0%, #002554 100%);
        }
    }
}
</style>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
        transition: opacity .2s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
.main-footer{
    a.main-footer__nav-link {
      width: fit-content;
    }
    &__parallax{
        position:relative;
        height:500px;
    }
    &__parallax-content{
        height:100%;
        width:100%;
        position:absolute;
        top:0;
        left:0;
    }
    &__parallax-container{
        height:100%;
        position:relative;
        padding:0;
    }
    &__parallax-col{
        position:relative;
    }
    &__copyright{
        position:relative;
    }
    &__copyright-img{
        position:absolute;
        top:0;
        transform:translateY(-552px);
        right:$spacer*8;
    }
    &__nav{
        &-link{
            color:$color-white;
            text-decoration:none;
            font-weight:700;
            font-size:0.75rem;
            text-transform: uppercase;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    &__legal, &__nav{
        box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.3);

        .sidearm-logo {
            filter: invert(100%) sepia(95%) saturate(19%) hue-rotate(349deg) brightness(106%) contrast(104%);
        }
    }
    &__copyright-spacer{
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.3);
    }
    &__link-item{
        &::after{
            content:'|';
        }
        &:last-child{
            &::after{
                content:'';
            }
        }
    }
    .parallax-text{
        font-weight:900;
        position:relative;
        z-index:4;
        &__upper{
            font-size:2.5rem;
            line-height:1;
            display:block;
        }
        &__lower{
            display:block;
            font-size:2.5rem;
            line-height:1;
        }
    }
    .parallax-stripes{
        width:107px;
        height:40px;
    }
    .parallax-stripe{
        background:$color-secondary;
        flex:0 1 33.3333%;
        min-width:1px;
        margin:0 5.8% 0 0;
        &:last-child{
            margin-right:0;
        }
    }
}

@media #{map-get($display-breakpoints, 'md-and-up')}{
    .main-footer{
        &__copyright-img{
            bottom:0;
            top:auto;
            transform:none;
        }
        &__copyright-text{
            padding-right:132px;
        }
        &__nav{
            // &-items{
            //     column-count: 2;
            //     -moz-column-count: 2;
            //     -webkit-column-count: 2;
            // }
        }

        .parallax-text{
            padding-bottom:18px;
            &__upper{
                font-size:4.375rem;
                line-height:0.86;
            }
            &__lower{
                font-size:4.375rem;
                line-height:0.86;
            }
        }
        .parallax-stripes{
            width:151px;
            height:56px;
        }
    }
}

@media #{map-get($display-breakpoints, 'lg-and-up')}{
    .main-footer{
        &__copyright-text{
            padding-right:150px;
        }
         .parallax-text{
            padding-bottom:$spacer*8;
            padding-left:0;
            &__upper{
                font-size: 6.25rem;
                line-height:0.8;
                margin-bottom:$spacer * 2;
            }
            &__lower{
                font-size:6.25rem;
                line-height:0.8;
            }
        }
        .parallax-stripes{
            width:272px;
            height:100px;
        }
    }
}
</style>
